// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('CreativeConcept', ['RailsResource', 'railsSerializer', 'uuid', function(RailsResource, railsSerializer, uuid) {
  let CreativeConcept;
  const ser = railsSerializer( function() {
    this.exclude('mediaCampaignId');
    this.exclude('dpFields');
    this.exclude('createdAt');
    return this.exclude('updatedAt');
  });

  return CreativeConcept = (function() {
    CreativeConcept = class CreativeConcept extends RailsResource {
      static initClass() {
        this.configure({url: '/api/creative_concepts', name: 'creative_concept', serializer: ser});
      }

      setS3Prefix() { return this.s3Prefix = `concept-${uuid.v4()}`; }

      markForDelete() { return this._destroy = 1; }
      undoMarkForDelete() { return delete this._destroy; }

      save() {
        if (this.isNew() && !this.s3Prefix) {
          this.setS3Prefix();
        }
        return super.save(...arguments);
      }
    };
    CreativeConcept.initClass();
    return CreativeConcept;
  })();
}
]);
