'use strict';

angular.module('kazaam.models').factory('MediaProduct', ['RailsResource', 'railsSerializer', function(RailsResource, railsSerializer) {
  let MediaProduct;
  const ser = railsSerializer( function() {
    this.exclude('creator');
    this.exclude('createdAt');
    this.exclude('updatedAt');
  });

  return MediaProduct = (function() {
    MediaProduct = class MediaProduct extends RailsResource {
      static initClass() {
        this.configure({url: '/api/media_products', name: 'media_product', serializer: ser});
      }

      siteAndName() {
        return this.dcmSite.name + ' - ' + this.name;
      }
    };
    MediaProduct.initClass();
    return MediaProduct;
  })();
}
]);
