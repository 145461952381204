// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

/*
This directive aims supports age-range audience targeting in the DBM trafficker.

There are 3 fields:

From age values: 18, 25, 35, 45, 55, 65
To age values: 25, 35, 45, 55, 65, +
Unknown values: true, false

'From' must be < 'To'
*/

const traffickerAgeSelector = ['$scope', 'DbmResource', '$q', 'MIN_SELECT_REFRESH', function($scope, DbmResource, $q, MIN_SELECT_REFRESH) {
  $scope.helpers = {
    disabled: undefined,
    searchResults: [],
    placeholderLabel: $scope.placeholderLabel
  };

  $scope.fromVals = [
    {pretty: "18", val: 18},
    {pretty: "25", val: 25},
    {pretty: "35", val: 35},
    {pretty: "45", val: 45},
    {pretty: "55", val: 55},
    {pretty: "65", val: 65}
  ];
  $scope.toVals   = [
    {pretty: "25", val: 25},
    {pretty: "35", val: 35},
    {pretty: "45", val: 45},
    {pretty: "55", val: 55},
    {pretty: "65", val: 65},
    {pretty: "Any", val: '+'}
  ];
  return $scope.unknownVals = [
    {pretty: "Yes", val: true},
    {pretty: "No", val: false}
  ];
}
];

angular.module('kazaam.directives').directive('traffickerAgeSelector', [ () =>
  ({
    restrict:     'AE',
    transclude:   true,
    scope: {
      currentSettings:      '=settings',
      placeholderLabel:     '=',
      sheet:                '=',
      dcmPlacementParam:    '=',
      mappingType:          '=',
      settingsKey:          '=',
      resourceType:         '=?',
      supportsLogicTree:    '=?',
      generalSettings:      '=?'
    },
    templateUrl: 'directives/trafficker-age-selector.html',
    controller: traffickerAgeSelector
  })

]);
