// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives').directive('startEndDatepicker', [ () =>
  ({
    restrict:     'AE',
    transclude:   true,
    scope: {
      chartSettings:  '=',
      rawDates:       '=',
      form:           '=',
      onChange:       '&',
      modelKey:       '@',
      dateMap:        '='
    },
    templateUrl: 'directives/start-end-datepicker.html',
    controller: 'startEndDatepickerCtrl',
    link(scope, elem, attrs) {
      scope.opened = {start: false, end: false};

      scope.openStartDate = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        scope.opened.start = true;
        return scope.opened.end = false;
      };

      return scope.openEndDate = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        scope.opened.start = false;
        return scope.opened.end = true;
      };
    }
  })


]);
