// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('User', ['RailsResource', 'oktaAuthClient', function(RailsResource, oktaAuthClient) {
  let User;
  return User = (function() {
    User = class User extends RailsResource {
      static initClass() {
        this.configure({url: '/api/users', name: 'user'});
      }

      static me() {
        // Maybe we eventually do something with okta here?
        //if @oktaSupported()
        //  @oktaSessionToken()
        return this.$get("/user_sessions/me").then(u => u);
      }

      isAdmin() { return this.role === "admin"; }
      isAdOps() { return this.role === "ad_ops"; }
      isStaff() { return this.role === "staff"; }
      isUser() { return this.role === "user"; }
      isUploader() { return this.role === "uploader"; }
      isMediaPlanner() { return this.role === "media_planner"; }

      canUse(siteSection, currentSite) {
        const allowed = (() => { switch (siteSection) {
          case "adminMenu":          return this.isAdmin() || this.isAdOps() || this.isMediaPlanner();
          case "trafficker":         return this.isAdmin() || this.isAdOps();
          case "query-engine":       return this.isAdmin() || this.isAdOps();
          case "mediaPlanner":       return this.isAdmin() || this.isAdOps() || this.isMediaPlanner();
          case "activator":          return !(this.isUser() || this.isUploader() || this.isMediaPlanner());
          case "overview":           return !this.isUploader();
          case "s3Uploader":         return true;
          default: return this.isAdmin();
        } })();
        return allowed && currentSite.hasFeature(siteSection);
      }

      static oktaSupported() { return oktaAuthClient.supported; }
      static oktaSessionToken() {
        if (!this.oktaSupported) {
          return;
        }

        const { client } = oktaAuthClient;
        const authParams = oktaAuthClient.params;
        return client.session.exists().then( function(exists) {
          if (exists) {
            const issuer = authParams["issuer"];
            const respType = authParams["responseType"];
            return client.token.getWithoutPrompt({issuer, responseType: respType}).then(function(tokens) {
              /*
              if (typeof console !== 'undefined' && console !== null) {
                console.log('Hi i got a session token', tokens);
              }
              */
              return tokens[0].idToken;
            });
          } else {
            /*
            if (typeof console !== 'undefined' && console !== null) {
              console.log("Not logged in");
            }
            */
            return undefined;
          }
        });
      }
    };
    User.initClass();
    return User;
  })();
}
]);
