// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives').directive('analyticsChartWidget', [ () =>
  ({
    restrict:     'AE',
    transclude:   true,
    scope: {
      showGraphControls:  '=',
      chartSettings:      '=',
      rawData:            '=',
      resultsData:        '=',
      report:             '=',
      onRemove:           '&',
      onClone:            '&',
      form:               '='
    },
    templateUrl: 'directives/analytics-chart-widget.html',
    controller: 'analyticsChartWidgetCtrl'
  })

  //link: (scope, elem, attrs) -> true
]);
