'use strict';

angular.module('kazaam.models').factory('MediaProductAdServingFee', ['RailsResource', 'railsSerializer', (RailsResource, railsSerializer) => {
  let MediaProductAdServingFee;
  const ser = railsSerializer( function() {
    this.exclude('createdAt');
    this.exclude('updatedAt');
  });

  return MediaProductAdServingFee = (() => {
    MediaProductAdServingFee = class MediaProductAdServingFee extends RailsResource {
      static initClass() {
        this.configure({url: '/api/media_product_ad_serving_fees', name: 'media_product_ad_serving_fee', serializer: ser});
      }
    };
    MediaProductAdServingFee.initClass();
    return MediaProductAdServingFee;
  })();
}
]);
