// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers')
  .controller('answerMetaModalCtrl', ['$scope', '$modalInstance', 'answer', 'CORRECTNESS_VALUES', function($scope, $modalInstance, answer, CORRECTNESS_VALUES) {
    $scope.answer = answer;
    $scope.correctnessValues = CORRECTNESS_VALUES;

    return $scope.saveAnswer = () => $modalInstance.close($scope.answer);
  }

  ]);
