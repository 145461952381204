// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives')
  .directive('scrollableMultiSelect', ['$templateCache', '$compile', '$timeout', ($templateCache, $compile, $timeout) =>
    ({
      restrict: 'A',
      controller($scope) {
        const { $select } = $scope;
        let placeholderUpdated = false;

        return $scope.$watch('$select.selected', function(newV, oldV) {
          if (newV.length > 9) {
            $scope.showDetachedInput = true;
            $select.placeholder = `Total selected: ${newV.length} | Select more...`;
            return placeholderUpdated = true;
          } else {
            $scope.showDetachedInput = false;

            if (placeholderUpdated) {
              $select.placeholder = `Search for ${$scope.helpers.placeholderLabel}`;
              return placeholderUpdated = false;
            }
          }
        });
      },

      compile(tElem, tAttrs) {
        return function(scope, elem, attrs) {
          const template = $templateCache.get('directives/scrollable-multi-select-wrapper.html');
          const compiledTpl = $compile(template)(scope);

          return elem.after(compiledTpl);
        };
      }
    })
  
  ]);
