'use strict';

angular.module('kazaam.models').factory('NetsuiteJob', ['RailsResource', 'railsSerializer', (RailsResource, railsSerializer) => {
  let NetsuiteJob;
  const ser = railsSerializer( function() {
    this.exclude('createdAt');
    this.exclude('updatedAt');
  });

  return NetsuiteJob = (() => {
    NetsuiteJob = class NetsuiteJob extends RailsResource {
      static initClass() {
        this.configure({url: '/api/netsuite_jobs', name: 'netsuite_job', serializer: ser});
      }

      nameAndId() {
        return `${this.name} (${this.netsuiteId})`;
      }
    };
    NetsuiteJob.initClass();
    return NetsuiteJob;
  })();
}
]);
