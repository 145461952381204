// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('AudienceFile', ['RailsResource', 'railsSerializer', function(RailsResource, railsSerializer) {
  let AudienceFile;
  const ser = railsSerializer( function() {
    this.preserve('headerMeta');
    this.exclude('originUrl');
    this.exclude('contentType');
    this.exclude('size');
    this.exclude('rowCount');
    this.exclude('contentSample');
    return this.exclude('analysis');
  });

  return AudienceFile = (function() {
    AudienceFile = class AudienceFile extends RailsResource {
      static initClass() {
        this.configure({url: '/api/audience_files', name: 'audience_file', serializer: ser});
  
        this.prototype.isFileBacked = true;
      }

      // Returns true/false depending if the headers have been set or not
      headersReady() { return (this.headerMap != null ? this.headerMap.length : undefined) > 0; }
      markForDelete() { return this._destroy = 1; }

      name() { return this.fname; }

      hasBadRecords() {
        if ((this.analysis === null) || (this.analysis === undefined)) {
          return true;
        }

        this.prettyErrors = {
          "missingPii": {msg: "Missing Personal Info",  threshold: 0},
          "shortZips":  {msg: "Short ZIP codes",        threshold: 0},
          "missingIds": {msg: "Missing Unique IDs",     threshold: 0},
          "dupeIds":    {msg: "Duplicate Unique IDs",   threshold: Math.round(this.analysis.rowCount * 0.10, 0)}
        };
        for (let key in this.analysis.details) {
          const cnt = this.analysis.details[key];
          if (cnt > this.prettyErrors[key].threshold) {
            return true;
          }
        }
        return false;
      }

      invalidRate() {
        if ((this.analysis != null ? this.analysis.rowCount : undefined) > 0) {
          return this.analysis.invalidCount / this.analysis.rowCount;
        } else {
          return NaN;
        }
      }

      refreshContentSample() {
        // POST to the refresh url, merge in the sample data returned
        const url = this.$url(`/update_content_sample?col_sep=${encodeURIComponent(this.colSep)}`);
        return AudienceFile.$post(url).then( otherAudFile => {
          this.contentSample    = otherAudFile.contentSample;
          this.contentEncoding  = otherAudFile.contentEncoding;
          this.headerMap        = otherAudFile.headerMap;
          this.headerPresent    = otherAudFile.headerPresent;
          this.headerMeta       = otherAudFile.headerMeta;
          return this.colSep           = otherAudFile.colSep;
        });
      }
    };
    AudienceFile.initClass();
    return AudienceFile;
  })();
}
]);
