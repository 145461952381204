// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('ActivatorEtlJob', ['RailsResource', 'railsSerializer', 'friendlyEtlStatus', 'highChartsHelpers', 'ActivatorEtlFile',
function(RailsResource, railsSerializer, friendlyEtlStatusMap, hch, ActivatorEtlFile) {
  let ActivatorEtlJob;
  const ser = railsSerializer( function() {
    this.resource('client', 'Client');
    this.exclude('client');
    this.exclude('selectedPaths');
    this.exclude('activatorStandardizedJob');
    this.resource('etlFiles', 'ActivatorEtlFile');
    return this.nestedAttribute('etlFiles');
  });

  return ActivatorEtlJob = (function() {
    let FILE_ORIGINS = undefined;
    ActivatorEtlJob = class ActivatorEtlJob extends RailsResource {
      static initClass() {
        this.configure({url: '/api/activator_etl_jobs', name: 'activator_etl_job', serializer: ser});
  
        // REFACTOR-TODO
        FILE_ORIGINS      = ["s3", "exavault"];
      }

      totalSize() {
        return this.etlFiles.reduce( (acc, ef) => acc += ef.size
        , 0);
      }
      totalRows() {
        if (this.rowCount) {
          return this.rowCount;
        } else {
          return this.etlFiles.reduce( (acc, ef) => acc += ef.rowCount
          , 0);
        }
      }

      friendlyStatus() { return friendlyEtlStatusMap[this.status]; }
      // Sets @selectedPaths based on @audienceFiles
      hydratePaths() {
        this.selectedPaths = {};
        for (let fo of Array.from(FILE_ORIGINS)) {
          this.selectedPaths[fo] = [];
        }

        return Array.from(this.etlFiles).map((jf) =>
          this.selectedPaths[jf.origin].push(jf.fname));
      }

      fileByPath(path) {
        for (let jf of Array.from(this.etlFiles)) {
          if (jf.fname === path) {
            return jf;
          }
        }
        return undefined;
      }

      syncEtlFiles() {
        // Sanity check - ensure that selectedPaths is populated, otherwise
        // we will incorrectly detect that all files were removed.
        let af, pathObj;
        if (!this.selectedPaths) { return; }

        // Build a single array of (path, origin) tuples and use that for add/remove
        // detection.
        const allSelected = [];
        for (let fo of Array.from(FILE_ORIGINS)) {
          for (let f of Array.from(this.selectedPaths[fo])) {
            allSelected.push({path: f, origin: fo});
          }
        }

        // Now figure out what's changed
        const curPaths  = this.etlFiles.map( af => ({path: af.fname, origin: af.origin}));
        const changes   = hch.arrayChangeSet(curPaths, allSelected, this.findPathAndOrigin);

        for (pathObj of Array.from(changes.added)) {
          af = new ActivatorEtlFile({fname: pathObj.path, origin: pathObj.origin});
          this.etlFiles.push(af);
        }
        for (pathObj of Array.from(changes.removed)) {
          for (af of Array.from(this.etlFiles)) {
            if ((af.fname === pathObj.path) && (af.origin = pathObj.origin)) {
              af.markForDelete();
              break;
            }
          }
        }

        return true;
      }

      //analyzeFiles: ->
      //  url = @$url("/analyze-files")
      //  ActivatorEtlJob.$post(url)

      save() {
        this.syncEtlFiles();
        return super.save(...arguments);
      }

      approve() {
        const url = this.$url("/approve");
        return ActivatorEtlJob.$post(url);
      }

      reset() {
        const url = this.$url("/reset");
        return ActivatorEtlJob.$post(url);
      }

      isLocked() { return this.status !== 'awaiting_approval'; }
      isFailed() { return this.status === 'failed'; }
      isDone() { return ['failed', 'completed', 'cancelled'].indexOf(this.status) !== -1; }

      isApprovable() {
        return !(this.isDone() || this.isLocked());
      }

      // Compat stubs
      wantsFacebookSegments() { return false; }

      standardJobColumnsMappedHelper (cols) {
        const klass = this;
        let found = {};
        cols.forEach((col) => {
          found[col] = false;
        });
        cols.forEach((col) => {
          klass.etlFiles.forEach((etlFile) => {
            if (etlFile.headerMap.indexOf(col) > 0) {
              found[col] = true;
            }
          });
        });
        return found;
      }

      requiredColumns() {
        if (this.activatorStandardizedJob) {
          return this.activatorStandardizedJob.requiredColumns;
        } else {
          return [];
        }
      }

      requiredColumnsBreakdown() {
        return this.standardJobColumnsMappedHelper(this.requiredColumns());
      }

      requiredColumnsPresent() {
        return Object.values(this.requiredColumnsBreakdown()).every((elm) => { return elm === true });
      }

      optionalColumns() {
        if (this.activatorStandardizedJob) {
          return this.activatorStandardizedJob.optionalColumns;
        } else {
          return [];
        }
      }

      optionalColumnsBreakdown() {
        return this.standardJobColumnsMappedHelper(this.optionalColumns());
      }
    };
    ActivatorEtlJob.initClass();
    return ActivatorEtlJob;
  })();
}

]);
