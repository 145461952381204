// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam').config(['$stateProvider', '$locationProvider',
  function($stateProvider, $locationProvider) {
    $stateProvider
      .state('abstract-root', {
        abstract:         true,
        views: {
          main: {
            template:     "<ui-view />"
          },
          sidebar: {
            templateUrl:  "partials/sidebar.html"
          },
          navigation: {
            templateUrl:  "partials/navigation.html",
            controller:   "NavigationCtrl",
            resolve: {
              currentUser: ['currentUser', currentUser => currentUser ]
            }
          }
        },
        resolve: {
          currentUser:    ['User', User => User.me()]
        }
      })
      .state('overview', {
        url:          "/",
        parent:       "abstract-root",
        controllerProvider: ['currentUser', function(u) {
          if (u.isUser() || u.isUploader()) {
            return "UploaderOverviewController";
          } else {
            return "StaffOverviewController";
          }
        }
        ],
        templateProvider: ['$templateCache', 'currentUser', function($templateCache, u) {
          if (u.isUser()) {
            return $templateCache.get("overview-users.html");
          } else if (u.isUploader()) {
            return '';
          } else {
            return $templateCache.get("overview-staff.html");
          }
        }
        ],
        resolve: {
          allReports: [ () => { return []; } ]
        }
      })
      .state('audience', {
        abstract: true,
        parent: 'abstract-root',
        template: '<ui-view />'
      }
      )
      .state('activatorEtl', {
        abstract: true,
        parent: 'abstract-root',
        template: '<ui-view />'
      }
      )
      .state('activatorEtl.list', {
        url:          "/activator-loader?:page&:filterField&:filterValue",
        templateUrl:  "activator-etl/list.html",
        controller:   "ActivatorEtlListController",
        resolve: {
          currentPage:  ['$stateParams', function($stateParams) {
            if (typeof $stateParams.page !== 'undefined') { return $stateParams.page; } else { return 1; }
          }
          ],
          jobsFull:  ['ActivatorEtlJob', '$stateParams', 'currentPage', function(ActivatorEtlJob, $stateParams, currentPage) {
            const opts = {};
            opts.page = currentPage;

            // Filtering params
            if (typeof $stateParams.filterField !== 'undefined') { opts.filterField = $stateParams.filterField; }
            if (typeof $stateParams.filterValue !== 'undefined') { opts.filterValue = $stateParams.filterValue; }

            // Need to set full response to true here so that we can get the meta data. This is false by default.
            // IMPORTANT: remember to reset it to false after the query call to ensure no blowups further down the tree
            ActivatorEtlJob.config.fullResponse = true;
            return ActivatorEtlJob.query(opts).then( function(results) {
              ActivatorEtlJob.config.fullResponse = false;
              return results;
            });
          }
          ],
          etlJobs:    ['jobsFull', etlFull => etlFull.data
          ],
          pageMeta:   ['jobsFull', etlFull => etlFull.originalData.meta
          ]
        },
        data: {
          title:      "Snowflake ETL"
        }
      }
      )
      .state('activatorEtl.new', {
        // This state *must* come before activatorEtl.viewer, since otherwise that one will match and we'll be trying
        // to retrieve the ActivatorEtlJob with id 'new'
        url:          "/activator-loader/new",
        controller:   "ActivatorEtlBuilderCtrl",
        templateUrl:  "activator-etl/builder.html",
        resolve: {
          etlJob:     ['ActivatorEtlJob', ActivatorEtlJob => new ActivatorEtlJob({etlFiles: []})],
          stdJobs:    ['ActivatorStandardizedJob', ActivatorStandardizedJob => ActivatorStandardizedJob.query({page: "all"})],
          clients:    ['Client',        Client => Client.query({page: "all"})]
        },
        data: {
          title:      "Create New Audience"
        }
      }
      )
      .state('activatorEtl.viewer', {
        url:          "/activator-loader/:jobId",
        templateUrl:  "activator-etl/show.html",
        controller:   "ActivatorEtlShowCtrl",
        resolve: {
          etlJob:     ['ActivatorEtlJob', '$stateParams', (ActivatorEtlJob, $stateParams) => ActivatorEtlJob.get($stateParams.jobId)],
          creator:    ['User', 'etlJob', (User, etlJob) => User.get(etlJob.creatorId)]
        },
        data: {
          title:      "Job Summary"
        }
      }
      )
      .state('activatorEtl.edit', {
        url:          "/activator-loader/:jobId/edit",
        controller:   "ActivatorEtlBuilderCtrl",
        templateUrl:  "activator-etl/builder.html",
        resolve: {
          etlJob:     ['ActivatorEtlJob', '$stateParams', (ActivatorEtlJob, $stateParams) => ActivatorEtlJob.get($stateParams.jobId)],
          stdJobs:    ['ActivatorStandardizedJob', ActivatorStandardizedJob => ActivatorStandardizedJob.query({page: "all"})],
          clients:    ['Client',        Client => Client.query({page: "all"})]
        },
        data: {
          title:      "Edit Job"
        }
      }
      )
      .state('activatorEtl.fields', {
        url:          "/activator-loader/:jobId/fields",
        controller:   "ActivatorEtlFieldManagerCtrl",
        templateUrl:  "activator-etl/fields.html",
        resolve: {
          etlJob:     ['ActivatorEtlJob', '$stateParams', (ActivatorEtlJob, $stateParams) => ActivatorEtlJob.get($stateParams.jobId)]
        },
        data: {
          title:      "Header Management"
        }
      }
      )
      .state('uploader', {
        abstract: true,
        parent: 'abstract-root',
        template: '<ui-view />'
      }
      )
      .state('uploader.list', {
        url:          "/audience-activator?:visibility&:page&:filterField&:filterValue",
        templateUrl:  "uploader/list.html",
        controller:   "UploaderListController",
        resolve: {
          channels:           ['AdBuyChannel',  AdBuyChannel => AdBuyChannel.query()],
          vendors:            ['MatchVendor',   MatchVendor => MatchVendor.query({perPage: 100})],
          currentPage:        ['$stateParams', ($sp) => { if (typeof $sp.page !== 'undefined') { return $sp.page; } else { return 1; } }],
          visibility:         ['$stateParams', ($sp) => { if (typeof $sp.visibility !== 'undefined') { return $sp.visibility; } else { return 'user'; } }],
          uaFull:             ['UploadedAudience', '$stateParams', 'currentPage', 'visibility', function(UploadedAudience, $stateParams, currentPage, visibility) {
            const opts = {page: currentPage, visibility: visibility};

            // Filtering params
            if (typeof $stateParams.filterField !== 'undefined') { opts.filterField = $stateParams.filterField; }
            if (typeof $stateParams.filterValue !== 'undefined') { opts.filterValue = $stateParams.filterValue; }

            // Need to set full response to true here so that we can get the meta data. This is false by default.
            // IMPORTANT: remember to reset it to false after the query call to ensure no blowups further down the tree
            UploadedAudience.config.fullResponse = true;
            return UploadedAudience.query(opts).then( function(results) {
              UploadedAudience.config.fullResponse = false;
              return results;
            });
          }
          ],
          uploadedAudiences:  ['uaFull', uaFull => uaFull.data
          ],
          pageMeta:   ['uaFull', uaFull => uaFull.originalData.meta
          ]
        },
        data: {
          title:      "Audience Activator"
        }
      }
      )
      .state('uploader.new', {
        // This state *must* come before uploader.viewer, since otherwise that one will match and we'll be trying
        // to retrieve the UploadedAudience with id 'new'
        url:          "/audience-activator/new?:queryId",
        controller:   "UploaderBuilderController",
        templateUrl:  "uploader/builder.html",
        resolve: {
          audience:   ['UploadedAudience', UploadedAudience => new UploadedAudience({channels: [], audienceFiles: [], audienceQueryUploadMetadata: []})],
          channels:   ['AdBuyChannel',  AdBuyChannel => AdBuyChannel.query({page: "all"})],
          clients:    ['Client',        Client => Client.query({page: "all"})],
          vendors:    ['MatchVendor',   MatchVendor => MatchVendor.query({perPage: 100})],
          queries:    ['AudienceQuery', AudienceQuery => AudienceQuery.query({perPage: 200})],
          selectedQry:  ['AudienceQuery', '$stateParams', 'queries', function(AudienceQuery, $stateParams, queries) {
            const qryId = Number($stateParams.queryId);
            if (qryId) {
              const qry = queries.find(elm => elm.id === qryId);
              if (qry) {
                return qry;
              } else {
                return AudienceQuery.get(qryId);
              }
            }
          }
          ]
        },
        data: {
          title:      "Create New Audience"
        }
      }
      )
      .state('uploader.viewer', {
        url:          "/audience-activator/:audienceId",
        templateUrl:  "uploader/report.html",
        controller:   "UploaderReportCtrl",
        resolve: {
          audience:   ['UploadedAudience', '$stateParams', (UploadedAudience, $stateParams) => UploadedAudience.get($stateParams.audienceId)],
          vendors:    ['MatchVendor', MatchVendor => MatchVendor.query({perPage: 100})],
          creator:    ['User', 'audience', (User, audience) => User.get(audience.creatorId)],
          emails:     ['User', User => User.query().then( users =>
                          users.map( u => u.email)
                        )  ]
        },
        data: {
          title:      "Summary"
        }
      }
      )
      .state('uploader.edit', {
        url:          "/audience-activator/:audienceId/edit",
        controller:   "UploaderBuilderController",
        templateUrl:  "uploader/builder.html",
        resolve: {
          audience:   ['UploadedAudience', '$stateParams', (UploadedAudience, $stateParams) => UploadedAudience.get($stateParams.audienceId)],
          channels:   ['AdBuyChannel',  AdBuyChannel => AdBuyChannel.query({page: "all"})],
          clients:    ['Client',        Client => Client.query({page: "all"})],
          vendors:    ['MatchVendor',   MatchVendor => MatchVendor.query({perPage: 100})],
          queries:    ['AudienceQuery', AudienceQuery => AudienceQuery.query({perPage: 200})],
          selectedQry:  [ () => undefined ]
        },
        data: {
          title:      "Edit Audience"
        }
      }
      )
      .state('uploader.fields', {
        url:          "/audience-activator/:audienceId/fields",
        controller:   "UploaderFieldManagerCtrl",
        templateUrl:  "uploader/fields.html",
        resolve: {
          audience:   ['UploadedAudience', '$stateParams', (UploadedAudience, $stateParams) => UploadedAudience.get($stateParams.audienceId)],
          vendors:    ['MatchVendor',   MatchVendor => MatchVendor.query({perPage: 100})]
        },
        data: {
          title:      "Header Management"
        }
      }
      )
      .state('uploader.randomizer', {
        url:          "/audience-activator/:audienceId/segments",
        controller:   "UploaderSegmentationCtrl",
        templateUrl:  "uploader/segmentation.html",
        resolve: {
          audience:   ['UploadedAudience', '$stateParams', (UploadedAudience, $stateParams) => UploadedAudience.get($stateParams.audienceId)],
          vendors:    ['MatchVendor',   MatchVendor => MatchVendor.query({perPage: 100})]
        },
        data: {
          title:      "Manage Segments"
        }
      }
      )
      .state('trafficker', {
        abstract: true,
        parent: 'abstract-root',
        template: '<ui-view />'
      }
      )
      .state('trafficker.list', {
        url:          "/trafficker?:page&:filterField&:filterValue",
        templateUrl:  "trafficker/list.html",
        controller:   "TraffickerListController",
        resolve: {
          currentPage:        ['$stateParams', function($stateParams) {
            if (typeof $stateParams.page !== 'undefined') { return $stateParams.page; } else { return 1; }
          }
          ],
          sheetsFull:         ['DcmSheet', '$stateParams', 'currentPage', function(DcmSheet, $stateParams, currentPage) {
            const opts = {};
            opts.page = currentPage;

            // Filtering params
            if (typeof $stateParams.filterField !== 'undefined') { opts.filterField = $stateParams.filterField; }
            if (typeof $stateParams.filterValue !== 'undefined') { opts.filterValue = $stateParams.filterValue; }

            // Need to set full response to true here so that we can get the meta data. This is false by default.
            // IMPORTANT: remember to reset it to false after the query call to ensure no blowups further down the tree
            DcmSheet.config.fullResponse = true;
            return DcmSheet.query(opts).then( function(results) {
              DcmSheet.config.fullResponse = false;
              return results;
            });
          }
          ],
          dcmSheets:  ['sheetsFull', sheetsFull => sheetsFull.data ],
          pageMeta:   ['sheetsFull', sheetsFull => sheetsFull.originalData.meta ]
        },
        data: {
          title:      "Trafficking Jobs"
        }
      }
      )
      .state('trafficker.new', {
        // This state *must* come before trafficker.viewer, as above
        url:          "/trafficker/new",
        controller:   "TraffickerBuilderController",
        templateUrl:  "trafficker/builder.html",
        resolve: {
          sheet:      ['DcmSheet', DcmSheet => new DcmSheet()],
          clients:    ['Client',   Client => Client.query({page: "all"})]
        },
        data: {
          title:      "New Trafficking Job"
        }
      }
      )
      .state('trafficker.audience-builder', {
        url:          "/trafficker/audiences?:page&:filterField&:filterValue&:new",
        controller:   "TraffickerAudienceBuilderController",
        templateUrl:  "trafficker/audience-builder.html",
        resolve: {
          currentPage:  ['$stateParams', function($stateParams) {
            if (typeof $stateParams.page !== 'undefined') { return $stateParams.page; } else { return 1; }
          }
          ],
          audFull:      ['DbmTargetedAudience', '$stateParams', 'currentPage', function(DbmTargetedAudience, $stateParams, currentPage) {
            const opts = {page: currentPage};

            // Filtering params
            if (typeof $stateParams.filterField !== 'undefined') { opts.filterField = $stateParams.filterField; }
            if (typeof $stateParams.filterValue !== 'undefined') { opts.filterValue = $stateParams.filterValue; }

            // Need to set full response to true here so that we can get the meta data. This is false by default.
            // IMPORTANT: remember to reset it to false after the query call to ensure no blowups further down the tree
            DbmTargetedAudience.config.fullResponse = true;
            return DbmTargetedAudience.query(opts).then( function(results) {
              DbmTargetedAudience.config.fullResponse = false;
              return results;
            });
          }
          ],
          audiences:  ['audFull', audFull => audFull.data ],
          pageMeta:   ['audFull', audFull => audFull.originalData.meta ]
        },
        data: {
          title:      "DBM Targeted Audience Builder"
        }
      }
      )
      .state('trafficker.viewer', {
        url:          "/trafficker/:sheetId",
        controller:   "TraffickerBuilderController",
        templateUrl:  "trafficker/builder.html",
        resolve: {
          sheet:      ['DcmSheet', '$stateParams', (DcmSheet, $stateParams) => DcmSheet.get($stateParams.sheetId)],
          clients:    ['Client',   Client => Client.query({page: "all"})]
        },
        data: {
          title:      "Trafficking Job Details"
        }
      }
      )
      .state('trafficker.targeting', {
        url:          "/trafficker/:sheetId/targeting",
        controller:   "TraffickerTargetingController",
        templateUrl:  "trafficker/targeting.html",
        resolve: {
          sheet:      ['DcmSheet', '$stateParams', (DcmSheet, $stateParams) => DcmSheet.get($stateParams.sheetId)],
          dpps:       ['sheet', sheet => sheet.dcmPlacementParams ],
          creatives:  ['sheet', sheet => sheet.dcmCreatives ],
          audiences:  ['DbmTargetedAudience', DbmTargetedAudience => DbmTargetedAudience.query({page: "all"})]
        },
        data: {
          title:      "Targeting Parameters"
        }
      }
      )
      .state('trafficker.lineitems', {
        url:          "/trafficker/:sheetId/line-items",
        controller:   "TraffickerLineItemsController",
        templateUrl:  "trafficker/line-items.html",
        resolve: {
          sheet:      ['DcmSheet', '$stateParams', (DcmSheet, $stateParams) => DcmSheet.get($stateParams.sheetId)],
          lineItems:  ['sheet', sheet => sheet.dbmLineItems]
        },
        data: {
          title:      "Line Item Settings"
        }
      }
      )
      .state('activatorQueries', {
        abstract: true,
        parent: 'abstract-root',
        template: '<ui-view />'
      }
      )
      .state('activatorQueries.list', {
        url:          "/activator-queries?:page&:filterField&:filterValue",
        templateUrl:  "activator-queries/list.html",
        controller:   "FilterableListController",
        resolve: {
          gridColumnDefs:   [ () => [
            {displayName: "Client",         field: "client.name", enableFiltering: true, filterParam: "client_name"},
            {displayName: "Name",           field: "name", cellTemplate: "grid-cell-report-link.html", enableFiltering: true, filterParam: "name"},
            {displayName: "Date",           field: "createdAt", cellFilter: "date"},
            {displayName: "Creator",        field: "creator.email", enableFiltering: true, filterParam: "creator_email"},
            {displayName: "In Library",     field: "bookmarked", enableFiltering: true, filterParam: "bookmarked"},
            {displayName: "Counts",         field: "result", cellTemplate: "activator-queries/grid-cell-counts.html", enableCellEdit: false}
          ]  ],
          currentPage:     ['$stateParams', function($stateParams) { if (typeof $stateParams.page !== 'undefined') { return $stateParams.page; } else { return 1; } } ],
          queriesFull:     ['AudienceQuery', '$stateParams', 'currentPage', function(AudienceQuery, $stateParams, currentPage) {
            const opts = {};
            opts.page = currentPage;

            // Filtering params
            if (typeof $stateParams.filterField !== 'undefined') { opts.filterField = $stateParams.filterField; }
            if (typeof $stateParams.filterValue !== 'undefined') { opts.filterValue = $stateParams.filterValue; }

            // Need to set full response to true here so that we can get the meta data. This is false by default.
            // IMPORTANT: remember to reset it to false after the query call to ensure no blowups further down the tree
            AudienceQuery.config.fullResponse = true;
            return AudienceQuery.query(opts).then( function(results) {
              AudienceQuery.config.fullResponse = false;
              return results;
            });
          }
          ],
          listItems:      ['queriesFull', queriesFull => queriesFull.data ],
          pageMeta:       ['queriesFull', queriesFull => queriesFull.originalData.meta ],
          secondaryNav:   [ () => undefined ]
        },
        data: {
          title:      "Activator Queries"
        }
      }
      )
      .state('activatorQueries.new', {
        // This state *must* come before trafficker.viewer, as above
        url:          "/activator-queries/new?:page",
        controller:   "ActivatorQueryBuilderController",
        templateUrl:  "activator-queries/builder.html",
        resolve: {
          clients:        ['Client',         Client => Client.query({page: "all"})],
          myQueries:      ['AudienceQuery', 'currentUser',  (AudienceQuery, user) => AudienceQuery.query({filterField: "creator_email", filterValue: user.email})],
          baseQry:        ['AudienceQuery',  AudienceQuery => AudienceQuery.query({filterField: "name", filterValue: "AB Base Query"}).then( res => res[0])],
          query:          ['AudienceQuery', 'baseQry', function(AudienceQuery, baseQry) {
            const aq = new AudienceQuery();
            if (baseQry) {
              aq.inputSql = angular.copy(baseQry.inputSql);
            }
            return aq;
          }],
          page:           ['$stateParams', ($sp) => $sp.page]
        },
        data: {
          title:      "New Query"
        }
      }
      )
      .state('activatorQueries.viewer', {
        url:          "/activator-queries/:queryId?:page",
        controller:   "ActivatorQueryBuilderController",
        templateUrl:  "activator-queries/builder.html",
        resolve: {
          query:          ['AudienceQuery', '$stateParams', (AudienceQuery, $stateParams) => AudienceQuery.get($stateParams.queryId)],
          myQueries:      ['AudienceQuery', 'currentUser',  (AudienceQuery, user) => AudienceQuery.query({filterField: "creator_email", filterValue: user.email})],
          clients:        ['Client',         Client => Client.query({page: "all"})],
          page:           ['$stateParams', ($sp) => $sp.page]
        },
        data: {
          title:      "Query Details"
        }
      }
      )
      .state('s3Uploader', {
        abstract: true,
        parent: 'abstract-root',
        template: '<ui-view />'
      }
      )
      .state('s3Uploader.uploader', {
        url:          "/transfer?:clientId",
        controller:   "S3TransferController",
        controllerAs: "$ctrl",
        templateUrl:  "s3-uploader/transfer.html",
        resolve: {
          clients:    ['Client', Client => Client.query({page: "all"})]
        },
        data: {
          title:      "Transfer Files"
        }
      }
      )
      .state('mediaCampaigns', {
        abstract: true,
        parent: 'abstract-root',
        template: '<ui-view />'
      }
      )
      .state('mediaCampaigns.list', {
        url:          "/media-campaigns?:page&:filterField&:filterValue",
        templateUrl:  "media-campaigns/list.html",
        controller:   "FilterableListController",
        resolve: {
          gridColumnDefs:   [ () => [
            {displayName: "Advertiser",       field: "client.name", enableFiltering: true, filterParam: "client_name"},
            {displayName: "Media Plan Name",  field: "name", cellTemplate: "grid-cell-report-link.html", enableFiltering: true, filterParam: "name"},
            {displayName: "File Name",        field: "planFile", enableFiltering: false},
            {displayName: "Status",           field: "statusText()", enableFiltering: false},
            {displayName: "Date Uploaded",    field: "createdAt", cellFilter: "date"},
            {displayName: "Creator",          field: "creator.email", enableFiltering: true, filterParam: "creator_email"}
          ]  ],
          currentPage:    ['$stateParams', function($stateParams) { if (typeof $stateParams.page !== 'undefined') { return $stateParams.page; } else { return 1; } } ],
          campaignsFull:  ['MediaCampaign', '$stateParams', 'currentPage', function(MediaCampaign, $stateParams, currentPage) {
            const opts = {};
            opts.page = currentPage;

            // Filtering params
            if (typeof $stateParams.filterField !== 'undefined') { opts.filterField = $stateParams.filterField; }
            if (typeof $stateParams.filterValue !== 'undefined') { opts.filterValue = $stateParams.filterValue; }

            // Need to set full response to true here so that we can get the meta data. This is false by default.
            // IMPORTANT: remember to reset it to false after the query call to ensure no blowups further down the tree
            MediaCampaign.config.fullResponse = true;
            return MediaCampaign.query(opts).then( function(results) {
              MediaCampaign.config.fullResponse = false;
              return results;
            });
          }
          ],
          listItems:    ['campaignsFull', campaignsFull => campaignsFull.data ],
          pageMeta:     ['campaignsFull', campaignsFull => campaignsFull.originalData.meta ],
          secondaryNav: ['MEDIA_CAMPAIGNS_SECONDARYNAV', nav => nav]
        },
        data: {
          title:      "Media Plans Submission Status"
        }
      }
      )
      .state('mediaCampaigns.new', {
        // This state *must* come before <route>.viewer, as above
        url:          "/media-campaigns/new",
        controller:   "MediaCampaignBuilderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-campaigns/setup.html",
        resolve: {
          clients:    ['Client',        Client => Client.query({page: "all"})],
          campaign:   ['MediaCampaign', MediaCampaign => new MediaCampaign({assetObjs: [], creativeConcepts: []}) ]
        },
        data: {
          title:      "Create & Upload Media Plan"
        }
      }
      )
      .state('mediaCampaign', {
        abstract: true,
        parent: 'abstract-root',
        template: '<ui-view />',
        resolve: {
          campaign:   ['MediaCampaign', '$stateParams', (MediaCampaign, $stateParams) => MediaCampaign.get($stateParams.campaignId)],
          clients:    ['campaign',                      campaign => [campaign.client]]
        }
      }
      )
      .state('mediaCampaign.show', {
        url:          "/media-campaigns/:campaignId",
        controller:   "MediaCampaignBuilderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-campaigns/review.html",
        data: {
          title:      "Review & Submit Media Plan"
        }
      }
      )
      .state('mediaCampaign.edit', {
        url:          "/media-campaigns/:campaignId/edit",
        controller:   "MediaCampaignBuilderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-campaigns/builder.html",
        data: {
          title:      "Edit Media Plan"
        }
      }
      )
      .state('mediaCampaign.setup', {
        url:          "/media-campaigns/:campaignId/setup",
        controller:   "MediaCampaignBuilderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-campaigns/setup.html",
        data: {
          title:      "Setup & Sync Media Plan"
        }
      }
      )
      .state('mediaCampaign.terms', {
        url:          "/media-campaigns/:campaignId/terms",
        controller:   "MediaCampaignBuilderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-campaigns/terms.html",
        data: {
          title:      "Search Terms (Optional)"
        }
      }
      )
      .state('mediaCampaign.concepts', {
        url:          "/media-campaigns/:campaignId/concepts",
        controller:   "MediaCampaignBuilderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-campaigns/concepts.html",
        data: {
          title:      "Creative Concepts (Optional)"
        }
      }
      );

    return $locationProvider.html5Mode(true);
  }
]);
