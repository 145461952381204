// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives')
  .directive('answerMeta', ['$modal', $modal =>
    ({
      restrict: 'AE',
      templateUrl: 'directives/answer-meta.html',
      scope: {
        answers: '=',
        answerSettings: '=',
        sortableOptions: '='
      },
      link(scope, elem, attrs) {

        return scope.open = function(ans) {
          const modalInst = $modal.open({
            animation: true,
            templateUrl: 'answer-meta-modal.html',
            controller: 'answerMetaModalCtrl',
            size: 'md',
            resolve: {
              answer() { return ans; }
            }
          });

          return modalInst.result.then( function(answer) {
            console.log('Closing modal: ', answer);
            if (typeof attrs.callback !== 'undefined') {
              return scope.$parent.$eval(attrs.callback);
            }
          });
        };
      }
    })
  

  ]);
