'use strict';

angular.module('kazaam.models').factory('MediaPlanUploadedLineItemSheet', ['RailsResource', 'railsSerializer', 'Upload', function(RailsResource, railsSerializer, Upload) {
  let MediaPlanUploadedLineItemSheet;
  const ser = railsSerializer( function() {
    this.exclude('createdAt');
    this.exclude('updatedAt');
    return this.exclude('file'); // ng-file-upload will handle this
  });

  return MediaPlanUploadedLineItemSheet = (function() {
    MediaPlanUploadedLineItemSheet = class MediaPlanUploadedLineItemSheet extends RailsResource {
      static initClass() {
        this.configure({url: '/api/media_plan_uploaded_line_item_sheets', name: 'media_plan_uploaded_line_item_sheet', serializer: ser});
      }

      // Use our serializer to dump out the data we'd pass to a HTTP req.
      // This is necessary to get saving w/ uploads working
      serializedJson() {
        const serializer = new ser();
        return serializer.serialize(this);
      }

      sheetFilename() {
        if (!this.file) {
          return "";
        } else if (Upload.isFile(this.file)) {
          // File pending upload
          return this.file.name;
        } else if (this.file.hasOwnProperty("name")) {
          // From ROR-land with name + url
          return this.file.name;
        } else {
          return "";
        }
      }

      sheetUrl() {
        if ((this.file != null ? this.file.hasOwnProperty("url") : undefined)) {
          return this.excelSheet.url;
        } else {
          return undefined;
        }
      }

      ready() {
        const obj = this;
        // Determine if all missing values have been mapped via renaming rules
        const isReady = Object.keys(this.summary.missing).reduce( (acc, missingKey) => {
          const vals = obj.summary.missing[missingKey];

          // Nothing missing from this set, so its ready
          if (vals.length === 0) {
            return acc && true;
          }

          // Figure out if all missing values in the given category are mapped
          const keyIsReady = vals.reduce( (keyIsReadyAccum, curVal) => {
            // Can we find the individual value in the renaming rules?
            if (Array.isArray(obj.renamingRules[missingKey])) {
              const rule = obj.renamingRules[missingKey].findByKey('from', curVal);
              if (rule && rule.to) {
                // Found the rule and it is mapped correctly, so AND the previous with 'true'
                return keyIsReadyAccum && true;
              } else {
                // Not found or not mapped
                return false;
              }
            } else {
              return false;
            }
          }, true);

          return acc && keyIsReady;
        }, true);

        return isReady;
      }

      approve() {
        const url = this.$url("/approve");
        return this.$post(url);
      }
    };
    MediaPlanUploadedLineItemSheet.initClass();
    return MediaPlanUploadedLineItemSheet;
  })();
}
]);
