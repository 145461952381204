// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam').config([ '$httpProvider', $httpProvider =>
  // Add the _utf8 param so browsers will submit in UTF8
  $httpProvider.defaults.transformRequest.push(function(data, headersGetter) {
    let utf8_data = data;
    const hdrs = headersGetter();
    if (((hdrs["Content-Type"] != null ? hdrs["Content-Type"].indexOf("json") : undefined) !== -1) && !angular.isUndefined(data)) {
      const d = angular.fromJson(data);
      d["_utf8"] = "✓";
      utf8_data = angular.toJson(d);
    }
    return utf8_data;
  })
]);

angular.module('kazaam').config(['markdownConverterProvider', function (mdcp) {
  mdcp.config( Showdown.getFlavorOptions('github') );
}]);

angular.module('kazaam').run(['$state', '$raven', function($state, $raven) {
  const onError = function(err) {
    if (typeof console !== 'undefined' && console !== null) {
      console.log(err);
    }
    // See https://github.com/ui-router/core/blob/c22f694/src/transition/rejectFactory.ts#L11
    // I'm not sure of a cleaner way to get these enums.
    // Don't log SUPERSEDED(2) or IGNORED(5) errors
    if (err.hasOwnProperty('type') && ([2, 5].indexOf(err.type) === -1)) {
      $raven.captureMessage(err.toString(), {extra: {type: err.type, message: err.message}});
    }
    return false;
  };

  return $state.defaultErrorHandler(onError);
}
]);
