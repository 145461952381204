// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives')
  .directive('columnFormat', [ () =>
    ({
      scope: {
        columnOptions   : '=',
        columnIndex     : '='
      },
      restrict: 'AE',
      templateUrl: 'directives/column-format.html',
      link(scope, elem, attrs) {
        if ((typeof scope.columnOptions !== 'undefined') && (typeof scope.columnIndex !== 'undefined')) {
          for (let opt of Array.from(scope.columnOptions)) {
            if (!scope.columnIndex.hasOwnProperty(opt.name)) {
              scope.columnIndex[opt.name] = false;
            }
          }
        }

        scope.setSelected = opt => scope.columnIndex[opt.name] = !scope.columnIndex[opt.name];

        scope.removeSelected = opt => scope.columnIndex[opt.name] = false;

        return scope.anyOptionSelected = function() {
          for (let name in scope.columnIndex) {
            const val = scope.columnIndex[name];
            if (val) {
              return true;
            }
          }
          return false;
        };
      }
    })
  

  ]);
