// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('Client', ['RailsResource', function(RailsResource) {
  let Client;
  return Client = (function() {
    Client = class Client extends RailsResource {
      static initClass() {
        this.configure({url: '/api/clients', name: 'client'});
      }
    };
    Client.initClass();
    return Client;
  })();
}
]);
