// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('MediaPlanLineItemBuilderCtrl',
  ['$scope', 'plan', 'lineItem', 'versions', 'products', 'audiences', 'buckets', 'MediaPlanLineItem', '$state', '$timeout', 'MEDIA_PLANNER_SECONDARYNAV', 'VALID_DOLLARS', 'VALID_INTEGER', 'currentUser', 'MediaProduct', 'uiGridHelper', '$window',
  ($scope, plan, lineItem, versions, products, audiences, buckets, MediaPlanLineItem, $state, $timeout, MEDIA_PLANNER_SECONDARYNAV, VALID_DOLLARS, VALID_INTEGER, currentUser, MediaProduct, uigh, $window) => {
    const ctrl        = this;
    this.title        = $state.current.data.title;
    this.plan         = plan;
    this.lineItem     = lineItem;
    this.versions     = versions;
    this.audiences    = audiences;
    this.buckets      = buckets;
    this.geos         = ['Geo 1', 'Geo 2'];
    this.products     = products;
    this.currentUser  = currentUser;

    this.showSpinner        = false;
    this.showDeleteSpinner  = false;

    this.deviceTypes  = MediaPlanLineItem.deviceTypes();
    this.rateTypes    = MediaPlanLineItem.rateTypes();

    this.friendlyTypeNames = {'MediaPlanLineItem': 'Line Item', 'MediaPlanLineItemBudgetSegment': 'Budget Segment'};

    const today         = new Date();
    const tomorrow      = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const EARLIEST_POSSIBLE_DATE = new Date('2019-06-01T00:00:00');

    $scope.secondaryNav = MEDIA_PLANNER_SECONDARYNAV;
    $scope.validDollars = VALID_DOLLARS;
    $scope.validInteger = VALID_INTEGER;

    // Hydrate
    this.lineItem.hydrate(this.plan);

    // Set reasonable defaults
    if (this.audiences.length === 1) { this.lineItem.mediaPlanAudienceId = this.audiences[0].id; }
    if (this.buckets.length === 1) { this.lineItem.mediaPlanBucketId = this.buckets[0].id; }

    this.hideSpinner = () => { $timeout(() => { ctrl.showSpinner = false ; ctrl.showDeleteSpinner = false }, 100); };

    this.addBudgetSegment = () => {
      const seg = this.lineItem.addBudgetSegment();
      this.setupDpFields(seg);
      return seg;
    };

    this.productSelected = (product) => {
      if (ctrl.lineItem.isNew()) {
        // Set the rate type
        if (!ctrl.lineItem.rateType && product.lineItemDefaults.rateType) {
          ctrl.lineItem.rateType ||= product.lineItemDefaults.rateType;
        }

        // Set impressions on the first budget segment, if its not there yet
        if (product.lineItemDefaults.impressions && ctrl.lineItem.mediaPlanLineItemBudgetSegments.length === 0) {
          const seg = ctrl.addBudgetSegment();
          seg.estimatedImpressions = Number(product.lineItemDefaults.impressions);
        }
      }
    }

    this.saveItem = function(item) {
      ctrl.showSpinner = true;

      return item.save().then( function(res) {
        ctrl.hideSpinner();
        $state.go('mediaPlan.show', {planId: ctrl.plan.id});
        return res;
      }
      , function(err) {
        ctrl.hideSpinner();
        if (typeof console !== 'undefined' && console !== null) {
          console.log("Error saving: ", err);
        }
        return err;
      });
    };

    this.deleteItem = (item) => {
      ctrl.showDeleteSpinner = true;

      return item.delete().then( function(res) {
        ctrl.hideSpinner();
        $state.go('mediaPlan.show', {planId: ctrl.plan.id});
        return res;
      }
      , function(err) {
        ctrl.hideSpinner();
        if (typeof console !== 'undefined' && console !== null) {
          console.log("Error saving: ", err);
        }
        return err;
      });
    };

    this.refreshProducts = (search) => {
      return MediaProduct.query({filter_field: 'name', filter_value: search, page: 'all'}).then((res) => {
        return ctrl.products = res;
      });
    };

    this.markForDelete = function($event, obj, idx) {
      $event.preventDefault();
      $event.stopPropagation();
      if (obj.isNew()) {
        this.lineItem.mediaPlanLineItemBudgetSegments.splice(idx, 1);
      } else {
        obj.markForDelete();
      }
      return obj;
    };

    this.undoMarkForDelete = function($event, obj) {
      $event.preventDefault();
      $event.stopPropagation();
      obj.undoMarkForDelete();
      return obj;
    };

    // Datepicker stuff
    this.dpFields = {
      format: 'MM/dd/yyyy',
      start: false,
      end: false,
      minDate: EARLIEST_POSSIBLE_DATE
    };
    $scope.openDatePopup = function($event, param, obj) {
      $event.preventDefault();
      $event.stopPropagation();
      // Reset any opened popups
      ctrl.lineItem.mediaPlanLineItemBudgetSegments.forEach((seg) => {
        seg.dpFields.start = false;
        seg.dpFields.end = false;
      });
      //Set the triggered popup
      return obj.dpFields[param] = true;
    };

    this.setupDpFields = function(obj) {
      obj.dpFields = angular.copy(ctrl.dpFields);
    };

    ctrl.lineItem.mediaPlanLineItemBudgetSegments.forEach((seg) => {
      ctrl.setupDpFields(seg);
    });

    ctrl.versionGridDefs    = [];
    ctrl.versionGridApis    = [];
    ctrl.versionGridHeights = [];
    const skippedChangeKeys = ["id", "deletedAt", "updatedAt", "createdAt", "mediaPlanLineItemId", "estimatedImpressions"];
    const versionColDefs = [
      { name: 'FIELD',      field: 'key' },
      { name: 'ORIGINAL',   field: 'origVal' },
      { name: 'CHANGED',    field: 'newVal' },
    ];

    ctrl.versions.forEach((version, idx) => {
      // When an object is destroyed there's no objectChanges hash
      if (version.objectChanges) {
        // Convert the hash of object changes to a list of (field, origVal, newVal) tuples
        const tuples = Object.entries(version.objectChanges).map(([key, val]) => { return {key: key, origVal: val[0], newVal: val[1]}; }).filter((tuple) => !skippedChangeKeys.includes(tuple.key));
        const tbl = uigh.buildTable(ctrl.versionGridDefs, ctrl.versionGridApis, ctrl.versionGridHeights, tuples, versionColDefs, idx);
        tbl.enableSorting = false;
        tbl.enableColumnMenus = false;
      }
    });

    // We seem to get stuck at the bottom
    $window.scrollTo(0, 0);

    return this;
  }
]);
