// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('TraffickerLineItemsController', ['$scope', 'sheet', 'lineItems', 'DBM_SUBNAV', '$state', 'DBM_SECONDARYNAV', 'VALID_DOLLARS', 'VALID_INTEGER',
  function($scope, sheet, lineItems, DBM_SUBNAV, $state, DBM_SECONDARYNAV, VALID_DOLLARS, VALID_INTEGER) {
    $scope.title              = $state.current.data.title;
    $scope.sheet              = sheet;
    $scope.lineItems          = lineItems;
    $scope.dateFormat         = 'MM/dd/yyyy';
    $scope.searchString       = '';
    $scope.selectionSettings  = {pixels: [], creatives: []};
    $scope.allSelected        = false;
    $scope.onlyNotReady       = false;
    $scope.subnav             = DBM_SUBNAV;
    $scope.secondaryNav       = DBM_SECONDARYNAV;
    $scope.selectedCount      = 0;

    $scope.frequencyCapUnits  = ["Minutes", "Hours", "Days", "Weeks", "Months", "Lifetime"];
    $scope.budgetUnits        = [
      {key: "Impressions", pretty: "Impressions"},
      {key: "Amount", pretty: "USD"}
    ];
    $scope.validInteger       = VALID_INTEGER;
    $scope.validDollars       = VALID_DOLLARS;

    $scope.viewabilityAdPositions     = ["All", "ATF", "BTF", "Unknown"];
    $scope.viewabilityAdActivePercent = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9];

    // Set some basic defaults for LineItems
    const setDefaults = lineItems =>
      angular.forEach(lineItems, function(item) {
        if (!item.hasOwnProperty("visible")) {
          item.visible                = true;
        }
        if (!item.settings["creatives"]) { item.settings["creatives"] = []; }
        return item.settings["pixels"]     || (item.settings["pixels"] = []);
      })
    ;
    setDefaults($scope.lineItems);

    const recalcSelected = function() {
      let rv = 0;
      for (let item of Array.from($scope.filtered)) {
        if (item.selected) {
          rv++;
        }
      }
      $scope.selectedCount = rv;
      return rv;
    };

    $scope.filterCaseMatch = item => item.name.toLowerCase().indexOf($scope.searchString.toLowerCase()) > -1;

    $scope.toggleItemSelected = function(item) {
      if (item.selected) {
        return $scope.selectedCount++;
      } else {
        return $scope.selectedCount--;
      }
    };

    $scope.toggleAllSelected = function(filtered) {
      angular.forEach($scope.filtered, function(lineItem) {
        // Hidden items get de-selected, otherwise use the normal rules
        if (lineItem.visible) {
          return lineItem.selected = $scope.allSelected;
        } else {
          return lineItem.selected = false;
        }
      });
      return recalcSelected();
    };

    $scope.toggleOnlyNotReady = function(filtered) {
      angular.forEach($scope.filtered, function(lineItem) {
        if ($scope.onlyNotReady) {
          if (lineItem.sdfReady) {
            // Hide the valid ones
            lineItem.visible = false;
            return lineItem.selected = false;
          } else {
            return lineItem.visible = true;
          }
        } else {
          // Toggle was un-checked, so
          lineItem.visible    = true;    // Set everybody visible
          lineItem.selected   = false;   // De-select everybody
          return $scope.allSelected  = false;
        }   // De-select the allSelected model
      });
      return recalcSelected();
    };

    $scope.applySettingsToSelected = function() {
      $scope.showSpinner = true;
      angular.forEach($scope.filtered, function(lineItem) {
        if (lineItem.selected) {
          return angular.merge(lineItem.settings, $scope.selectionSettings);
        }
      });

      return $scope.sheet.save().then( function(res) {
        const origItems         = $scope.lineItems;
        $scope.sheet      = res;
        $scope.lineItems  = res.dbmLineItems;

        setDefaults(res.dbmLineItems);

        // Preserve the visible/selected attributes from the original set
        // of lineItems
        for (let idx = 0; idx < origItems.length; idx++) {
          const li = origItems[idx];
          if (li.visible != null) {
            $scope.lineItems[idx].visible = li.visible;
          }
          if (li.selected != null) {
            $scope.lineItems[idx].selected = li.selected;
          }
        }

        $scope.resetForm();
        $scope.showSpinner = false;
        return res;
      }
      , function(err) {
        $scope.showSpinner = false;
        if (typeof console !== 'undefined' && console !== null) {
          console.log("Error during save: ", err);
        }
        return err;
      });
    };


    $scope.resetForm = function() {
      // Omitting pixels/creatives here makes ui-select furious,
      // and we need special handling b/c of the watch in dbmResourceSelector
      $scope.$broadcast("dbmResourceSelector:reset");
      return angular.forEach(Object.keys($scope.selectionSettings), function(key) {
        if ((key !== "pixels") && (key !== "creatives")) {
          return delete $scope.selectionSettings[key];
        }
      });
    };

    $scope.clearSelected = function() {
      $scope.allSelected = false;
      $scope.toggleAllSelected($scope.filtered);
      return $scope.resetForm();
    };

    // Datepicker stuff
    $scope.dpFields = {
      format: 'MM/dd/yyyy',
      start: false,
      end: false
    };
    $scope.openDatePopup = function($event, param) {
      $event.preventDefault();
      $event.stopPropagation();
      // Reset the opened popups
      $scope.dpFields.start = false;
      $scope.dpFields.end = false;
      //Set the triggered popup
      return $scope.dpFields[param] = true;
    };

    $scope.validSettingsCount = function() {
      let rv = 0;
      angular.forEach($scope.filtered, function(item) {
        if (item.sdfReady) {
          return rv++;
        }
      });
      return rv;
    };

    $scope.copyToForm = function(lineItem) {
      angular.merge($scope.selectionSettings, lineItem.settings);
      return $scope.$broadcast("dbmResourceSelector:changed");
    };

    return true;
  }
]);
