// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Filter to convert lowercase and underscore|dash delimited text to human readable form in sentence case

'use strict';

angular.module('kazaam.filters').filter('humanize', ['$log', $log =>
  function(text) {
    try {
      text = text.charAt(0).toUpperCase() + text.slice(1);
      return text.replace(/_|-/g, ' ');
    } catch (err) {
      return $log.error('Cannot humanize a non-string input.');
    }
  }

]);
