// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
"use strict";

angular.module("kazaam.controllers").controller("UploaderBuilderController", [
  "$scope",
  "audience",
  "channels",
  "clients",
  "vendors",
  "queries",
  "selectedQry",
  "$state",
  "highChartsHelpers",
  function (
    $scope,
    audience,
    channels,
    clients,
    vendors,
    queries,
    selectedQry,
    $state,
    hch
  ) {
    $scope.title =
      $state.current.data != null ? $state.current.data.title : undefined;
    $scope.audience = audience;
    $scope.clients = clients;
    $scope.queries = queries;

    // The keys used here must match the 'origin' field returned by the backend serializer
    $scope.queryLoadOptions = [
      { id: "exavault", name: "FTP" },
      { id: "s3", name: "Vantage File Transfer" },
      { id: "aq", name: "Activator Query" }
    ];

    if (!audience.matchPlan) {
      audience.matchPlan = { strategy: "full" };
    }
    audience.hydrateChannels(channels);
    audience.hydrateVendors(vendors);
    audience.hydratePaths();
    audience.hydrateQueries();

    if (selectedQry && audience.isNew()) {
      audience.selectedQueries.push(selectedQry);
      audience.name = `${selectedQry.name} - based on query ${selectedQry.id}`;
      audience.clientId = selectedQry.clientId;
    }

    // Default to FTP, but if we have queries selected and no paths, use that
    if (audience.selectedPaths.exavault.length > 0) {
      $scope.queryLoadType = $scope.queryLoadOptions[0];
    } else if (audience.selectedPaths.s3.length > 0) {
      $scope.queryLoadType = $scope.queryLoadOptions[1];
    } else if (audience.selectedQueries.length > 0) {
      $scope.queryLoadType = $scope.queryLoadOptions[2];
    } else {
      $scope.queryLoadType = $scope.queryLoadOptions[0];
    }

    const vendorIdMap = {};
    let liveRamp = {};
    let rokuVendor = {};
    angular.forEach(vendors, function (v) {
      if (v.name === "LiveRamp") {
        liveRamp = v;
      } else if (v.name === "Roku") {
        rokuVendor = v;
      }
      vendorIdMap[v.id] = v;
    });

    // UI-sortable settings.  Per the docs, if we want to prevent a required partner moving
    // to the 'available' list, we have to call cancel in the 'selected' options
    $scope.selectedSortableOpts = {
      "ui-floating": false,
      connectWith: ".sortable-answers-1"
    };
    $scope.availableSortableOpts = angular.copy($scope.selectedSortableOpts);
    $scope.selectedSortableOpts["update"] = function (ev, ui) {
      const tgt_elm = angular.element(ui.item.sortable.droptarget[0]);
      if (
        ui.item.sortable.model.required &&
        tgt_elm.hasClass("deselected-partners")
      ) {
        return ui.item.sortable.cancel();
      }
    };

    $scope.saveAudience = (audience) => {
      audience.save().then((aud) => {
        $state.go("uploader.fields", { audienceId: aud.id });
      });
    };

    // Rebuild the set of match vendors and their ordering depending on the list of selected channels
    $scope.rebuildVendors = function (audience, initial) {
      let idx, v;
      if (initial == null) {
        initial = false;
      }

      // Check if Roku is selected
      const hasRokuChannel = audience.channels.some(
        (channel) => channel.name === "Roku"
      );

      if (hasRokuChannel) {
        // If Roku is selected, only allow Roku vendor
        audience.selectedVendors = [rokuVendor];
        audience.availableVendors = [];
        rokuVendor.required = true;
        return true;
      }

      if (audience.matchPlan.primaryKeyMatch) {
        return $scope.rebuildVendorsRestricted(audience, liveRamp);
      }

      $scope.channels = channels;
      let selected = [];
      const available = [];

      if (initial) {
        selected = angular.copy(audience.selectedVendors);
      }

      angular.forEach(audience.channels, (chan) =>
        angular.forEach(chan.channelMatchPartners, function (cmp) {
          const vendor = vendorIdMap[cmp.matchVendorId];
          if (cmp.required) {
            // Can only be in this list
            if (hch.indexOfByKey(selected, vendor, "id") === -1) {
              selected.push(vendor);
            }
            // Mark as required, since at least one channel treats this vendor as 'required'
            return (vendor.required = true);
          } else {
            // Can be in either
            if (
              hch.indexOfByKey(selected, vendor, "id") === -1 &&
              hch.indexOfByKey(available, vendor, "id") === -1
            ) {
              return available.push(vendor);
            }
          }
        })
      );

      // Add/Remove entries to selected, to preserve ordering
      const toDel = [];
      for (v of Array.from(audience.selectedVendors)) {
        if (hch.indexOfByKey(selected, v, "id") === -1) {
          toDel.push(v);
        }
      }

      for (v of Array.from(toDel)) {
        idx = hch.indexOfByKey(audience.selectedVendors, v, "id");
        audience.selectedVendors.splice(idx, 1);
      }
      for (v of Array.from(selected)) {
        if (hch.indexOfByKey(audience.selectedVendors, v, "id") === -1) {
          audience.selectedVendors.push(v);
        }
      }

      // Remove all 'selected' from 'available'
      for (v of Array.from(audience.selectedVendors)) {
        idx = hch.indexOfByKey(available, v, "id");
        if (idx !== -1) {
          available.splice(idx, 1);
        }
      }

      audience.availableVendors = available;
      return true;
    };

    $scope.rebuildVendorsRestricted = function (audience, onlyVendor) {
      // In this case, the vendor is known / locked
      audience.selectedVendors = [onlyVendor];
      audience.availableVendors = [];
      return (onlyVendor.required = true); // Lock to prevent dragging to available
    };

    $scope.togglePkMatch = function (audience, onlyVendor) {
      if (onlyVendor == null) {
        onlyVendor = liveRamp;
      }
      if (audience.matchPlan.primaryKeyMatch) {
        // Modify the set of available channels to only include those that reference the restricted vendor
        $scope.channels = [];
        angular.forEach(channels, (chan) =>
          angular.forEach(chan.channelMatchPartners, function (cmp) {
            if (cmp.matchVendorId === onlyVendor.id) {
              return $scope.channels.push(chan);
            }
          })
        );
        return $scope.rebuildVendorsRestricted(audience, onlyVendor);
      } else {
        // Reset the list back to the clean one
        $scope.channels = channels;
        return $scope.rebuildVendors(audience);
      }
    };

    $scope.headerState = function (path) {
      // Returns the text describing the state of headers for an uploaded file.
      const af = audience.audienceFileByPath(path);
      if (af && af.headersReady()) {
        return "Ready";
      } else {
        return "Not Ready";
      }
    };

    $scope.rebuildVendors(audience, true);

    $scope.customerFileSourceOptions = [
      { id: "USER_PROVIDED_ONLY", label: "From users directly" },
      { id: "PARTNER_PROVIDED_ONLY", label: "From partners" },
      { id: "BOTH_USER_AND_PARTNER_PROVIDED", label: "From users and partners" }
    ];

    if (!$scope.audience.matchPlan.customerFileSource) {
      $scope.audience.matchPlan.customerFileSource =
        "BOTH_USER_AND_PARTNER_PROVIDED";
    }

    return true;
  }
]);
