// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

const CSVFileReaderCtrl = ['$scope', 'Papa', '$q', function($scope, Papa, $q) {
  const ctrl = this;
  this.reset = function() {
    $scope.error              = false;
    $scope.errorMsg           = '';
    return $scope.fileContainer      = {files: []};
  };

  this.$onInit = function() {
    return this.reset();
  };

  this.$onChanges = function(changeObj) {};
    //console?.log("onChanges(): ", changeObj)

  this.flattenData = function(results) {
    const flattened = [];
    angular.forEach(results, result =>
      angular.forEach(result.data, row =>
        (() => {
          const result1 = [];
          for (let val of Array.from(row)) {
            let clean = val;
            if (ctrl.trim) {
              clean = clean.trim();
            }
            if (ctrl.skipBlankValues && (clean === '')) {
              continue;
            } else {
              result1.push(flattened.push(val));
            }
          }
          return result1;
        })()
      )
    );
    return {results, flat: flattened.sort()};
  };

  this.dedupeData = function(resultObj) {
    if (resultObj.flat) {
      resultObj.deduped = Array.from(new Set(resultObj.flat));
    }
    return resultObj;
  };

  this.readFiles = function(files) {
    files = Array.ensureArray(files);
    const promises = [];
    angular.forEach(files, function(file) {
      const p = Papa.parse(file, {skipEmptyLines: 'greedy'});
      return promises.push(p);
    });

    let allResults = $q.all(promises);

    if (this.flatten) {
      allResults = allResults.then(this.flattenData);
    }
    if (this.dedupe) {
      allResults = allResults.then(this.dedupeData);
    }

    return allResults.then(function(results) {
      if (ctrl.onParseFinish) {
        ctrl.onParseFinish({$event: {results}});
      }
      return results;
    });
  };

  return this;
}
];

angular.module('kazaam.components')
  .component("csvFileReader", {
    controller: CSVFileReaderCtrl,
    templateUrl: 'components/csv-file-reader.html',
    bindings: {
      multiple: '<',
      flatten: '<',
      dedupe: '<',
      trim: '<',
      skipBlankValues: '<',
      onParseFinish: '&',
      buttonLabel: '@'
    }
  });
