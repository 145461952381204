// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

const LoginCtrl = ['$scope', 'authService', '$http', '$timeout', 'currentSite', function($scope, authService, $http, $timeout, currentSite) {
  const ctrl = this;
  $scope.currentSite = currentSite;
  $scope.showLogin = false;
  this.user = {email: undefined, password: undefined};

  $scope.$on('event:auth-loginRequired', (event, args) => $scope.showLogin = true);

  $scope.login = function() {
    const req = {
      url:                "/user_sessions",
      method:             "POST",
      headers: {
        Accept:           "application/json"
      },
      data:               ctrl.user,
      ignoreAuthModule:   true
    };
    return $http(req).then( function(resp) {
      $scope.showLogin = false;
      return authService.loginConfirmed();
    }
    , function(resp) {
      if ((resp.status === 401) || (resp.status === 403)) {
        $(ctrl.elem).find('.loginizer').addClass('shakey');
        return $timeout( ()=> $(ctrl.elem).find('.loginizer').removeClass('shakey')
        , 1000);
      }
    });
  };
  return this;
}
];

angular.module('kazaam.directives')
  .directive('loginizer', [ function() {
    return {
      restrict: 'AE',
      scope: true,
      templateUrl: 'directives/loginizer.html',
      controller: LoginCtrl,
      link(scope, elem, attrs, ctrl) {
        scope.ctrl = ctrl;
        ctrl.elem = elem;
        return this;
      }
    };
  }
]);

