// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('ActivatorEtlShowCtrl',
  ['$scope', 'etlJob', '$state', 'SimplePoller', 'creator', '$transitions',
  function($scope, etlJob, $state, SimplePoller, creator, $transitions) {
    $scope.title    = $state.current.data != null ? $state.current.data.title : undefined;
    $scope.etlJob   = etlJob;
    $scope.creator  = creator;

    const stopPolling = function() {
      // If we get any sort of weirdness, then stop
      if (!$scope.etlJob || (typeof $scope.etlJob.isDone  !== 'function')) {
        return true;
      }

      // Keep polling (aka return false) if we're still potentially updating things
      return $scope.etlJob.isApprovable() || $scope.etlJob.isDone();
    };

    const refreshStatus = () =>
      $scope.etlJob.get().then( ej => $scope.etlJob = ej
      , function(err) {
        if (typeof console !== 'undefined' && console !== null) {
          console.log("refreshStatus failed: ", err);
        }
        return err;
      })
    ;

    const cutoff    = 7 * 24 * 60 * 60 * 1000; // 1 week
    const pollFreq  = 30 * 1000; // 30s
    const initDelay = 30 * 1000; // 30s

    $scope.poller = new SimplePoller(stopPolling, refreshStatus, cutoff, pollFreq, initDelay);
    $transitions.onExit({exiting: $state.current.name}, transition => $scope.poller.stop());
    if (!stopPolling()) {
      $scope.poller.start({logErrors: true});
    }

    $scope.approve = () =>
      $scope.etlJob.approve().then( ej => $state.go("activatorEtl.viewer", {jobId: ej.id}, {reload: true}))
    ;

    return true;
  }
]);
