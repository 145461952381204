// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.services').factory('friendlyStatus', function() {
    let status;
    return status = {
      'awaiting_approval': 'editing',
      'scheduled': 'pushing',
      'running': 'matching',
      'awaiting_matchback': 'awaiting_matchback',
      'processing_matches': 'processing_matches',
      'completed': 'completed',
      'failed': 'failed',
      'cancelled': 'cancelled'
    };
});

angular.module('kazaam.services').factory('friendlyEtlStatus', function() {
    let status;
    return status = {
      'awaiting_approval': 'editing',
      'scheduled': 'preparing',
      'running': 'loading',
      'completed': 'completed',
      'failed': 'failed',
      'cancelled': 'cancelled'
    };
});
