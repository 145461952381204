// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives').directive('placementGrouper', [ () =>
  ({
    restrict:     'AE',
    transclude:   true,
    scope: {
      placements:     '=ngModel',
      report:         '=',
      type:           '@',
      label:          '@'
    },
    templateUrl: 'analytics/placement-grouper.html',
    controller: 'placementGrouperController'
  })

]);
