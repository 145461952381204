// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers')
  .controller('NavigationCtrl', ['$scope', '$state', 'currentUser', 'currentSite', '$location', function($scope, $state, currentUser, currentSite, $location) {
    $scope.currentUser = currentUser;
    $scope.currentSite = currentSite;

    $scope.logoutHref = '/logout';
    if ($location.search().layout) {
      $scope.logoutHref += `?layout=${$location.search().layout}`;
    }

    $scope.currentState = $state;
    $scope.$watch('currentState.current.parent', function(newVal, oldVal) {
      if ((typeof newVal !== 'undefined') && newVal.length) {
        return angular.forEach($scope.navList, function(item){
          item.selected = false;
          if (newVal === item.parent) {
            return item.selected = 'selected';
          }
        });
      }
    });

    $scope.navList = [];

    if (currentUser.canUse("activator", currentSite)) {
      $scope.navList.push({
        parent:   'uploader',
        name:     'uploader.list',
        title:    'Audience Activator',
        icon:     'cloud-upload',
        selected: false
      });
    }

    if (currentUser.canUse("query-engine", currentSite)) {
      $scope.navList.push({
        parent:   'activatorQueries',
        name:     'activatorQueries.list',
        title:    'Activator Queries',
        icon:     'paper-plane',
        selected: false
      });
    }

    if (currentUser.canUse("trafficker", currentSite)) {
      $scope.navList.push({
        parent:   'trafficker',
        name:     'trafficker.list',
        title:    'DBM Trafficker',
        icon:     'book-open',
        selected: false
      });
    }

    if (currentUser.canUse("s3Uploader", currentSite)) {
      $scope.navList.push({
        parent:   's3Uploader',
        name:     's3Uploader.uploader',
        title:    'Secure Uploader',
        icon:     'shuffle',
        selected: false
      });
    }

    if (currentUser.canUse("mediaCampaigns", currentSite)) {
      return $scope.navList.push({
        parent:   'mediaCampaigns',
        name:     'mediaCampaigns.list',
        title:    'Media Plans',
        icon:     'screen-desktop',
        selected: false
      });
    }

    if (currentUser.canUse("mediaPlanner", currentSite)) {
      return $scope.navList.push({
        parent:   'mediaPlanner',
        name:     'mediaPlanner.list',
        title:    'Vector',
        icon:     'screen-desktop',
        selected: false
      });
    }
  }
  ]);
