// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('DbmTargetedAudience', ['RailsResource', 'railsSerializer', function(RailsResource, railsSerializer) {
  let DbmTargetedAudience;
  const ser = railsSerializer( function() {
    return this.preserve('dbmSettings');
  });

  return DbmTargetedAudience = (function() {
    DbmTargetedAudience = class DbmTargetedAudience extends RailsResource {
      static initClass() {
        this.configure({url: '/api/dbm_targeted_audiences', name: 'dbm_targeted_audience', serializer: ser});
      }

      static mappingSettings() { return this.dbmSettings; }
    };
    DbmTargetedAudience.initClass();
    return DbmTargetedAudience;
  })();
}
]);
