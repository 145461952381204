// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('MediaPlan', ['RailsResource', 'railsSerializer', 'DatepickerFriendlyDateInterceptor', function(RailsResource, railsSerializer, dpfdInterceptor) {
  let MediaPlan;
  const ser = railsSerializer( function() {
    this.resource('client', 'Client');
    this.resource('netsuiteJob', 'NetsuiteJob');
    this.resource('buyingNetsuiteJob', 'NetsuiteJob');
    this.resource('mediaPlanAudiences', 'MediaPlanAudience');
    this.nestedAttribute('mediaPlanAudiences');
    this.resource('mediaPlanBuckets', 'MediaPlanBucket');
    this.nestedAttribute('mediaPlanBuckets');
    this.resource('mediaPlanFees', 'MediaPlanFee');
    this.nestedAttribute('mediaPlanFees');
    this.resource('mediaPlanLineItems', 'MediaPlanLineItem');
    this.exclude('mediaPlanLineItems');
    this.exclude('client');
    this.exclude('creator');
    this.exclude('creatorId');
    this.exclude('createdAt');
    this.exclude('updatedAt');
    this.exclude('dpFields');
    this.exclude('remainingBudget');
    this.exclude('traffickingReady');
    this.exclude('favorite');
  });

  const _adServingFee = 'Ad Serving';
  const _dataFee = 'Data Licensing';

  return MediaPlan = (function() {
    MediaPlan = class MediaPlan extends RailsResource {
      static initClass() {
        this.configure({url: '/api/media_plans', name: 'media_plan', serializer: ser,
          interceptors: [{
            response: (result, ctor, context) => {
              const fields = ["startOn", "endOn"];
              return dpfdInterceptor(result, ctor, context, fields);
            }
          }]
        });
      }

      netsuiteJobAndId() {
        // Long name aside, we want to id of the thing in netsuite
        return `${this.netsuiteJobName} (${this.netsuiteJobNetsuiteId})`;
      }

      calculateTotalBudget() {
        let sum = this.bucketBudget() + this.feeBudget();
        return sum;
      }

      canCreateLineItems() {
        return this.mediaPlanAudiences.length > 0 && this.mediaPlanBuckets.length > 0;
      }

      bucketBudget() {
        let sum = 0;
        angular.forEach(this.mediaPlanBuckets, (bkt) => {
          if (!isNaN(bkt.budget) && !bkt.toBeDestroyed()) {
            sum += Number(bkt.budget);
          }
        });
        return sum;
      }

      feeBudget() {
        let sum = 0;
        angular.forEach(this.mediaPlanFees, (fee) => {
          if (!isNaN(fee.amount) && !fee.toBeDestroyed()) {
            sum += Number(fee.amount);
          }
        });
        return sum;
      }

      adServingFee() {
        return this.mediaPlanFees.find((fee) => { return fee.feeType === _adServingFee });
      }

      dataLicensingFee() {
        return this.mediaPlanFees.find((fee) => { return fee.feeType === _dataFee });
      }

      // These expect external hydration to supply `this.mediaPlanineItems`, e.g. by MediaPlanShowCtrl
      projectedAdServing() {
        if (this.mediaPlanLineItems) {
          const sum = this.mediaPlanLineItems.reduce( (total, item) => {
            return total += item.adServingCost;
          }, 0);
          return sum;
        } else {
          return undefined;
        }
      }
      adServingBuffer() {
        const fee = this.adServingFee();
        const projected = this.projectedAdServing();
        if (fee && projected) {
          return fee.amount - projected;
        } else {
          return undefined;
        }
      }

      projectedDataLicensing() {
        let sum = 0;
        angular.forEach(this.mediaPlanAudiences, (aud) => {
          angular.forEach(aud.mediaPlanAudienceFees, (fee) => {
            if (!isNaN(fee.projectedAmount) && !fee.toBeDestroyed()) {
              sum += Number(fee.projectedAmount);
            }
          });
        });
        return sum;
      }

      dataLicensingBuffer() {
        const fee = this.dataLicensingFee();
        const projected = this.projectedDataLicensing();
        if (fee && projected) {
          return fee.amount - projected;
        } else {
          return undefined;
        }
      }

      addFavorite() {
        const url = this.$url("/favorite");
        return MediaPlan.$post(url).then((resp) => {
          this.favorite = resp.favorite;
          return resp;
        });
      }
      delFavorite() {
        const url = this.$url("/favorite");
        return MediaPlan.$delete(url).then((resp) => {
          this.favorite = resp.favorite;
          return resp;
        });
      }

    };
    MediaPlan.initClass();
    return MediaPlan;
  })();
}
]);
