// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('effectSizeTableController',
  ['$scope', 'uiGridConstants', 'uiGridHelper',
  function($scope, uiGridConstants, uigh) {
    const pValCutoff      = 0.10;
    const positiveEffects = [];
    const negativeEffects = [];
    $scope.tables   = {};

    angular.forEach($scope.results, function(row) {
      if ((row.survey_question === $scope.question) && (row.p_value <= pValCutoff)) {
        if (row.effect_size > 0.0) {
          return positiveEffects.push(row);
        } else {
          return negativeEffects.push(row);
        }
      }
    });

    const tableFactory = function(tblData, label, sort_direction) {
      const curTbl                = { api: undefined };
      $scope.tables[label]  = curTbl;
      curTbl.opts = {
        data: tblData,
        enableFiltering: false,
        enableSorting: true,
        columnDefs: [
          { name: 'dimension',    enableColumnMenu: false, displayName: 'Dimension', width: "80%" },
          { name: 'effect_size',  enableColumnMenu: false, displayName: 'Effect Size', sort: { direction: sort_direction }, cellFilter: "roundPrecision:4" },
          { name: 'p_value',      enableColumnMenu: false, displayName: 'P Value', visible: false, cellFilter: "roundPrecision:4" }
        ],
        enableGridMenu:       true,
        exporterCsvFilename:  `${$scope.report.name}-effect-size-${$scope.question}-${label}.csv`,
        exporterMenuPdf:      false,
        enableHorizontalScrollbar:  uiGridConstants.scrollbars.NEVER,
        //enableVerticalScrollbar:    uiGridConstants.scrollbars.NEVER
        minRowsToShow: !(tblData.length > 10) ? tblData.length + 1 : undefined // Default here is 10
      };
      curTbl.opts.onRegisterApi = gridApi => curTbl.api = gridApi;
        //curTbl.height = uigh.setHeight(curTbl.opts, gridApi, 0, 200)
      return curTbl;
    };

    tableFactory(positiveEffects, 'positive', uiGridConstants.DESC);
    return tableFactory(negativeEffects, 'negative', uiGridConstants.ASC);
  }
  ]);
