// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

// Use .toPrecision to round numbers to a specified precision
angular.module('kazaam.filters').filter('roundPrecision', [ () =>
  function(num, precision) {
    if (isNaN(num) || isNaN(precision)) {
      return num;
    } else {
      return num.toPrecision(precision);
    }
  }

]);
