'use strict';

angular.module('kazaam.models').factory('DcmCampaign', ['RailsResource', 'railsSerializer', (RailsResource, railsSerializer) => {
  let DcmCampaign;

  return DcmCampaign = (() => {
    DcmCampaign = class DcmCampaign extends RailsResource {
      static initClass() {
        this.configure({url: '/api/dcm_campaigns', name: 'dcm_campaign'});
      }
    };
    DcmCampaign.initClass();
    return DcmCampaign;
  })();
}
]);
