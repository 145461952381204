// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('UploaderSegmentationCtrl',
  ['$scope', 'audience', 'vendors', '$state', function($scope, audience, vendors, $state) {
    if (!audience.matchPlan["segments"]) { audience.matchPlan["segments"] = []; }

    $scope.title        = $state.current.data != null ? $state.current.data.title : undefined;
    $scope.audience     = audience;
    $scope.segments     = audience.matchPlan.segments;
    $scope.newSegment   = {};
    $scope.totalPct     = 0;
    $scope.progressType = 'primary';
    $scope.segmentationStyles = [
      {label: "Individual",  value: "individual",  disabled: false},
      {label: "Household",   value: "household",   disabled: false}
    ];

    audience.hydrateVendors(vendors);
    angular.forEach(audience.selectedVendors, vend =>
      (() => {
        const result = [];
        for (let style of Array.from($scope.segmentationStyles)) {
          if ((style.disabled === false) && !vend.supportsSegmentationStyle(style.value)) {
            style.disabled = true;
            result.push(style.label = `${style.label} - Not supported by ${vend.name}`);
          } else {
            result.push(undefined);
          }
        }
        return result;
      })()
    );

    $scope.noSupportedSegmentationStyles = $scope.segmentationStyles.every(style => style.disabled);

    $scope.setTotalPct = function() {
      let type = '';
      const pct = $scope.segments.reduce( (acc, seg) => acc + parseInt(seg.pct)
      , 0);

      if (pct > 100) {
        type = 'danger';
      } else if (pct === 100) {
        type = 'success';
      } else {
        type = 'primary';
      }

      $scope.totalPct = pct;
      return $scope.progressType = type;
    };

    $scope.noop = () => undefined;

    $scope.validSegment = seg =>
      // Has name + pct + a unique name
      seg.hasOwnProperty("name") && (seg.name.length > 0) &&
        seg.hasOwnProperty("pct") && (seg.pct > 0) && (seg.pct <= 100)
    ;

    $scope.addSegment = function(seg) {
      if ($scope.validSegment(seg) && ($scope.segments.indexOfByKey("name", seg.name) === -1)) {
        $scope.segments.push(seg);
        $scope.newSegment = {};
        return $scope.setTotalPct();
      }
    };

    $scope.rmSegment = function(seg) {
      const idx = $scope.segments.indexOfByKey("name", seg.name);
      if (idx !== -1) {
        $scope.segments.splice(idx, 1);
        return $scope.setTotalPct();
      }
    };

    $scope.validSegments = () =>
      ($scope.segments.length === 0) || (
        $scope.segments.every($scope.validSegment) && ($scope.totalPct === 100)
      )
    ;

    $scope.save = () =>
      audience.save().then( aud => $state.go("uploader.viewer", {audienceId: aud.id}))
    ;

    $scope.setTotalPct();
    return true;
  }
]);
