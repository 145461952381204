'use strict';

angular.module('kazaam.models').factory('MediaPlanLineItemVersion', ['RailsResource', (RailsResource) => {
  let MediaPlanLineItemVersion;

  return MediaPlanLineItemVersion = (() => {
    MediaPlanLineItemVersion = class MediaPlanLineItemVersion extends RailsResource {
      static initClass() {
        this.configure({url: '/api/media_plan_line_items/{{lineItemId}}/history', name: 'version'});
      }
    };
    MediaPlanLineItemVersion.initClass();
    return MediaPlanLineItemVersion;
  })();
}
]);
