'use strict';

angular.module('kazaam.directives').directive('validateUniqueness', [ () =>
({
  restrict: 'AE',
  require: 'ngModel',
  scope: {
    collection: '<',
    collectionKey: '<',
    currentIndex: '<'
  },
   link(scope, element, attrs, ctrl) {
    return ctrl.$validators.uniqueness = (modelValue, viewValue) => {
      if (ctrl.$isEmpty(modelValue)) {
        return true;
      }

      const firstIdx = scope.collection.indexOfByKey(scope.collectionKey, modelValue);
      if (firstIdx === -1 || firstIdx === scope.currentIndex) {
        return true;
      }

      return false;
    };
  }
})]);
