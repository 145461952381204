// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('SnowflakeTableLister', ['$http', function($http) {
  let SnowflakeTableLister;
  return SnowflakeTableLister = (function() {
    SnowflakeTableLister = class SnowflakeTableLister {
      static initClass() {
        this.url = '/api/activator_etl_jobs/tables';
      }

      static search(term) {
        if (term == null) { term = undefined; }
        return $http.get(this.url, {
          params: {
            search: term
          },
          headers: {
            Accept: 'application/json'
          }
        }).then(res => res.data);
      }
    };
    SnowflakeTableLister.initClass();
    return SnowflakeTableLister;
  })();
}
]);
