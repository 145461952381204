/*
 * decaffeinate suggestions:
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('MediaPlanLineItem', ['RailsResource', 'railsSerializer', '$filter', 'MediaPlanLineItemBudgetSegment', (RailsResource, railsSerializer, $filter, MediaPlanLineItemBudgetSegment) => {
  let MediaPlanLineItem;
  const ser = railsSerializer( function() {
    this.nestedAttribute('mediaPlanLineItemBudgetSegments');
    this.resource('mediaPlanLineItemBudgetSegments', 'MediaPlanLineItemBudgetSegment');
    this.exclude('budget');
    this.exclude('startOn');
    this.exclude('endOn');
    this.exclude('mediaProduct');
    this.exclude('mediaPlan');
    this.exclude('mediaPlanId');
    this.exclude('mediaPlanAudience');
    this.exclude('dpFields');
    this.exclude('adServingCost');
    this.exclude('adServingCostBreakdown');
    this.exclude('createdAt');
    this.exclude('updatedAt');
  });

  // XXX: These must match rails
  const _deviceTypes = ["ConnectedHome", "Desktop", "Mobile", "OTT", "Print", "All"];
  const _rateTypes = ["CPM", "CPC", "CPA", "Fixed", "dCPM", "dCPC", "vCPM", "CPV", "Flat"];
  const _standardComm = "Standard Commission";

  return MediaPlanLineItem = (() => {
    MediaPlanLineItem = class MediaPlanLineItem extends RailsResource {
      static initClass() {
        this.configure({url: '/api/media_plan_line_items', name: 'media_plan_line_item', serializer: ser});
      }

      hydrate(mediaPlan) {
        const item = this;
        this.mediaPlan = mediaPlan;
        this.mediaPlanId = mediaPlan.id;
        this.mediaPlanLineItemBudgetSegments.forEach((seg) => {
          seg.hydrate(item);
        });
        this.calcGrossBudget();
        this.calcDateEndpoints();
      }

      addBudgetSegment() {
        const seg = new MediaPlanLineItemBudgetSegment();
        seg.hydrate(this);
        this.mediaPlanLineItemBudgetSegments.push(seg);
        return seg;
      }

      static deviceTypes() { return _deviceTypes; }
      static rateTypes() { return _rateTypes; }

      flatRate() { return this.rateType === 'Flat' }

      markForDelete() { return this._destroy = 1; }
      undoMarkForDelete() { return delete this._destroy; }

      // Use the 'Original' fields to avoid the datepicker-friendly transformation.
      // See media_plan_line_item_budget_segment.js
      calcDateEndpoints() {
        let start, end;
        this.mediaPlanLineItemBudgetSegments.forEach((seg, idx) => {
          if (idx == 0) {
            start = seg.startOnOriginal;
            end = seg.endOnOriginal;
          } else {
            if (seg.startOnOriginal < start) {
              start = seg.startOnOriginal;
            }
            if (seg.endOnOriginal > end) {
              end = seg.endOnOriginal;
            }
          }
        });
        this.startOn = Date.convertDateToUTC(new Date(start));
        this.endOn = Date.convertDateToUTC(new Date(end));
      }

      // XXX: This is duplicated from the ROR method on MediaPlanLineItem, changes must match what's there.
      calcNetBudget() {
        if (!this.mediaPlan) {
          return undefined;
        }

        let netBudget = 0;
        this.mediaPlanLineItemBudgetSegments.forEach((seg) => {
          netBudget += seg.calcNetBudget();
        });
        return netBudget;
      }

      calcGrossBudget() {
        let budget = 0;
        this.mediaPlanLineItemBudgetSegments.forEach((seg) => {
          budget += Number(seg.budget);
        });
        this.budget = budget;
        return budget;
      }

      calcGrossRate() {
        if (!this.mediaPlan) {
          return undefined;
        }

        if (this.mediaPlan.markupType === _standardComm) {
          return (this.netRate / (1 - this.effectiveCommission() / 100.0));
        } else {
          return this.netRate;
        }
      }

      effectiveCommission() {
        if (!this.mediaPlan) {
          return undefined;
        }

        if (this.commission) {
          return this.commission;
        }

        return this.mediaPlan.commission;
      }

      activeBudgetSegmentCount() {
        let cnt = 0;
        this.mediaPlanLineItemBudgetSegments.forEach((seg) => {
          if (!seg._destroy) {
            cnt += 1;
          }
        });
        return cnt;
      }

      uiGridEditable() {
        // We want to allow editing of the start/end/budget fields, but those are derived
        // from our budget segments.  Restrict edits to line items with just one, so that
        // edits are 1:1 with the segment.
        return this.mediaPlanLineItemBudgetSegments.length == 1;
      }

      prepareInlineEdit(field) {
        // Save this so we can revert and detect pending changes
        this.inlineEditOriginal ||= {};
        this.inlineEditOriginal[field] ||= this[field];
      }

      processInlineEdit(field, newVal, oldVal) {
        this.inlineEditOriginal ||= {};

        // Propagate the editable fields back to the budget segment
        const seg = this.mediaPlanLineItemBudgetSegments[0];
        seg[field] = newVal;
        this[field] = newVal;

        // Recalc our pretty dates
        //this.calcDateEndpoints();
        return;
      }

      revertInlineEdit() {
        if (!this.inlineEditOriginal) {
          return;
        }

        angular.forEach(Object.keys(this.inlineEditOriginal), (key) => {
          this[key] = this.inlineEditOriginal[key];
        });
        this.inlineEditOriginal = {};
        return this;
      }

      clearInlineEdit() {
        this.inlineEditOriginal = {};

        return this;
      }

      hasPendingEdits() {
        if (!this.inlineEditOriginal) {
          // This gets invoked early, before any edits have happened
          return false;
        }

        return Object.keys(this.inlineEditOriginal).some((field) => this.fieldEdited(field, this[field]));
      }
      fieldEdited(field, val) {
        if (!this.inlineEditOriginal || this.inlineEditOriginal[field] === undefined) {
          // This gets invoked early, before any edits have happened
          return false;
        }

        return this.inlineEditOriginal[field] !== val;
      }
    };
    MediaPlanLineItem.initClass();
    return MediaPlanLineItem;
  })();
}
]);
