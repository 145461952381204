// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives')
  .directive('tagger', () =>
    ({
      restrict: 'AE',
      template: '<input type="text" placeholder="Add tags for {{tagForLabel}}" ng-model="newTag" />',
      scope: {
        tags: '=',
        tagForLabel: '='
      },
      link(scope, elem, attrs) {
        if ((typeof scope.tags === 'undefined') || (scope.tags === null)) {
          scope.tags = [];
        }

        return $(elem).on('keydown', function(e) {
          if (((e.which === 9) || (e.which === 13)) && (scope.tags.indexOf(scope.newTag) === -1)) {
            scope.tags.push(scope.newTag);
            scope.newTag = '';
            scope.$apply();
            return e.preventDefault();
          }
        });
      }
    })
  );
