// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('AudienceQueryResult', ['RailsResource', 'railsSerializer', function(RailsResource, railsSerializer) {
  let AudienceQueryResult;
  const ser = railsSerializer( function() {
    this.exclude('createdAt');
    return this.exclude('updatedAt');
  });

  return AudienceQueryResult = (function() {
    AudienceQueryResult = class AudienceQueryResult extends RailsResource {
      static initClass() {
        this.configure({url: '/api/audience_query_results', name: 'audience_query_result', serializer: ser});
      }
    };
    AudienceQueryResult.initClass();
    return AudienceQueryResult;
  })();
}

]);
