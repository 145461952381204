// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('AdBuyChannel', ['RailsResource', 'railsSerializer', function(RailsResource, railsSerializer) {
  let AdBuyChannel;
  const ser = railsSerializer( function() {
    return this.resource('channelMatchPartners', 'ChannelMatchPartner');
  });
  return AdBuyChannel = (function() {
    AdBuyChannel = class AdBuyChannel extends RailsResource {
      static initClass() {
        this.configure({url: '/api/ad_buy_channels', name: 'ad_buy_channel', serializer: ser});
      }
    };
    AdBuyChannel.initClass();
    return AdBuyChannel;
  })();
}
]);
