// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('DbmResource', ['$http', '$q', 'MIN_SELECT_REFRESH', function($http, $q, MIN_SELECT_REFRESH) {
  let DbmResource;
  return DbmResource = (function() {
    DbmResource = class DbmResource {
      static initClass() {
        this.url = '/api/dbm_resources';
      }

      static search(term, resourceType, mappingType, page, extra_opts) {
        if (mappingType == null) { mappingType = undefined; }
        if (page == null) { page = undefined; }
        if (extra_opts == null) { extra_opts = {}; }
        if (!term || (term.length < MIN_SELECT_REFRESH.length)) {
          const deferred = $q.defer();
          deferred.resolve({data: []});
          return deferred.promise;
        }

        const params = angular.merge({
          search:         term,
          resource_type:  resourceType,
          mapping_type:   mappingType,
          page
        }, extra_opts);

        const headers = {
          Accept: 'application/json'
        };

        return $http.get(this.url, {params: params, headers: headers});
      }
    };
    DbmResource.initClass();
    return DbmResource;
  })();
}
]);
