// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('MediaPlanShowCtrl',
  ['$scope', 'plan', 'lineItems', 'products', '$state', '$timeout', 'MEDIA_PLANNER_SECONDARYNAV', 'currentUser', 'uiGridHelper', 'uiGridConstants', '$q',
  ($scope, plan, lineItems, products, $state, $timeout, MEDIA_PLANNER_SECONDARYNAV, currentUser, uigh, uigc, $q) => {
    this.title        = `${plan.client.name} - ${plan.name}`;
    this.plan         = plan;
    this.lineItems    = lineItems;
    this.client       = plan.client;
    this.products     = products;
    this.currentUser  = currentUser;
    this.showSpinner  = false;

    this.lineItemsPendingSave = {};

    const ctrl        = this;

    $scope.secondaryNav = MEDIA_PLANNER_SECONDARYNAV;

    this.destroyPlan = function(planId) {
      if (this.plan.id === planId) {
        return this.plan.delete().then( () => $state.go('mediaPlanner.list'));
      }
    };

    this.hideSpinner = () => { $timeout(() => { ctrl.showSpinner = false }, 250); };

    // Hydrate the line items with their products & the plan id
    const productsById = {};
    angular.forEach(products, (product) => {
      productsById[product.id] = product;
    });
    const audiencesById = {};
    angular.forEach(plan.mediaPlanAudiences, (audience) => {
      audiencesById[audience.id] = audience;
      audience.budget = 0;
      audience.mediaPlanId = plan.id;
    });
    const bucketsById = {};
    angular.forEach(plan.mediaPlanBuckets, (bkt) => {
      bucketsById[bkt.id] = bkt;
      bkt.allocated = 0;
      bkt.mediaPlanId = plan.id;
    });

    angular.forEach(this.lineItems, (item) => {
      item.hydrate(plan);
      item['mediaProduct'] = productsById[item.mediaProductId];
      item['mediaPlanAudience'] = audiencesById[item.mediaPlanAudienceId];
      item['mediaPlanBucket'] = bucketsById[item.mediaPlanBucketId];
      audiencesById[item.mediaPlanAudienceId].budget += Number(item.budget);
      bucketsById[item.mediaPlanBucketId].allocated += Number(item.budget);
    });
    this.plan.mediaPlanLineItems = this.lineItems;

    this.traffickerStatusText = {
      clientAdvertiser:       "Client must have the dtv2_advertiser (aka DCM advertiser) field set.",
      dcmCampaign:            "This media plan needs a DCM Campaign assigned.",
      lineItems:              "At least 1 line item is required for trafficking.",
      lineItemBudgets:        "All line items must have a budget segment.",
      dcmProductCount:        "Line items must be assigned to at least 1 Media Product on the DCM platform.",
      productSiteGoogleId:    "All assigned Media Products must have DCM Sites assigned with valid Google DCM ids.",
    };

    // Ui-grid stuff
    $scope.tables       = [];
    $scope.tblApis      = [];
    $scope.tblHeights   = [];

    this.lineItemEditable = ($scope) => {
      return $scope.row.entity.uiGridEditable();
    };

    this.beginCellEdit = (rowEntity, colDef) => {
      rowEntity.prepareInlineEdit(colDef.field);
    }

    this.afterCellEdit = (rowEntity, colDef, newVal, oldVal) => {
      rowEntity.processInlineEdit(colDef.field, newVal, oldVal);

      // Check if changes are still pending or not
      if (rowEntity.hasPendingEdits()) {
        ctrl.lineItemsPendingSave[rowEntity.id] = rowEntity;
      } else {
        delete ctrl.lineItemsPendingSave[rowEntity.id];
      }

      $scope.$apply();
    };

    this.savePendingLineItems = () => {
      const promises = [];
      ctrl.showSpinner = true;
      angular.forEach(ctrl.lineItemsPendingSave, (li) => {
        // Call save() + setup the after-save handling
        const p = li.save();
        p.then((item) => {
          li.clearInlineEdit();
          return item;
        });
        promises.push(p);
      });

      return $q.all(promises).then((items) => {
        ctrl.lineItemsPendingSave = {};
        $state.go('.', {}, {reload: true});
      }).catch((err) => {
        console.log("Errors while saving:", err);
      }).finally(() => {
        ctrl.showSpinner = false;
      });
    };

    this.resetPendingLineItems = () => {
      angular.forEach(ctrl.lineItemsPendingSave, (li) => { li.revertInlineEdit(); });
      ctrl.lineItemsPendingSave = {};
      $scope.tblApis[2].core.notifyDataChange( uigc.dataChange.COLUMN );
    };

    this.cellClassSelector = (grid, row, col, rowRenderIndex, colRenderIndex) => {
      const cellVal = grid.getCellValue(row, col);
      if (row.entity.fieldEdited(col.field, cellVal)) {
        return 'line-item-cell-changed';
      }
    };

    const audienceGridColumnDefs = [
      {displayName: 'Name',         field: 'name', sort: { direction: uigc.ASC, priority: 0}, cellTemplate: 'media-planner/bucket-audience-grid-cell.html'},
      {displayName: 'Type',         field: 'audienceType'},
      {displayName: 'Min Age',      field: 'minAge'},
      {displayName: 'Max Age',      field: 'maxAge'},
      {displayName: 'Gender',       field: 'genderText()'},
      {displayName: 'Budget',       field: 'budget', cellFilter: 'dollars'},
      {displayName: 'Est. Size',    field: 'estSize', cellFilter: 'number:0'},
    ];
    $scope.tables[0] = uigh.buildTable($scope.tables, $scope.tblApis, $scope.tblHeights, ctrl.plan.mediaPlanAudiences, audienceGridColumnDefs, 0, {padding: 10, maxHeight: 300});
    $scope.tables[0].enableFiltering = false;
    $scope.tables[0].enableGridMenu = false;
    $scope.tables[0].enableColumnMenus = false;
    $scope.tables[0].enableColumnResizing = true;
    $scope.tables[0].exporterMenuCsv = false;

    const bucketGridColumnDefs = [
      {displayName: 'Name',         field: 'name', sort: { direction: uigc.ASC, priority: 0}, cellTemplate: 'media-planner/bucket-audience-grid-cell.html'},
      {displayName: 'Gross',        field: 'budget', cellFilter: 'dollars'},
      {displayName: 'Allocated',    field: 'allocated', cellFilter: 'dollars'},
      {displayName: 'Buffer',       field: 'buffer()', cellFilter: 'dollars'},
    ];
    $scope.tables[1] = uigh.buildTable($scope.tables, $scope.tblApis, $scope.tblHeights, ctrl.plan.mediaPlanBuckets, bucketGridColumnDefs, 1, {padding: 10, maxHeight: 300});
    $scope.tables[1].enableFiltering = false;
    $scope.tables[1].enableGridMenu = false;
    $scope.tables[1].enableColumnMenus = false;
    $scope.tables[1].enableColumnResizing = true;
    $scope.tables[1].exporterMenuCsv = false;

    const lineItemGridColumnDefs = [
      {displayName: 'ID',           field: 'id', cellTemplate: 'media-planner/line-item-grid-cell.html', enableCellEdit: false},
      {displayName: 'Audience',     field: 'mediaPlanAudience.name', sort: { direction: uigc.ASC, priority: 0 }, enableCellEdit: false},
      {displayName: 'Bucket',       field: 'mediaPlanBucket.name', sort: { direction: uigc.ASC, priority: 1 }, enableCellEdit: false},
      {displayName: 'Geo',          field: 'geography', enableCellEdit: false},
      {displayName: 'Site',         field: 'mediaProduct.dcmSite.name', enableCellEdit: false},
      {displayName: 'Product',      field: 'mediaProduct.name', enableCellEdit: false},
      {displayName: 'Channel',      field: 'mediaProduct.channel', enableCellEdit: false},
      {displayName: 'Device Type',  field: 'deviceType', enableCellEdit: false},
      {displayName: 'Flight Start', field: 'startOn', enableCellEdit: true, cellEditableCondition: this.lineItemEditable, cellFilter: 'date:\'MM/dd/yyyy\'', cellClass: this.cellClassSelector },
      {displayName: 'Flight End',   field: 'endOn', enableCellEdit: true, cellEditableCondition: this.lineItemEditable, cellFilter: 'date:\'MM/dd/yyyy\'', cellClass: this.cellClassSelector},
      {displayName: 'Rate Type',    field: 'rateType', enableCellEdit: false},
      {displayName: 'Net Rate',     field: 'netRate', cellFilter: 'dollars:2', enableCellEdit: false},
      {displayName: 'Budget',       field: 'budget', cellFilter: 'dollars', enableCellEdit: true, cellEditableCondition: this.lineItemEditable, cellClass: this.cellClassSelector},
      {displayName: 'Serving Cost', field: 'adServingCost', cellFilter: 'dollars', enableCellEdit: false},
      {displayName: 'Commission',   field: 'commission', cellFilter: 'dollars', visible: false, enableCellEdit: false}
    ];

    $scope.tables[2] = uigh.buildTable($scope.tables, $scope.tblApis, $scope.tblHeights, ctrl.lineItems, lineItemGridColumnDefs, 2, {padding: 50, maxHeight: 800, beginCellEdit: this.beginCellEdit, afterCellEdit: this.afterCellEdit, scope: $scope});
    $scope.tables[2].enableFiltering = true;
    $scope.tables[2].enableGridMenu = true;
    $scope.tables[2].enableColumnMenus = false;
    $scope.tables[2].enableColumnResizing = true;
    $scope.tables[2].exporterMenuCsv = false;
    $scope.tables[2].rowTemplate = 'media-planner/line-item-grid-row.html';

    return this;
  }
]);
