// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('DcmPlacementParam', ['RailsResource', 'railsSerializer', 'DbmResource',
  function(RailsResource, railsSerializer, DbmResource) {
    let DcmPlacementParam;
    const ser = railsSerializer( function() {
      this.preserve('dbmSettings');
      this.exclude('dcmSheetId');
      this.exclude('mappingTypes');
      this.exclude('label');
      this.exclude('paramType');
      this.exclude('mappable');
      return this.exclude('sdfReady');
    });

    return DcmPlacementParam = (function() {
      DcmPlacementParam = class DcmPlacementParam extends RailsResource {
        static initClass() {
          this.configure({url: '/api/dcm_placement_params', name: 'dcm_placement_param', serializer: ser});
        }

        settingsKeys(mappingType) {
          if (["demo_gender", "demo_age"].indexOf(mappingType) > -1) {
            return ["includes"];
          } else {
            return ["includes", "excludes"];
          }
        }

        setDefaults() {
          for (let mt of Array.from(this.mappingTypes)) {
            if (!this.dbmSettings[mt]) { this.dbmSettings[mt] = {}; }
            for (let sk of Array.from(this.settingsKeys(mt))) {
              if (!this.dbmSettings[mt][sk]) { this.dbmSettings[mt][sk] = []; }

              // Fix any potentially messed up data, forcibly
              if (!Array.isArray(this.dbmSettings[mt][sk])) {
                this.dbmSettings[mt][sk] = [];
              }

              if (this.supportsLogicTree(mt, sk)) {
                // Logic tree uses an array of arrays.  Ensure
                // it has at least 1 element and that all objects are arrays
                if (this.dbmSettings[mt][sk].length === 0) {
                  this.dbmSettings[mt][sk] = [[]];
                } else {
                  for (let idx = 0; idx < this.dbmSettings[mt][sk].length; idx++) {
                    const obj = this.dbmSettings[mt][sk][idx];
                    if (!Array.isArray(obj)) {
                      this.dbmSettings[mt][sk][idx] = [];
                    }
                  }
                }
              }
            }
          }
          return this;
        }

        supportsLogicTree(mappingType, settingsKey) { return this.logicTree && this.logicTree[mappingType] && this.logicTree[mappingType][settingsKey]; }

        currentSettings(mappingType, settingsKey) { return this.dbmSettings[mappingType][settingsKey]; }
        mappingSettings(mappingType) { return this.dbmSettings[mappingType]; }

        sortKey() {
          if (this.mappable) {
            return `0${this.paramType}${this.label}`;
          } else {
            return `1${this.paramType}${this.label}`;
          }
        }
      };
      DcmPlacementParam.initClass();
      return DcmPlacementParam;
    })();
  }
]);
