// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
Array.prototype.indexOfByKey = function(keyName, val) {
  let offset = -1;
  this.some(function(el, idx, array) {
    if (el[keyName] === val) {
      offset = idx;
      return true;
    }
    return false;
  });
  return offset;
};

Array.prototype.findByKey = function(keyName, val) {
  const idx = this.indexOfByKey(keyName, val);
  if (idx !== -1) {
    return this[idx];
  } else {
    return undefined;
  }
};

// http://stackoverflow.com/a/1232046/845546
Array.prototype.clear = function() { return this.length = 0; };

Array.ensureArray = function(maybeAry) {
  if (Array.isArray(maybeAry)) {
    return maybeAry;
  } else {
    return [maybeAry];
  }
};

// This is slow, but I like the style: http://stackoverflow.com/a/33704783/845546
String.prototype.capitalize = function() { return this.charAt(0).toUpperCase() + this.slice(1); };

// https://stackoverflow.com/a/6777470/845546
Date.convertDateToUTC = function(date) {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}
