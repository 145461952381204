// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('SimplePoller', ['$q', '$timeout', function($q, $timeout) {
  // A coffeescript rendering of http://davidwalsh.name/javascript-polling,
  // with some additional deferred assumptions
  let SimplePoller;
  return (SimplePoller = class SimplePoller {
    constructor(checkFn, callback, timeoutFromNow, interval, initDelay) {
      if (initDelay == null) { initDelay = 0; }
      this.dfd        = $q.defer();
      this.initDelay  = initDelay;
      const endTime     = Number(new Date()) + (timeoutFromNow || 5000);
      interval    = interval || 1000;

      const obj = this;
      this.poller = () =>
        callback().then( function(val) {
          if (obj.stopped) {
            return obj.dfd.reject(new Error('Stopped'));
          } else if (checkFn()) {
            // If the condition is met, we're done! 
            //console?.log("checkFn() returned true")
            return obj.dfd.resolve(val);
          } else if (Number(new Date()) < endTime) {
            // If the condition isn't met but the timeout hasn't elapsed, go again
            obj.curTimeout = $timeout(obj.poller, interval);
            return obj.curTimeout.catch(err =>
              //console?.log("Ignoring timeout error")
              true
            );
          } else {
            // Didn't match and too much time, reject!
            //console?.log("SimplePoller timed out")
            return obj.dfd.reject(new Error(`Timed out for ${checkFn}: ${arguments}`));
          }
        })
      ;
      obj;
    }

    start(opts) {
      if (opts == null) { opts = {}; }
      this.stopped = false;
      if (this.initDelay > 0) {
        $timeout(this.poller, this.initDelay);
      } else {
        this.poller();
      }

      if (opts.logErrors) {
        this.logErrors();
      }

      return this.dfd.promise;
    }

    stop() {
      this.stopped = true;
      if (this.curTimeout) {
        $timeout.cancel(this.curTimeout);
        this.curTimeout = undefined;
      }
      return true;
    }

    logErrors() {
      if (this.dfd) {
        return this.dfd.promise.catch(function(err) {
          if (err.message !== 'Stopped') {
            return (typeof console !== 'undefined' && console !== null ? console.log(`Poller error: ${err.message}`) : undefined);
          }
        });
      }
    }
  });
}
]);
