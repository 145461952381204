'use strict';

/*
 * This is derived from the more complicated directive at directives/trafficker/dbm-resource-select.js, but
 * intended for a simpler use case.
 */
const SimpleDbmResourceSelectCtrl = ['$scope', 'DbmResource', '$q', 'MIN_SELECT_REFRESH', function($scope, DbmResource, $q, MIN_SELECT_REFRESH) {
  const ctrl = this;
  this.mappingType      = 'default';
  this.searchResults    = [];

  this.applyChanges = ($item) => {
    if (ctrl.onSelect) {
      ctrl.onSelect({$event: {canonicalName: ctrl.canonicalName, id: $item.id}});
    }
  };

  // Pagination stuff
  const loadingItem   = {id: "loading", name: 'Loading...', isLoadingItem: true};
  const hasNextChunk  = true;
  let searchTerm    = '';
  let nextPage      = 2;

  this.addLoadingItem    = () => ctrl.searchResults.push(loadingItem);
  this.removeLoadingItem = function() {
    const idx = ctrl.searchResults.indexOf(loadingItem);
    if (idx >= 0) {
      ctrl.searchResults.splice(idx, 1);
    }
    return true;
  };

  this.getInfiniteScrollChunk = function(pageNum) {
    if (pageNum == null) { pageNum = 1; }
    const queryParams = {};
    return DbmResource.search(searchTerm, ctrl.resourceType, ctrl.mappingType, pageNum, queryParams).then( response => response.data.dbm_mappings);
  };

  this.refreshList = function(search) {
    searchTerm  = search.length ? search : '';
    nextPage    = 2;
    if (searchTerm.length >= MIN_SELECT_REFRESH.length) {
      return ctrl.getInfiniteScrollChunk().then( (results) => { return ctrl.searchResults = results || []; }
      , err => $q.reject(err));
    }
  };
  // End pagination related

  this.isSelectOpen = function(isOpen, $select) {
    if (!isOpen) {
      return $select.search = '';
    }
  };

  ctrl.searchResultLabel = function(item) {
    if (!item) {
      // XXX: I was able to trigger this manually once, but then not again... :(
      if (typeof console !== 'undefined' && console !== null) {
        // console.log("Unexpected undefined item in search results, using undefined label");
      }
      return undefined;
    } else if (item.hasOwnProperty("country_code")) {
      return `${item.canonical_name}, Type: ${item.target_type} (${item.id})`;
    } else {
      return `${item.canonical_name} (${item.id})`;
    }
  };

  ctrl.formatSelected = (item) => {
    if (typeof item === 'string') {
      return item;
    } else {
      return ctrl.searchResultLabel(item);
    }
  };

  return this;
}];

angular.module('kazaam.components')
  .component('simpleDbmResourceSelect', {
    controller: SimpleDbmResourceSelectCtrl,
    templateUrl: 'components/simple-dbm-resource-select.html',
    bindings: {
      canonicalName:        '<',
      placeholder:          '@',
      resourceType:         '@',
      onSelect:             '&'
    }
  });
