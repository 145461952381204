// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives')
  .directive('errorHandler', ['XmlToJson', xmlToJson =>
    ({
      restrict: 'AE',
      scope: {
        appError: '<'
      },
      templateUrl: 'error-handler.html',
      link(scope, elem, attrs) {
        // We want to support HTTP errors and app-level errors here. Guidelines:
        // * If there's an app error, display it.
        // * Try to display the most recent error (likely HTTP errors)
        // * If a HTTP error clears and there's still an app error, render the app error
        let getErrorDetails;
        const $elem = $(elem);
        scope.errorMessage = scope.appError || '';

        scope.$on('response:responseError', function(event, errorData) {
          const parts = getErrorDetails(errorData);
          scope.errorMessage = parts.msg;
          scope.errorDetails = parts.details;
          return $elem.addClass('show');
        });

        scope.$on('response:noError', () => scope.errorMessage = scope.appError || '');

        return getErrorDetails = function(errorData) {
          const rv = {msg: '', details: []};
          if (angular.isString(errorData)) {
            if (errorData.indexOf('xml') === 2) {
              const json = xmlToJson.parseXml(errorData);
              rv.msg = json.error.message;
            } else {
              rv.msg = errorData;
            }
          } else if (angular.isObject(errorData)) {
            // Handle 'full' errors as produced by rails
            if (Array.isArray(errorData.full) && errorData.full.length === 1) {
              rv.msg += ` ${errorData.full[0]}.`;
            } else {
              // Handle the original style of {field => [errors]}
              for (var key in errorData) {
                rv.msg += key;
                if (angular.isArray(errorData[key])) {
                  if (errorData[key].length > 1) {
                    rv.msg += " has multiple errors.";
                    rv.details = errorData[key].map(detailMsg => `${key} ${detailMsg}`);
                  } else {
                    rv.msg += ` ${errorData[key][0]}`;
                  }
                } else if (angular.isString(errorData[key])) {
                  rv.msg += ` ${errorData[key]}`;
                }
              }
            }
          }

          if ((rv.msg === '') && scope.appError) {
            rv.msg = scope.appError;
          }

          return rv;
        };
      }
    })
  

  ]);
