// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives').directive('ftpBrowser', [ '$log', '$q', function($log, $q) {
  return {
    restrict:     'AE',
    transclude:   true,
    require:      'ngModel',
    scope: {
      initialPath:      '@',
      selectedPaths:    '=',
      includeFolders:   '=',
      mode:             '@'
    },
    templateUrl: 'directives/ftp-browser.html',
    link(scope, elem, attrs, ngModelCtrl) {
      const treeRoot = {
        id:         scope.initialPath,
        text:       scope.initialPath,
        path:       scope.initialPath,
        parent:     '#',
        children:   true,
        type:       "root"
      };

      const treeCfg = {
        core: {
          // This is the recommended way per:
          // https://groups.google.com/forum/#!topic/jstree/22huAf7Co2c
          data(node, cb) {
            if (node.id === '#') {
              return cb.call(this, [treeRoot]);
            } else {
              let url;
              if (scope.mode.toLowerCase() === "s3") {
                url = "/api/ftp_browser/s3_ls";
              } else {
                url = "/api/ftp_browser/ls";
              }
              return $.ajax({
                data: {
                  path: node.id
                },
                url
              }).done( function(data) {
                return cb.call(this, data.files);
              });
            }
          },
          check_callback: true,
          error(err) { return $log.error(`treeCtrl: error from js tree - ${angular.toJson(err)}`); }
        },
        version: 1,
        types: {
          default: {
            icon: 'glyphicon glyphicon-cat'
          },
          root: {
            icon: 'glyphicon glyphicon-flash'
          },
          file: {
            icon: 'glyphicon glyphicon-file'
          }
        },
        plugins: ["types", "wholerow", "checkbox"]
      };

      const $elem = angular.element(elem);
      $elem.jstree(treeCfg);

      // Populate the top level once the tree is ready
      $elem.on('ready.jstree', () =>
        //console?.log("jstree ready! mode: #{scope.mode}")
        $elem.jstree('open_node', scope.initialPath, () => selectInitialNodes())
      );

      // Loads / marks nodes as selected based on the specified model.
      // We use a promise chain to ensure that open_node calls on intermediate
      // directories have finished before making one final select_node call to 
      // do all the selections in one shot.  This nicely avoids triggering unnecessary
      // $watch calls in the caller.
      var selectInitialNodes = function() {
        const promises = [];
        angular.forEach(ngModelCtrl.$modelValue, function(path) {
          const deferred  = $q.defer();
          let { promise }   = deferred;
          const parts     = path.split('/');
          let curPath   = scope.initialPath;

          angular.forEach(parts, function(part, idx) {
            if (idx === 0) { return; }  // The root is already open
            curPath += part;     // Desired pieces: /, /foo, /foo/bar, ...

            //console?.log("selectInitialNodes, curPath/idx/parts.length: ", curPath, idx, parts.length)
            if (parts.length === 2) {
              // Top-level file, selected, just tell jstree
              $elem.jstree('select_node', path);
            } else if (idx === (parts.length - 1)) {
              // Leaf node, do nothing, will be handled later
              true;
            } else {
              // Interior directory node, tell jstree to open it
              // and make deferreds.  Respect currently pending deferreds.
              const tmp = curPath;
              promise = promise.then( function() {
                //console?.log("calling open_node with: ", tmp)
                const d = $q.defer();
                $elem.jstree('open_node', tmp, () => d.resolve());
                return d.promise;
              });
              curPath += "/";
            }
            return promises.push(promise);
          });
          return deferred.resolve();
        });
        return $q.all(promises).then( () =>
          // Ok, all requisite nodes are open.  Fire the select_node calls!
          $elem.jstree('select_node', ngModelCtrl.$viewValue, true)
        );
      };


      // Returns an array of paths with the folders removed
      const stripFolders = function(paths) {
        const rv = [];
        for (let path of Array.from(paths)) {
          const n = $elem.jstree().get_node(path);
          if (n.original.type !== "folder") {
            rv.push(path);
          }
        }
        return rv;
      };

      const openSelectedFolder = function(selected, doneCb) {
        // Open the node if its not
        if (selected.node.original.type === "folder") {
          return selected.instance.open_node(selected.node, function() {
            // Re-fetch the node to populate its children
            const node = $elem.jstree('get_node', selected.node);
            if (node.children.length === 0) {
              selected.instance.deselect_node(node);
            }
            return doneCb();
          });
        } else {
          return doneCb();
        }
      };

      const syncSelected = function(selected) {
        // Sync selected with the model
        let allPaths = selected.instance.get_selected();
        if (!scope.includeFolders) {
          allPaths = stripFolders(allPaths);
        }
        //console?.log("all selected paths:", allPaths)
        return ngModelCtrl.$setViewValue(allPaths);
      };

      $elem.on('select_node.jstree', (node, selected, event) =>
        openSelectedFolder(selected, () => syncSelected(selected))
      );
      $elem.on('deselect_node.jstree', (node, selected, event) =>
        openSelectedFolder(selected, () => syncSelected(selected))
      );
      $elem.on('after_open.jstree after_close.jstree', (node, selected, event) => $elem.find('.loading').removeClass('loading'));
      $elem.on('click', function(evt) {
        const $target = $(evt.target);
        if ($target.hasClass('jstree-ocl')) {
          const $row = $target.parent();
          return $row.addClass('loading');
        }
      });

      return ngModelCtrl.$render = () =>
        // Mark all elements in the array as selected
        Array.from(ngModelCtrl.$viewValue).map((path) =>
          $elem.jstree('select_node', path))
      ;
    }
  };
}

]);
