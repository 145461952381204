// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').service('identitySerializer', ['railsSerializer', function(railsSerializer) {
  const customizer = {
    underscore(attr) { return attr; },
    camelize(attr) { return attr; },
    pluralize(attr) { return attr; }
  };

  return railsSerializer(customizer);
}
]);
