// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('WatcherCache', [ function() {
  // This class watches a referenced object and clears itself when it notices a change.
  let WatcherCache;
  return (WatcherCache = class WatcherCache {
    constructor(refObj) {
      this.refObj = refObj;
      this._cache = {};
    }

    get(key) {
      this.check();
      return this._cache[key];
    }

    set(key, val) {
      this.check();
      this._cache[key] = val;
      return val;
    }

    clear() {
      this._cache = {};
      this.savedRefObj = angular.copy(this.refObj);
      return this;
    }

    isStale() { return !angular.equals(this.refObj, this.savedRefObj, true); }
    isEmpty() { return angular.equals(this._cache, {}, true); }

    check() {
      if (this.isStale()) {
        this.clear();
      }
      return this;
    }

    get_or_call(key, cb) {
      let val = this.get(key);
      if (val === undefined) {
        val = cb();
        this.set(key, val);
      }
      return val;
    }
  });
}
]);
