// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

const FieldEditorCtrl = ['$scope', '$log', '$q', 'AUDIENCE_FILE_CANONICAL_HEADERS', function($scope, $log, $q, CANON_HEADERS) {
  let left;
  $scope.refreshFailed = false;
  $scope.contentSample = $scope.file.contentSample;
  $scope.nonCanonCols = [];
  $scope.showHeaderMeta = ((left = $scope.showHeaderMeta === undefined) != null ? left : {true : $scope.showHeaderMeta});
  $scope.requiredColumns = $scope.audience.requiredColumns().sort();
  $scope.optionalColumns = $scope.audience.optionalColumns().sort();

  //console.log("Required columns", $scope.requiredColumns);

  const setupColumns = function(curCols, data) {
    const rv = [];
    if ((data != null ? data.length : undefined) > 0) {
      for (let idx = 0; idx < data[0].length; idx++) {
        const val = data[0][idx];
        if (curCols != null ? curCols[idx] : undefined) {
          const colVal = angular.copy(curCols[idx]);
          rv.push(colVal);

          // Check if any column has data not in headerNames.  If so, that column
          // has a user-defined name, and lets make it available in all columns.
          if ($scope.headerNames.indexOfByKey("id", colVal) === -1) {
            $scope.headerNames.push({id: colVal, name: colVal});
          }
        } else {
          rv.push("");
        }
      }
    }
    return rv;
  };

  $scope.refreshContent = function() {
    $scope.showSpinner = true;
    return $scope.file.refreshContentSample().then( function() {
      $scope.showSpinner   = false;
      $scope.refreshFailed = false;
      return $scope.cols = setupColumns($scope.file.headerMap, $scope.file.contentSample);
    }
    , function(err) {
      $scope.showSpinner   = false;
      $scope.refreshFailed = true;
      return $log.error("Failed to refresh content sample: ", err);
    });
  };

  if (!$scope.file.headerMap || ($scope.file.headerMap.length === 0) || ($scope.file.contentSample === 0) || ($scope.file.contentSample.length === 0)) {
    // Missing data of some kind, go fetch it!
    $scope.refreshContent();
  } else {
    // Some semblance of a mapping in place, bootstrap!
    $scope.cols = setupColumns($scope.file.headerMap, $scope.file.contentSample);
  }

  // Calls other validation functions
  $scope.handleFieldHeaderChange = function(curCol, curIdx) {
    $scope.clearConflicts(curCol, curIdx);
    $scope.checkCanon(curCol, curIdx);
    $scope.refreshRequired();
    return false;
  };

  // If the user maps 2 columns to the same field name, we unset any not
  // matching the current index.
  // Ignores blanks / ignored columns
  $scope.clearConflicts = function(curCol, curIdx) {
    //$log.debug("in clearConflicts")
    for (let idx = 0; idx < $scope.cols.length; idx++) {
      const col = $scope.cols[idx];
      if ((curCol !== "") && (col === curCol) && (idx !== curIdx)) {
        //$log.debug("reset idx #{idx} to blank")
        $scope.cols[idx] = "";
      }
    }
    return true;
  };

  $scope.checkCanon = function(curCol, curIdx) {
    if ((typeof curCol !== 'undefined') && (CANON_HEADERS.indexOf(curCol) === -1)) {
      $scope.nonCanonCols.push(curIdx);
    }
    return false;
  };

  $scope.refreshRequired = function() {
    if ($scope.requiredColumns.length > 0) {
      $scope.requiredBreakdown = $scope.audience.requiredColumnsBreakdown();
      $scope.optionalBreakdown = $scope.audience.optionalColumnsBreakdown();
    }
  };

  // Propagate changes
  $scope.$watch("cols", function(newV, oldV) {
    //$log.debug("in watch for cols")
    if (newV === oldV) { return; }
    return $scope.file.headerMap = newV;
  }
  , true);

  $scope.addTableTag = function(tag) {
    const tagObj = {id: tag.toLowerCase(), name: tag};
    $scope.$emit('headerChange:new', tagObj);

    // Make sure the tag/tag object is returned, otherwise ui-select
    // thinks we've rejected the tag.
    return tagObj;
  };

  $scope.$watch('headerNames', function(nV, oV) {
    tagObj;
    if (nV !== oV) {
      var tagObj = nV;
      if ($scope.headerNames.indexOfByKey("id", tagObj) === -1) {
        return $scope.headerNames.push(tagObj);
      }
    }
  });

  $scope.columnOptions = [
    { label: 'Round', name: 'rounded' }
  ];

  const vendorSpecificColOpts = {
    "Facebook": {
      options: [{ label: 'Facebook Segment', name: 'facebook_audience_segment' }],
      checkFn() { return $scope.audience.wantsFacebookSegments(); }
    }
  };

  for (let vendorName in vendorSpecificColOpts) {
    const colOptSpec = vendorSpecificColOpts[vendorName];
    if (colOptSpec.checkFn()) {
      angular.forEach(colOptSpec.options, colOpt => $scope.columnOptions.push(colOpt));
    }
  }

  // Still need to show the column options when an option is true
  $scope.inCanonHeaders = function(field) {
    for (let kField of Array.from(CANON_HEADERS)) {
      if (field.name === kField.name) {
        for (let headerMeta of Array.from($scope.file.headerMeta)) {
          if (field.name === headerMeta) {
            true;
          }
        }
      }
    }
    return false;
  };

  $scope.showFormatter = function($index) {
    if (!$scope.showHeaderMeta) {
      return false;
    }

    if ($scope.audience.wantsFacebookSegments()) {
      return true;
    }

    let keyMatch = false;
    angular.forEach($scope.file.headerMeta[$index], (meta, metaKey) =>
      Array.from($scope.columnOptions).map((opt) =>
        (keyMatch = (metaKey === opt.name)))
    );
    return (($scope.nonCanonCols.indexOf($index) !== -1) || keyMatch);
  };

  $scope.useFileHeaders = file => $scope.cols = setupColumns(file.contentSample[0], file.contentSample);

  $scope.clearHeaders = file => $scope.cols = setupColumns([], file.contentSample);

  $scope.refreshRequired();
  return true;
}
];

angular.module('kazaam.directives').directive('audienceFieldEditor', [ () =>
  ({
    restrict:     'AE',
    scope: {
      audience:         '=',
      file:             '=',
      headerNames:      '=',
      showHeaderMeta:   '=?',
      formName:         '@?'
    },
    templateUrl:  'directives/field-editor.html',
    controller: FieldEditorCtrl
  })

]);
