// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('UploaderReportCtrl',
  ['$scope', 'audience', '$state', 'SimplePoller', 'vendors', 'friendlyStatus', 'AdBuyChannel', 'emails', 'creator', '$transitions',
  function($scope, audience, $state, SimplePoller, vendors, friendlyStatus, AdBuyChannel, emails, creator, $transitions) {
    $scope.title    = $state.current.data != null ? $state.current.data.title : undefined;
    $scope.audience = audience;
    $scope.creator  = creator;
    $scope.emails   = emails;
    $scope.friendlyStatus = friendlyStatus;

    $scope.vendorIdMap = {};
    angular.forEach(vendors, v => $scope.vendorIdMap[v.id] = v);

    // Quick-add related
    $scope.newStrategy    = "waterfall";
    $scope.showAddVendor  = false;

    const buildAvailableVendors = function() {
      $scope.availableVendors = [];
      return AdBuyChannel.query().then( function(channels) {
        const seen = {};
        angular.forEach(channels, chan =>
          (() => {
            const result = [];
            for (let cmp of Array.from(chan.channelMatchPartners)) {
              const vId = cmp.matchVendorId;
              if (($scope.audience.matchPlan.selectedVendorIds.indexOf(vId) === -1) && !seen[vId]) {
                seen[vId] = true;
                result.push($scope.availableVendors.push( $scope.vendorIdMap[vId] ));
              } else {
                result.push(undefined);
              }
            }
            return result;
          })()
        );
        //console?.log("availableVendors", $scope.availableVendors)
        return channels;
      });
    };

    if ($scope.audience.canAddVendors()) {
      buildAvailableVendors();
    }

    const stopPolling = function() {
      // If we get any sort of weirdness, then stop
      if (!$scope.audience || (typeof $scope.audience.analysisPending  !== 'function') || (typeof $scope.audience.analysisRunning  !== 'function') || (typeof $scope.audience.matchStarted     !== 'function')) {
        return true;
      }

      // Keep polling (aka return false) if we're still analyzing or still matching
      return !($scope.audience.analysisPending() || $scope.audience.analysisRunning() || $scope.audience.matchStarted());
    };

    const refreshAnalysisStatus = () =>
      $scope.audience.get().then( function(aud) {
        // Try to deal with a weird error I haven't successfully tracked down.  The error is:
        // "aud.analysisRunning is not a function"
        if (!((typeof aud.analysisRunning === 'function') && (typeof aud.analysisPending === 'function') && (aud.analysisRunning() || aud.analysisPending()))) {
          $scope.audience = aud;
        }
        return aud;
      }
      , function(err) {
        if (typeof console !== 'undefined' && console !== null) {
          console.log("refreshAnalysisStatus failed: ", err);
        }
        return err;
      })
    ;

    const reprocessMatchback = (vendor_id) => {
      $scope.audience.reprocessMatchback(vendor_id).then( (aud) => {
        $scope.audience = aud;
        return aud;
      }, (err) => {
        console.log("reprocessMatchback failed: ", err);
        return err;
      });
    };
    $scope.reprocessMatchback = reprocessMatchback;

    // When we're waiting for the analysis to finish, poll frequently
    let cutoff    = 120 * 60 * 1000; // 120 minutes
    let pollFreq  = 5000;
    let initDelay = 5000;

    // Things slow down if the match has started, so adjust
    if ($scope.audience.matchStarted()) {
      cutoff    = 14 * 24 * 60 * 60 * 1000; // 2 weeks
      pollFreq  = 5 * 60 * 1000; // 5 min
      initDelay = 5 * 60 * 1000; // 5 min
    }

    $scope.poller = new SimplePoller(stopPolling, refreshAnalysisStatus, cutoff, pollFreq, initDelay);
    $transitions.onExit({exiting: $state.current.name}, transition => $scope.poller.stop());
    $scope.poller.start({logErrors: true});

    $scope.approve = () =>
      $scope.audience.approve().then( aud => $state.go("uploader.viewer", {audienceId: aud.id}, {reload: true}))
    ;

    $scope.addVendor = (newVendor, strategy)=>
      $scope.audience.addVendor(newVendor.id, strategy).then( function(aud) {
        $scope.audience       = aud;
        $scope.newVendor      = undefined;
        $scope.showAddVendor  = false;
        return buildAvailableVendors();
      })
    ;

    $scope.syncNotifications = aud => aud.save();

    return true;
  }
]);
