// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('DcmCreative', ['RailsResource', 'railsSerializer', 'DbmResource',
  function(RailsResource, railsSerializer, DbmResource) {
    let DcmCreative;
    const ser = railsSerializer( function() {
      return this.exclude('dbmMapping');
    });
    return DcmCreative = (function() {
      DcmCreative = class DcmCreative extends RailsResource {
        static initClass() {
          this.configure({url: '/api/dcm_creatives', name: 'dcm_creative', serializer: ser});
        }

        queryDbmMapping(term, mappingType, page) {
          if (mappingType == null) { mappingType = undefined; }
          if (page == null) { page = undefined; }
          return DbmResource.search(term, "creative", undefined, page);
        }
      };
      DcmCreative.initClass();
      return DcmCreative;
    })();
  }
]);
