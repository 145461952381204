// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

const SecondaryNavController = ['$scope', '$stateParams', function($scope, $stateParams) {
  if (!$scope.navlist) { return; }
  return Array.from($scope.navlist).map((tab) => {
    const params = {};
    if (tab.params === '') {
      Object.assign(params, $stateParams);
    } else {
      Object.assign(params, $stateParams, tab.params);
    }

    tab.path = tab.pathName + '(' + JSON.stringify(params) + ')'
  });
}];

angular.module('kazaam.directives').directive('secondaryNav', () =>
  ({
    restrict:     'AE',
    scope: {
      navlist:    '=secondaryNav'
    },
    templateUrl:  'directives/secondary-nav.html',
    controller:   SecondaryNavController
  })
);
