// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
"use strict";

angular.module("kazaam.models").factory("UploadedAudience", [
  "RailsResource",
  "railsSerializer",
  "highChartsHelpers",
  "AudienceFile",
  "AudienceQueryUploadMetadatum",
  "friendlyStatus",
  function (
    RailsResource,
    railsSerializer,
    hch,
    AudienceFile,
    AudienceQueryUploadMetadatum,
    friendlyStatusMap
  ) {
    let UploadedAudience;
    const ser = railsSerializer(function () {
      this.resource("client", "Client");
      this.exclude("client");
      this.exclude("availableVendors");
      this.exclude("selectedPaths");
      this.exclude("selectedQueries");
      this.exclude("canAddFullVendors");
      this.exclude("nonPiiSegmentsByVendor");
      this.exclude("nonPiiSegmentLimit");
      this.resource("audienceFiles", "AudienceFile");
      this.resource(
        "audienceQueryUploadMetadata",
        "AudienceQueryUploadMetadatum"
      );
      this.nestedAttribute("audienceFiles");
      return this.nestedAttribute("audienceQueryUploadMetadata");
    });

    // Returns a function suitable for use as a RailsResource beforeRequest data transformation
    const objToIdArrayFactory = (objKey, idListKey) =>
      function (data) {
        if (!data || !data.hasOwnProperty(objKey)) {
          return data;
        }
        const idList = [];
        angular.forEach(data[objKey], (obj) => idList.push(obj.id));
        delete data[objKey];
        hch.deepSet(data, idListKey, idList);
        return data;
      };
    return (UploadedAudience = (function () {
      let MATCH_STRATEGIES = undefined;
      let FILE_ORIGINS = undefined;
      UploadedAudience = class UploadedAudience extends RailsResource {
        static initClass() {
          this.configure({
            url: "/api/uploaded_audiences",
            name: "uploaded_audience",
            serializer: ser
          });

          MATCH_STRATEGIES = ["full", "waterfall"]; //, "hybrid"]
          FILE_ORIGINS = ["s3", "exavault"];

          this.beforeRequest(
            objToIdArrayFactory("channels", "ad_buy_channel_ids")
          );
          this.beforeRequest(
            objToIdArrayFactory(
              "selected_vendors",
              "match_plan.selected_vendor_ids"
            )
          );
        }

        partnerList() {
          return this.selectedVendors != null
            ? this.selectedVendors.map((v) => v.name).join(", ")
            : undefined;
        }
        channelList() {
          return this.channels != null
            ? this.channels.map((c) => c.name).join(", ")
            : undefined;
        }
        matchStrategies() {
          return MATCH_STRATEGIES;
        }
        hasVendorByAttr(key, val) {
          return this.selectedVendors.indexOfByKey(key, val) !== -1;
        }

        // Builds a temporary array from an object list and a list of ids
        hydrateArrayById(objs, ids, targetKey) {
          const newAry = [];
          const objMap = {};
          angular.forEach(objs, (obj) => (objMap[obj.id] = obj));
          angular.forEach(ids, function (id) {
            const obj = objMap[id];
            return newAry.push(obj);
          });
          return (this[targetKey] = newAry);
        }
        hydrateChannels(channels) {
          return this.hydrateArrayById(
            channels,
            this.adBuyChannelIds,
            "channels"
          );
        }
        hydrateVendors(vendors) {
          return this.hydrateArrayById(
            vendors,
            this.matchPlan.selectedVendorIds,
            "selectedVendors"
          );
        }

        hydrateQueries() {
          this.selectedQueries = [];
          return Array.from(this.audienceQueryUploadMetadata).map((aqum) =>
            this.selectedQueries.push(aqum.audienceQuery)
          );
        }

        // Sets @selectedPaths based on @audienceFiles
        hydratePaths() {
          this.selectedPaths = {};
          for (let fo of Array.from(FILE_ORIGINS)) {
            this.selectedPaths[fo] = [];
          }

          return Array.from(this.audienceFiles).map((af) =>
            this.selectedPaths[af.origin].push(af.fname)
          );
        }

        audienceFileByPath(path) {
          for (let af of Array.from(this.audienceFiles)) {
            if (af.fname === path) {
              return af;
            }
          }
          return undefined;
        }

        // Matches files based on their path and origin type
        findPathAndOrigin(ary, elm) {
          const idx = hch.indexOfByKey(ary, elm, "path");
          if (idx === -1) {
            return false;
          }

          // Ensure the found object has the same origin
          return ary[idx].origin === elm.origin;
        }

        syncAudienceFiles() {
          // Sanity check - ensure that selectedPaths is populated, otherwise
          // we will incorrectly detect that all files were removed.
          let af, pathObj;
          if (!this.selectedPaths) {
            return;
          }

          // Build a single array of (path, origin) tuples and use that for add/remove
          // detection.
          const allSelected = [];
          for (let fo of Array.from(FILE_ORIGINS)) {
            for (let f of Array.from(this.selectedPaths[fo])) {
              allSelected.push({ path: f, origin: fo });
            }
          }

          // Now figure out what's changed
          const curPaths = this.audienceFiles.map((af) => ({
            path: af.fname,
            origin: af.origin
          }));
          const changes = hch.arrayChangeSet(
            curPaths,
            allSelected,
            this.findPathAndOrigin
          );

          for (pathObj of Array.from(changes.added)) {
            af = new AudienceFile({
              fname: pathObj.path,
              origin: pathObj.origin
            });
            this.audienceFiles.push(af);
          }
          for (pathObj of Array.from(changes.removed)) {
            for (af of Array.from(this.audienceFiles)) {
              if (af.fname === pathObj.path && (af.origin = pathObj.origin)) {
                af.markForDelete();
                break;
              }
            }
          }

          return true;
        }

        syncAudienceQueries() {
          // Sanity check - ensure that selectedQueries is populated, otherwise
          // we will incorrectly detect that all files were removed.
          let aqum, qryId;
          if (!this.selectedQueries) {
            return;
          }

          const selectedIds = this.selectedQueries.map((qry) => qry.id);
          const curIds = this.audienceQueryUploadMetadata.map(
            (aqum) => aqum.audienceQueryId
          );
          const changes = hch.arrayChangeSet(curIds, selectedIds);

          for (qryId of Array.from(changes.added)) {
            aqum = new AudienceQueryUploadMetadatum({ audienceQueryId: qryId });
            this.audienceQueryUploadMetadata.push(aqum);
          }
          for (qryId of Array.from(changes.removed)) {
            for (aqum of Array.from(this.audienceQueryUploadMetadata)) {
              if (aqum.audienceQueryId === qryId) {
                aqum.markForDelete();
                break;
              }
            }
          }

          return true;
        }

        analyzeFiles() {
          const url = this.$url("/analyze-files");
          return UploadedAudience.$post(url);
        }

        save() {
          this.syncAudienceFiles();
          this.syncAudienceQueries();
          return super.save(...arguments);
        }

        approve() {
          const url = this.$url("/approve");
          return UploadedAudience.$post(url);
        }

        reset() {
          const url = this.$url("/reset");
          return UploadedAudience.$post(url);
        }

        addVendor(vendor_id, strategy) {
          const url = this.$url("/add-vendor");
          return UploadedAudience.$post(
            url,
            { match_vendor_id: vendor_id, strategy },
            { skipRequestProcessing: true }
          );
        }
        reprocessMatchback(vendor_id) {
          const url = this.$url(`/reprocess-matchback/${vendor_id}`);
          return UploadedAudience.$post(url);
        }

        isLocked() {
          return this.status !== "awaiting_approval";
        }
        isFailed() {
          return this.status === "failed";
        }

        isApprovable() {
          if (this.isLocked()) {
            return false;
          }
          if (
            this.hasVendorSegments() > 0 &&
            Math.max.apply(null, Object.values(this.nonPiiSegmentsByVendor)) >
              this.nonPiiSegmentLimit
          ) {
            return false;
          }
          return true;
        }

        canAddVendors() {
          return this.isLocked() && !this.isFailed();
        }

        analysisDone() {
          return this.analysisStatus === "completed";
        }
        analysisFailed() {
          return this.analysisStatus === "failed";
        }
        analysisPending() {
          return this.analysisStatus === "on_hold";
        }
        analysisRunning() {
          return this.analysisStatus === "running";
        }

        matchStarted() {
          return ["scheduled", "running"].indexOf(this.status) !== -1;
        }

        downloadUrl(vendorId) {
          return `/audience-activator/${this.id}/vendor-file/${vendorId}`;
        }
        uniqueValuesUrl(vendorId) {
          return `/audience-activator/${this.id}/unique-values/${vendorId}`;
        }

        friendlyStatus() {
          return friendlyStatusMap[this.status];
        }

        individualMatchRate() {
          if (this.rowCount && this.rowCount > 0) {
            return this.matchCount / this.rowCount;
          } else {
            return 0;
          }
        }
        householdMatchRate() {
          if (this.householdCount && this.householdCount > 0) {
            return this.hhMatchCount / this.householdCount;
          } else {
            return 0;
          }
        }

        matchbackPossible() {
          return this.hasVendorByAttr("supportsMatchback", true);
        }
        hasCustomAudienceVendor() {
          return this.hasVendorByAttr("transferProtocol", "custom_audience");
        }
        wantsFacebookSegments() {
          // For facebook w/ this option selected, always show the formatter
          return (
            this.matchPlan.allCustomAudienceCombos &&
            this.hasCustomAudienceVendor()
          );
        }

        matchStatusesWithExceptions() {
          return this.audienceMatchStatuses.filter((ams) =>
            ams.details.hasOwnProperty("exception")
          );
        }

        segmentCount() {
          if (this.matchPlan.hasOwnProperty("segments")) {
            return this.matchPlan.segments.length;
          } else {
            return 0;
          }
        }
        segmentRecordCount(seg) {
          return Math.floor((Number(seg.pct) / 100) * this.rowCount);
        }
        segmentationStyle() {
          if (this.matchPlan.segmentationStrategy) {
            return this.matchPlan.segmentationStrategy.capitalize();
          } else {
            return "Individual";
          }
        }

        // I had trouble calling Object.keys() directly in templates, so ... helpers...
        hasVendorSegments() {
          return Object.keys(this.nonPiiSegmentsByVendor) > 0;
        }
        nonPiiSegmentVendorIds() {
          return Object.keys(this.nonPiiSegmentsByVendor);
        }

        // FBR related helpers
        hasFBR() {
          return this.audienceMatchStatuses.some((ams) => ams.fbrMatchback);
        }
        allFBR() {
          return this.audienceMatchStatuses.every((ams) => ams.fbrMatchback);
        }

        // None yet but for method-signature consistency w/ EtlJob
        requiredColumns() {
          return [];
        }
        optionalColumns() {
          return [];
        }
        requiredColumnsPresent() {
          return true;
        }
      };
      UploadedAudience.initClass();
      return UploadedAudience;
    })());
  }
]);
