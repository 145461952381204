// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

const UploaderPageCtrl = ['$scope', '$state', function($scope, $state) {
  $scope.resetAudience = audience =>
    audience.reset().then( aud => $state.go("uploader.viewer", {audienceId: aud.id}, {reload: true}))
  ;

  return $scope.destroy = audience =>
    audience.delete().then( () => $state.go("uploader.list"))
  ;
}
];

angular.module('kazaam.directives')
  .directive('uploaderPageControls', [ '$stateParams', $stateParams =>
    ({
      restrict: 'AE',
      scope: {
        audience: '='
      },
      templateUrl: 'uploader/directives/uploader-page-controls.html',
      controller: UploaderPageCtrl
    })
  
  ]);
