// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

const savedAudienceController = ['$scope', '$q', 'DBM_SECONDARYNAV', function($scope, $q, DBM_SECONDARYNAV) {
    if (!$scope.audience) {
      throw 'Saved audience requires an audience object';
    }

    $scope.editingAudience  = false;
    $scope.secondaryNav     = DBM_SECONDARYNAV;
    $scope.newAudience      = $scope.audience.isNew();

    let audienceCache = angular.copy($scope.audience);

    if ($scope.newAudience) {
      $scope.editingAudience = true;
    }

    $scope.saveAudience = function() {
      const wasNew = $scope.audience.isNew();
      return $scope.audience.save().then( function(res) {
        audienceCache = angular.copy(res);
        $scope.newAudience = false;
        return $scope.$emit('savedAudienceAction:success', $scope.audience, wasNew);
      });
    };

    $scope.removeAudience = () =>
      $scope.audience.delete().then( () => $scope.$parent.savedAudiences.splice($scope.$parent.$index, 1))
    ;

    $scope.cancelEditing = function() {
      $scope.editingAudience = false;
      return $scope.audience = angular.copy(audienceCache);
    };

    // Setup a watcher b/c our parent may replace the audience after save
    return $scope.$watch("audience", function(newV, oldV) {
      if (newV) {
        return $scope.newAudience = newV.isNew();
      } else {
        return $scope.newAudience = false;
      }
    });
  }
  ];

angular.module('kazaam.directives')
  .directive('savedAudience',
    [ () =>
      ({
        restrict: 'AE',
        scope: {
          audience:     '=savedAudience',
          newAudience:  '=?'
        },
        templateUrl: 'directives/trafficker-saved-audience.html',
        controller: savedAudienceController
      })
    
    ]
  );
