'use strict';

angular.module('kazaam.models').factory('MediaPlanAudience', ['RailsResource', 'railsSerializer', (RailsResource, railsSerializer) => {
  let MediaPlanAudience;
  const ser = railsSerializer( function() {
    this.resource('mediaPlanAudienceFees', 'MediaPlanAudienceFee');
    this.nestedAttribute('mediaPlanAudienceFees');
    this.exclude('uploadedAudience');
    this.exclude('createdAt');
    this.exclude('updatedAt');
  });

  const _audienceTypes = ["All", "1st Party", "Behavioral", "Demo", "Geofencing", "Lookalike", "Retargeting", "Search / Contextual"];
  const _genders = ["All", "M", "F"];

  return MediaPlanAudience = (() => {
    MediaPlanAudience = class MediaPlanAudience extends RailsResource {
      static initClass() {
        this.configure({url: '/api/media_plan_audiences', name: 'media_plan_audience', serializer: ser});
      }

      static audienceTypes() { return _audienceTypes; }
      static genders() { return _genders; }

      markForDelete() { return this._destroy = 1; }
      undoMarkForDelete() { return delete this._destroy; }

      ageRange() {
        let range;
        if (this.maxAge) { 
          range = `${this.minAge}-${this.maxAge}`;
        } else {
          range = `${this.minAge}+`;
        }
        return range;
      }

      genderText() {
        if (this.gender === 'M') { return 'Men'; }
        if (this.gender === 'F') { return 'Women'; }
        if (this.gender === 'All') { return 'All'; }
      }

      targetSummary() {
        return `${this.ageRange()} ${this.genderText()}`;
      }

    };
    MediaPlanAudience.initClass();
    return MediaPlanAudience;
  })();
}
]);
