// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('S3TransferController',
  ['$scope', '$state', '$stateParams', '$raven', 'clients', 'currentUser', 'S3UserUpload', 'Upload', 'XmlToJson', '$q',
  function($scope, $state, $stateParams, $raven, clients, currentUser, S3UserUpload, Upload, xmlToJson, $q) {
    $scope.title              = $state.current.data.title;
    $scope.clients            = clients;
    $scope.recentFiles        = [];
    $scope.error              = false;
    $scope.errorMsg           = '';
    $scope.prefixes           = [];

    const ctrl = this;

    this.refreshPrefixes = function() {
      if ($scope.s3uuTemplate.clientId) {
        return $scope.s3uuTemplate.fetchPrefixes().then(prefixes => $scope.prefixes = prefixes);
      } else {
        return $scope.prefixes = [];
      }
    };

    this.reset = function() {
      $scope.error              = false;
      $scope.errorMsg           = '';
      if (!$scope.s3uuTemplate) { $scope.s3uuTemplate = new S3UserUpload({userId: currentUser.id, clientId: Number($stateParams.clientId), prefix: ""}); }

      // Force this if the user only has 1
      if (clients.length === 1) {
        $scope.s3uuTemplate.clientId = clients[0].id;
      }

      this.refreshPrefixes();
      return this;
    };

    this.uploadFinished = $event =>
      //console.log("Upload finished: ", $event)
      $event
    ;

    $scope.$watch('s3uuTemplate.clientId', function(newV, oldV) {
      if (angular.equals(newV, oldV)) { return; }
      $scope.s3uuTemplate.prefix = "";
      return ctrl.refreshPrefixes();
    });

    this.reset();
    return this;
  }
]);
