// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers')
  .controller('placementSelectController', ['$scope', 'Dtv2Placement', '$q', 'MIN_SELECT_REFRESH', function($scope, Dtv2Placement, $q, MIN_SELECT_REFRESH) {
    $scope.searchEnabled = undefined;
    $scope.searchResults = [];

    // Pagination stuff
    const loadingItem   = {id: "loading", name: 'Loading...', isLoadingItem: true};
    const hasNextChunk  = true;
    let searchTerm    = '';
    let nextPage      = 2;

    const addLoadingItem    = () => $scope.searchResults.push(loadingItem);
    const removeLoadingItem = function() {
      const idx = $scope.searchResults.indexOf(loadingItem);
      if (idx >= 0) {
        $scope.searchResults.splice(idx, 1);
      }
      return true;
    };

    const getInfiniteScrollChunk = function(pageNum) {
      if (pageNum == null) { pageNum = 1; }
      return Dtv2Placement.search(searchTerm, pageNum, {dtv2_campaign_id: $scope.campaignId});
    };

    $scope.refreshList = function(search) {
      searchTerm  = search.length ? search : '';
      nextPage    = 2;
      if (searchTerm.length >= MIN_SELECT_REFRESH.length) {
        return getInfiniteScrollChunk().then( function(results) {
          $scope.searchResults = [];
          // Filter any already selected results
          for (let result of Array.from(results)) {
            if ($scope.localData.data.indexOfByKey("id", result.id) === -1) {
              $scope.searchResults.push(result);
            }
          }

          return $scope.searchResults;
        }
        , err => $q.reject(err));
      }
    };
    // End pagination related

    $scope.isSelectOpen = function(isOpen, $select) {
      if (!isOpen) {
        return $select.search = '';
      }
    };

    //
    // There appears to be a ui-select bug whereby using $scope.currentSettings
    // directly doesn't propagate changes.  I suspect something is breaking the
    // reference chain inside ui-select, but its not clear what/where.
    //
    $scope.localData = {data: angular.copy($scope.placements)};
    $scope.$watch("localData", function(newV, oldV) {
      if (oldV === newV) { return; }
      return angular.copy(newV.data, $scope.placements);
    }
    , true);

    return $scope.$on('placementSelect:reset', function(event, resetKey) {
      if (resetKey && $scope.resetRestrict && (resetKey !== $scope.resetRestrict)) {
        return;
      }

      // Don't wait for the next cycle, just clear them both immediately.
      $scope.localData.data.clear();
      return $scope.placements.clear();
    });
  }
  ]);
