// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('StaffOverviewController', ['$scope', 'allReports', 'currentUser', 'overviewCtrlHelper',
  function($scope, allReports, currentUser, och) {
    $scope.title            = 'Overview';
    $scope.currentUser      = currentUser;

    // Ui-grid stuff
    $scope.tables           = [];
    $scope.tblApis          = [];
    $scope.tblHeights       = [];

    $scope.runningReports   = [];
    $scope.completedReports = [];

    const sortedReports = allReports.sort( (a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // by date, desc
    angular.forEach(sortedReports, function(report) {
      if (report.hasSufficientData()) {
        return $scope.completedReports.push(report);
      } else {
        return $scope.runningReports.push(report);
      }
    });

    $scope.runningTable   = ($scope.tables[0] = och.buildTable($scope, $scope.runningReports, 0));
    $scope.completedTable = ($scope.tables[1] = och.buildTable($scope, $scope.completedReports, 1));
    return true;
  }
  ]);

angular.module('kazaam.controllers').controller('UploaderOverviewController', ['$scope', 'currentUser', 'currentSite', '$state',
  function($scope, currentUser, currentSite, $state) {
    $scope.title            = 'Overview';
    $scope.currentUser      = currentUser;

    if (currentUser.canUse('s3Uploader', currentSite)) {
      $state.go("s3Uploader.uploader");
    } else if (currentUser.canUse('mediaCampaigns', currentSite)) {
      $state.go("mediaCampaigns.list");
    }
    return true;
  }
  ]);
