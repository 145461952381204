'use strict';

const ActivatorAudienceSelectCtrl = ['$scope', 'UploadedAudience', '$q', 'MIN_SELECT_REFRESH', function($scope, UploadedAudience, $q, MIN_SELECT_REFRESH) {
  const ctrl = this;
  this.searchResults    = [];

  this.applyChanges = ($item) => {
    if (ctrl.onSelect) {
      ctrl.onSelect({$event: {audience: ctrl.audience, selected: $item}});
    }
  };

  // Pagination stuff
  const loadingItem   = {id: "loading", name: 'Loading...', isLoadingItem: true};
  const hasNextChunk  = true;
  let searchTerm    = '';
  let nextPage      = 2;

  /*
  this.addLoadingItem    = () => ctrl.searchResults.push(loadingItem);
  this.removeLoadingItem = function() {
    const idx = ctrl.searchResults.indexOf(loadingItem);
    if (idx >= 0) {
      ctrl.searchResults.splice(idx, 1);
    }
    return true;
  };
  */

  this.getInfiniteScrollChunk = function(pageNum) {
    if (pageNum == null) { pageNum = 1; }
    const queryParams = {filterField: 'name', filterValue: searchTerm, serializer: 'micro', clientId: ctrl.plan.clientId};
    return UploadedAudience.query(queryParams);
  };

  this.refreshList = function(search) {
    searchTerm  = search.length ? search : '';
    nextPage    = 2;
    if (searchTerm.length >= MIN_SELECT_REFRESH.length) {
      return ctrl.getInfiniteScrollChunk().then( (results) => { return ctrl.searchResults = results || []; }
      , err => $q.reject(err));
    }
  };
  // End pagination related

  ctrl.searchResultLabel = function(item) {
    return item.name;
  };

  ctrl.formatSelected = (item) => {
    if (item && item.name) {
      return item.name;
    } else if (ctrl.audience.uploadedAudience) {
      return ctrl.audience.uploadedAudience.name;
    } else if (typeof item === 'string') {
      return item;
    }
  };

  return this;
}];

angular.module('kazaam.components')
  .component('activatorAudienceSelect', {
    controller: ActivatorAudienceSelectCtrl,
    templateUrl: 'media-planner/components/activator-audience-select.html',
    bindings: {
      audience:             '<',
      plan:                 '<',
      onSelect:             '&'
    }
  });
