'use strict';

const RenamingRuleWidgetCtrl = ['$scope', function($scope) {
  const ctrl = this;
  this.reset = function() {
    this.ensureRules();
    $scope.placeholder = this.placeholder;
    return this;
  };

  this.ensureRules = () => {
    let newRules = angular.copy(this.rules) || [];
    angular.forEach(this.missingVals, (val, idx) => {
      if (newRules[idx]) {
        // Ensure we have something to bind to
        newRules.to = newRules.to || undefined;
      } else {
        newRules[idx] = {from: val, to: undefined};
      }
    });
    return this.newRules = newRules;
  };

  this.applyRules = () => {
    if (ctrl.onRuleChange) {
      ctrl.onRuleChange({$event: {rules: this.newRules, ruleKey: ctrl.ruleKey}});
    }
  };

  this.refreshMatches = async (search) => {
    if (ctrl.searchFunction && search.length > 2) {
      let matches = await ctrl.searchFunction({$event: {search: search}});
      this.possibleMatches = matches;
      $scope.$apply(this.possiblMatches);
    }
  };

  this.$onInit = function() {
    this.reset();
  };

  this.$onChanges = function(changeObj) {
    //console?.log("onChanges(): ", changeObj)
  };

  return this;
}
];

angular.module('kazaam.components')
  .component("renamingRuleWidget", {
    controller: RenamingRuleWidgetCtrl,
    templateUrl: 'media-planner/components/renaming-rule-widget.html',
    bindings: {
      rules: '<',
      uniqueVals: '<',
      missingVals: '<',
      possibleMatches: '<',
      placeholder: '@',
      ruleKey: '@',
      onRuleChange: '&',
      searchFunction: '&'
    }
  });
