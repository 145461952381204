// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

const S3uploaderMediaCampaignHelperCtrl = ['$scope', 'MediaCampaign', 'CreativeConcept', function($scope, MediaCampaign, CreativeConcept) {
  this.reset = function() {
    this.mediaCampaign    = undefined;
    this.mediaCampaigns   = [];
    this.creativeConcept  = undefined;
    return this.creativeConcepts = [];
  };

  this.$onInit = function() {
    //console?.log("onInit()")
    this.reset();

    const ctrl = this;
    $scope.$watch('$ctrl.mediaCampaign', function(newV, oldV) {
      if (oldV === newV) { return; }
      ctrl.refreshCreativeConcepts(newV);
      if (ctrl.onCampaignChange) {
        ctrl.onCampaignChange({$event: {campaign: newV}});
      }
      return newV;
    });
    return $scope.$watch('$ctrl.creativeConcept', function(newV, oldV) {
      if (oldV === newV) { return; }
      if (ctrl.onConceptChange) {
        ctrl.onConceptChange({$event: {concept: newV}});
      }
      return newV;
    });
  };

  this.$onChanges = function(changeObj) {
    //console?.log("onChanges(): ", changeObj)
    if (changeObj.presetCampaignId) {
      this.presetCampaignId = Number(changeObj.presetCampaignId.currentValue);
    }

    if (changeObj.clientId) {
      this.clientId = changeObj.clientId.currentValue;
      return this.refreshMediaCampaigns(this.clientId);
    }
  };

  this.refreshMediaCampaigns = function(clientId) {
    this.reset();
    if (clientId) {
      const ctrl = this;
      return MediaCampaign.query({filterField: "client_id", filterValue: clientId}).then(function(campaigns) {
        ctrl.mediaCampaigns = campaigns;
        if (ctrl.presetCampaignId) {
          ctrl.mediaCampaign = campaigns.find(cpn => cpn.id === ctrl.presetCampaignId);
        }
        return campaigns;
      });
    }
  };

  this.refreshCreativeConcepts = function(cpn) {
    this.creativeConcept  = undefined;
    this.creativeConcepts = [];
    if (cpn) {
      return this.creativeConcepts = cpn.creativeConcepts;
    }
  };

  // Tagging support functions for new objects
  const ctrl = this;
  $scope.setupCampaign = function(name) {
    if (!name) { return; }
    const mc = new MediaCampaign({name, clientId: ctrl.clientId, creativeConcepts: []});
    return mc;
  };

  $scope.setupConcept = function(name) {
    if (!name) { return; }
    const concept = new CreativeConcept({name, mediaCampaignId: ctrl.mediaCampaign.id});
    ctrl.mediaCampaign.creativeConcepts.push(concept);
    return concept;
  };

  return this;
}
];

angular.module('kazaam.components')
  .component("s3uploaderMediaCampaignHelper", {
    controller: S3uploaderMediaCampaignHelperCtrl,
    templateUrl: 'components/s3uploader-media-campaign-helper.html',
    bindings: {
      clientId: '<',
      presetCampaignId: '<',
      onCampaignChange: '&',
      onConceptChange: '&'
    }
  });
