// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives')
  .directive('multiSelect', () =>
    ({
      restrict: 'AE',
      scope: {
        collection:         '=',
        selectedObj:        '=multiselectModel',
        collapsible:        '='
      },
      templateUrl: 'directives/multi-select.html',
      link(scope, elem, attrs) {
        const callback  = attrs.multiselectChange;
        const objKey    = attrs.multiselectObjectKey;

        // Helper to render the correct label
        scope.labelForElement = function(elm) {
          if (objKey) {
            return elm[objKey];
          } else {
            return elm;
          }
        };

        if (callback) {
          return scope.$watch('selectedObj', function(newVal, oldVal) {
            if (newVal !== oldVal) {
              return scope.$parent.$eval(callback);
            }
          }
          , true);
        }
      }
    })
  );
