// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.components')
  .component('mediaCampaignPageHeader', {
    templateUrl: 'media-campaigns/components/page-header.html',
    controller: ['$scope', '$state', function($scope, $state) {
      $scope.$state = $state;
      return this;
    }
    ],
    bindings: {
      title: '<',
      campaignId: '=',
      isNew: '<',
      onDelete: '&'
    }
  });

