// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('uiGridHelper', ['uiGridConstants', function(uigc) {
  return {
    // Implements a similar strategy to: https://github.com/angular-ui/ng-grid/issues/1735#issuecomment-61319601
    // Can be removed if the PR is ever resolved.
    calcHeight(tblData, padding) {
      if (padding == null) { padding = 10; }
      return (tblData.length * 30) + 30 + padding;
    },

    setHeight(tblOpts, api, padding, maxHeight) {
      if (padding == null) { padding = 10; }
      if (maxHeight == null) { maxHeight = undefined; }
      let height              = this.calcHeight(tblOpts.data, padding);
      if (maxHeight && (height > maxHeight)) {
        api.grid.options.enableVerticalScrollbar = uigc.scrollbars.ALWAYS;
        height = maxHeight;
      }

      api.grid.gridHeight = height;
      return height;
    },

    buildTable(tables, tableApis, tableHeights, dataSet, colDefs, tblIdx, extraOpts = {}) {
      const uigh = this;
      const registerCb = function(gridApi) {
        tableApis[tblIdx] = gridApi;
        tableHeights[tblIdx] = uigh.setHeight(tables[tblIdx], gridApi, extraOpts.padding, extraOpts.maxHeight);

        if (extraOpts.beginCellEdit && extraOpts.scope) {
          gridApi.edit.on.beginCellEdit(extraOpts.scope, extraOpts.beginCellEdit);
        }
        if (extraOpts.afterCellEdit && extraOpts.scope) {
          gridApi.edit.on.afterCellEdit(extraOpts.scope, extraOpts.afterCellEdit);
        }

        return gridApi;
      };

      const tblDef = {
        data:                 dataSet,
        columnDefs:           colDefs,
        onRegisterApi:        registerCb,

        // Until 'WHEN NEEDED' is back: https://github.com/angular-ui/ng-grid/pull/3100
        enableHorizontalScrollbar:  uigc.scrollbars.NEVER,
        enableVerticalScrollbar:    uigc.scrollbars.NEVER
      };
      tables[tblIdx] = tblDef;
      return tblDef;
    }
  };
}
]);
