// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('StatefulButton', ['$timeout', function($timeout) {
  let StatefulButton;
  return (StatefulButton = class StatefulButton {
    constructor(opts) {
      if (opts == null) { opts = {}; }
      this.stateLabels  = opts.stateLabels || {};
      this.resetState   = opts.resetState;
      this.resetTimeout = opts.resetTimeout || 5000;
      this._state       = opts.initState || "init";

      if (!this.validState(this._state)) {
        throw new Error(`Label for initial state '${this._state}' is not defined`);
      }

      this._initialState  = this._state;
      this.label          = this.stateLabels[this._state];
    }

    validState(state) { return this.stateLabels.hasOwnProperty(state); }
    setState(newState) {
      if (!this.validState(newState)) {
        throw new Error(`'${newState}' is not a valid state for this button.`);
      }

      // Change the state / update the label
      this._state = newState;
      this.label = this.stateLabels[newState];

      this.checkForReset();
      return this;
    }

    reset() { return this.setState(this._initialState); }

    checkForReset() {
      // This function looks to see if we're in the state designated for reset,
      // and if so, schedulers a reset via $timeout
      if (this._state === this.resetState) {
        $timeout( () => {
          return this.reset();
        }
        , this.resetTimeout);
      }
      return this;
    }
  });
}
]);

