// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.services')
  .factory('errorHandlerInterceptor', ['$q', '$rootScope', ($q, $rootScope) =>
    ({
      'response'(response) {
        if (response.status === 200) {
          $rootScope.$broadcast('response:noError');
        }
        return response;
      },
      'responseError'(rejection) {
        if ((rejection.status >= 400) && (rejection.status <= 500)) {
          $rootScope.$broadcast('response:responseError', rejection.data);
        }
        return $q.reject(rejection);
      }
    })
  
  ])
  .factory('layoutQSPInterceptor', ['$q', '$location', ($q, $location) =>
    ({
      'request'(config) {
        const apiRegexp = /\/(api|user_sessions)\//;
        const { layout } = $location.search();
        if (layout && apiRegexp.test(config.url)) {
          config.headers['X-Site-Layout'] = layout;
        }
        return config;
      }
    })
  
  ]);

angular.module('kazaam')
  .config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('errorHandlerInterceptor');
    return $httpProvider.interceptors.push('layoutQSPInterceptor');
  }
  ]);
