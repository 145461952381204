// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives').directive('creativeSetup', [ '$modal', $modal =>
  ({
    restrict:     'AE',
    transclude:   true,
    scope: {
      creatives:  '=ngModel',
      grouping:   '@',
      label:      '@'
    },
    templateUrl: 'analytics/creative-setup.html',
    controller: 'creativeSetupController',
    link(scope, elem, attrs) {
      return scope.openAnswerModal = function(ans) {
        //console.log('answer: ', ans)
        const modalInst = $modal.open({
          animation:    false, // We want 'true', but currently in hack+slash mode
          templateUrl:  'answer-meta-modal.html',
          controller:   'answerMetaModalCtrl',
          size:         'md',
          resolve: {
            answer() { return ans; }
          }
        });

        return modalInst.result.then( function(answer) {
          if (typeof attrs.callback !== 'undefined') {
            return scope.$apply(attrs.callback);
          } else {
            return answer;
          }
        });
      };
    }
  })


]);
