/*
 * decaffeinate suggestions:
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('MediaPlanLineItemPlacementDetail', ['RailsResource', (RailsResource) => {
  let MediaPlanLineItemPlacementDetail;

  return MediaPlanLineItemPlacementDetail = (() => {
    MediaPlanLineItemPlacementDetail = class MediaPlanLineItemPlacementDetail extends RailsResource {
      static initClass() {
        this.configure({url: '/api/media_plan_trafficker/{{mediaPlanId}}/activity', name: 'media_plan_line_item_placement_detail'});
      }
    };
    MediaPlanLineItemPlacementDetail.initClass();
    return MediaPlanLineItemPlacementDetail;
  })();
}
]);
