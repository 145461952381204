"use strict";

angular
  .module("kazaam.constants")
  .constant("AUDIENCE_FILE_CANONICAL_HEADERS", [
    /* XXX: These are all copies of backend values, do not change independently of ROR */
    "fname",
    "lname",
    "address1",
    "address2",
    "address3",
    "city",
    "state",
    "zip5",
    "zip4",
    "zip9",
    "country",
    "email",
    "sha1_email",
    "md5_email",
    "phone",
    "gender",
    "dob",
    "latitude",
    "longitude",
    "cong_district",
    "unique_id",
    "vantage_condition"
  ])
  .constant("CORRECTNESS_VALUES", [
    /* These can be changed independently */
    "correct",
    "neutral",
    "ignored",
    "incorrect"
  ])
  .constant("CHART_VIEWS", [
    "One way",
    "Net shift",
    "Multi-point scale"
    /* 'One way', 'Two way', 'Net shift', 'Multi-point scale' */
  ])
  .constant("DBM_SUBNAV", [
    {
      label: "Job Details",
      pathName: "trafficker.viewer",
      params: "",
      active: false
    },
    {
      label: "Targeting",
      pathName: "trafficker.targeting",
      params: "",
      active: false
    },
    {
      label: "Line Items",
      pathName: "trafficker.lineitems",
      params: "",
      active: false
    }
  ])
  .constant("DBM_SECONDARYNAV", [
    {
      label: "Jobs",
      pathName: "trafficker.list",
      params: "",
      active: false,
      icon: "icon-directions"
    },
    {
      label: "Add Job",
      pathName: "trafficker.new",
      params: "",
      active: false,
      icon: "icon-plus"
    },
    {
      label: "Saved Audiences",
      pathName: "trafficker.audience-builder",
      params: "",
      active: false,
      icon: "icon-people"
    }
  ])
  .constant("ACTIVATOR_SECONDARYNAV", [
    {
      label: "Audiences",
      pathName: "uploader.list",
      params: { visibility: "user" },
      active: false
    },
    {
      label: "Lebowski Audiences",
      pathName: "uploader.list",
      params: { visibility: "lebowski" },
      active: false
    },
    {
      label: "Snowflake",
      pathName: "activatorEtl.list",
      params: "",
      active: false
    }
  ])
  .constant("UPLOADER_SUBNAV", [
    {
      label: "Media Plan + Ad Uploader",
      pathName: "s3Uploader.uploader",
      params: "",
      active: false
    }
  ])
  .constant("MEDIA_CAMPAIGNS_SECONDARYNAV", [
    {
      label: "Media Plans",
      pathName: "mediaCampaigns.list",
      params: "",
      active: false,
      icon: "icon-screen-desktop"
    },
    {
      label: "New Plan",
      pathName: "mediaCampaigns.new",
      params: "",
      active: false,
      icon: "icon-plus"
    }
  ])
  .constant("MIN_SELECT_REFRESH", { length: "2" })
  .constant("VALID_DOLLARS", new RegExp("^[0-9]*(.[0-9]{1,2})?$"))
  .constant("VALID_INTEGER", new RegExp("^[0-9]*$"))
  .constant("jQuery", ["$window", ($window) => $window.$]);
