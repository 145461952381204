// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('TraffickerBuilderController',
  ['$scope', 'sheet', 'clients', 'DBM_SUBNAV', '$state', 'highChartsHelpers', 'Upload', '$q', 'DBM_SECONDARYNAV',
  function($scope, sheet, clients, DBM_SUBNAV, $state, hch, Upload, $q, DBM_SECONDARYNAV) {
    $scope.title        = $state.current.data.title;
    $scope.sheet        = sheet;
    $scope.clients      = clients;
    $scope.showSpinner  = false;
    $scope.subnav       = DBM_SUBNAV;
    $scope.secondaryNav = DBM_SECONDARYNAV;

    $scope.startUpload = function(sheet) {
      let method;
      if (sheet.isNew()) {
        method = 'POST';
      } else {
        method = 'PUT';
      }

      const uploads   = [];
      const files     = [sheet.excelSheet];
      const sheetData = sheet.serializedJson();
      for (let file of Array.from(files)) {
        sheetData["excel_sheet"] = file;
        uploads.push(
          Upload.upload({
            url:      sheet.$url(),
            method,
            data:     { 'dcm_sheet': sheetData }
          })
        );
      }
      return uploads;
    };

    $scope.destroySheet = sheet =>
      sheet.delete().then( () => $state.go('^.list'))
    ;

    $scope.saveSheet = function(sheet) {
      let prom;
      const wasNew = sheet.isNew();
      if (Upload.isFile(sheet.excelSheet)) {
        prom = $scope.startUpload(sheet)[0];
      } else {
        prom = sheet.save();
      }

      return prom.then( function(res) {
        let id;
        if (res.hasOwnProperty("data")) {
          // Request made via Upload.upload()
          ({ id } = res.data.dcm_sheet);
        } else {
          // Request made via DcmSheet.save()
          ({ id } = res);
        }

        if (wasNew) {
          $state.go('^.targeting', {sheetId: id});
        } else if (id) {
          $scope.showSpinner = false;
          $state.go('^.viewer', {sheetId: id});
        } else {
          $state.go('^.list');
        }
        return res;
      }
      , function(err) {
        $scope.showSpinner = false;
        if (typeof console !== 'undefined' && console !== null) {
          console.log("Error during upload: ", err);
        }
        return err;
      }
      , function(evt) {
        // Progress
        if (evt.config.data.file) {
          console.log(`progress: ${parseInt((100.0 * evt.loaded) / evt.total)}% file :${evt.config.data.file.name}`);
        }
        return evt;
      });
    };

    $scope.removeSheet = () => delete $scope.sheet.excelSheet;

    $scope.countNotReadyPlacementParams = function() {
      let rv = 0;
      for (let dpp of Array.from($scope.sheet.dcmPlacementParams)) {
        if (!dpp.sdfReady) {
          rv++;
        }
      }
      return rv;
    };

    $scope.countNotReadyLineItems = function() {
      let rv = 0;
      for (let item of Array.from($scope.sheet.dbmLineItems)) {
        if (!item.sdfReady) {
          rv++;
        }
      }
      return rv;
    };

    return true;
  }
]);
