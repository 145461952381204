// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

const stepperCtrl = ['$scope', '$state', function($scope, $state) {
  $scope.progressRuleWidth = Math.round(($scope.currentStep / ($scope.steps.length - 1)) * 100) + '%';

  return $scope.handleStepClick = function(step, index) {
    if (index >= $scope.currentStep) { return false; }
    return $state.go(step.state, $scope.params);
  };
}
];



const stepperDirective = [ () =>
    ({
      restrict: 'AE',
      templateUrl: 'directives/stepper.html',
      scope: {
        currentStep: '=',
        steps: '=',
        params: '='
      },
      controller: stepperCtrl
    })
  
  ];

angular.module('stepper', [])
  .directive('stepper', stepperDirective);
