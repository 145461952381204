// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('MediaPlanBuilderCtrl',
  ['$scope', 'plan', 'clients', 'netsuiteJobs', 'MediaPlanAudience', 'MediaPlanFee', 'MediaPlanAudienceFee', 'MediaPlanBucket', '$state', '$timeout', 'MEDIA_PLANNER_SECONDARYNAV', 'VALID_DOLLARS', 'VALID_INTEGER', 'currentUser',
  ($scope, plan, clients, netsuiteJobs, MediaPlanAudience, MediaPlanFee, MediaPlanAudienceFee, MediaPlanBucket, $state, $timeout, MEDIA_PLANNER_SECONDARYNAV, VALID_DOLLARS, VALID_INTEGER, currentUser) => {
    const ctrl        = this;
    this.title        = $state.current.data.title;
    this.plan         = plan;
    this.clients      = clients;
    this.netsuiteJobs = netsuiteJobs;
    this.currentUser  = currentUser;
    this.doWorkflow   = true;
    this.programTypes = ["Persuasion", "Mobilization", "GOTV"];
    this.raceTypes    = ["House", "Senate", "Governor", "Statewide", "State Legislature"];

    this.backSpinner      = false;
    this.showSpinner      = false;
    this.showSkipSpinner  = false;

    const today         = new Date();
    const tomorrow      = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const EARLIEST_POSSIBLE_DATE = new Date('2019-06-01T00:00:00');

    // XXX: These must match rails
    this.markupTypes        = ["Standard Commission", "No Commission"];
    this.buyingMarginTypes  = ["Flat CPM", "dCPM"];
    this.statuses           = ["draft", "approved", "archived"];
    this.feeTypes           = MediaPlanFee.feeTypes();
    this.audienceTypes      = MediaPlanAudience.audienceTypes();
    this.genders            = MediaPlanAudience.genders();
    this.audienceFeeTypes   = MediaPlanAudienceFee.feeTypes();
    this.audienceFeeRateTypes = MediaPlanAudienceFee.rateTypes();

    $scope.secondaryNav = MEDIA_PLANNER_SECONDARYNAV;
    $scope.validDollars = VALID_DOLLARS;
    $scope.validInteger = VALID_INTEGER;
    $scope.isDragging = false;

    this.workflowTransitions = {
      'mediaPlanner.new':       'mediaPlan.budgets',
      'mediaPlan.edit':         '^.budgets',
      'mediaPlan.budgets':      '^.audiences',
      'mediaPlan.audiences':    '^.lineItemStart'
    };

    if (this.clients.length === 1) {
      this.plan.clientId = this.clients[0].id;
    }

    this.nextWorkflowStep = function(plan, explicitState=undefined) {
      let targetState = 'mediaPlan.show';

      if (explicitState) {
        targetState = explicitState;
      } else if (ctrl.doWorkflow) {
        targetState = this.workflowTransitions[$state.current.name];
      }

      if (targetState) {
        return $state.go(targetState, {planId: plan.id});
      }
    };

    this.hideSpinner = () => { $timeout(() => {
      ctrl.backSpinner      = false;
      ctrl.showSpinner      = false;
      ctrl.showSkipSpinner  = false;
    }, 100); };

    this.savePlan = function(plan, transitionTo=undefined) {
      if (transitionTo) {
        ctrl.backSpinner = true;
      } else if (ctrl.doWorkflow) {
        ctrl.showSpinner = true;
      } else {
        ctrl.showSkipSpinner = true;
      }

      return plan.save().then( function(res) {
        ctrl.hideSpinner();
        ctrl.nextWorkflowStep(res, transitionTo);
        return res;
      }
      , function(err) {
        ctrl.hideSpinner();
        if (typeof console !== 'undefined' && console !== null) {
          console.log("Error saving: ", err);
        }
        return err;
      });
    };

    this.addAudience = (plan) => { plan.mediaPlanAudiences.push(new MediaPlanAudience({mediaPlanAudienceFees: []})); };
    this.addBucket = (plan) => { plan.mediaPlanBuckets.push(new MediaPlanBucket()); };
    this.addFee = (plan) => { plan.mediaPlanFees.push(new MediaPlanFee()); };
    this.addAudienceFee = (audience) => { audience.mediaPlanAudienceFees.push(new MediaPlanAudienceFee()); };

    this.markForDelete = function($event, obj, idx, baseObj=undefined) {
      $event.preventDefault();
      $event.stopPropagation();

      const objTypeCollections = {
        'MediaPlanAudience': 'mediaPlanAudiences',
        'MediaPlanBucket': 'mediaPlanBuckets',
        'MediaPlanFee': 'mediaPlanFees',
        'MediaPlanAudienceFee': 'mediaPlanAudienceFees',
      };

      baseObj ||= this.plan

      if (obj.isNew()) {
        const coll = baseObj[objTypeCollections[obj.constructor.name]];
        coll.splice(idx, 1);
      } else {
        obj.markForDelete();
      }

      // An audience marked for delete should ignore any fees also marked for delete
      if (obj.constructor.name === 'MediaPlanAudience') {
        angular.forEach(obj.mediaPlanAudienceFees, (fee) => { fee.undoMarkForDelete(); });
      }

      return obj;
    };

    this.undoMarkForDelete = function($event, obj) {
      $event.preventDefault();
      $event.stopPropagation();
      obj.undoMarkForDelete();
      return obj;
    };


    // Datepicker stuff
    this.dpFields = {
      format: 'MM/dd/yyyy',
      start: false,
      end: false,
      minDate: EARLIEST_POSSIBLE_DATE
    };
    $scope.openDatePopup = function($event, param, obj) {
      $event.preventDefault();
      $event.stopPropagation();
      // Reset any opened popups
      ctrl.plan.dpFields.start = false;
      ctrl.plan.dpFields.end = false;
      //Set the triggered popup
      return obj.dpFields[param] = true;
    };

    this.setupDpFields = function() {
      this.plan.dpFields = angular.copy(ctrl.dpFields);
    };

    this.setupDpFields();

    // Tagging stuff
    this.stripQuotes = newTag => newTag.replace(/"|'/g, '').replace(/^ +/, '').replace(/ +$/, '');

    this.getClientNameById = function(id) {
      const client = this.clients.find( item => item.id === id);
      return (client != null ? client.name : undefined);
    };

    // Audience linking
    /*
    this.setUploadedAudience = (event) => {
      console.log(`Set audience event: ${JSON.stringify(event)}`);
    };
    */

    return this;
  }
]);
