// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('AudienceQueryUploadMetadatum', ['RailsResource', 'railsSerializer', function(RailsResource, railsSerializer) {
  let AudienceUploadMetadatumQuery;
  const ser = railsSerializer( function() {
    this.resource('audienceQuery', 'AudienceQuery');
    this.exclude('audienceQuery');
    this.exclude('contentSample');
    this.exclude('createdAt');
    return this.exclude('updatedAt');
  });

  return AudienceUploadMetadatumQuery = (function() {
    AudienceUploadMetadatumQuery = class AudienceUploadMetadatumQuery extends RailsResource {
      static initClass() {
        this.configure({url: '/api/audience_query_upload_metadata', name: 'audience_query_upload_metadatum', pluralName: "audience_query_upload_metadata", serializer: ser});
  
        this.prototype.isFileBacked =   false;
        this.prototype.headerPresent =  false;
      }

      // Returns true/false depending if the headers have been set or not
      headersReady() { return (this.headerMap != null ? this.headerMap.length : undefined) > 0; }
      markForDelete() { return this._destroy = 1; }

      hasBadRecord() { return false; }
      invalidRate() { return 0.0; }

      name() { return this.audienceQuery.name; }

      refreshContentSample() {
        this.audienceQuery.runBeforeSave = true;
        return this.audienceQuery.save().then( aq => aq.result);
      }
    };
    AudienceUploadMetadatumQuery.initClass();
    return AudienceUploadMetadatumQuery;
  })();
}

]);
