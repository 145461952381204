// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('ActivatorEtlFieldManagerCtrl', ['$scope', 'etlJob', '$state', 'AUDIENCE_FILE_CANONICAL_HEADERS',
  function($scope, etlJob, $state, CANON_HEADERS) {
    $scope.title    = $state.current.data != null ? $state.current.data.title : undefined;
    $scope.etlJob   = etlJob;
    $scope.knownHeaders = [{id: "", name: "None"}];

    angular.forEach(CANON_HEADERS, h => $scope.knownHeaders.push({id: h, name: h}));

    $scope.save = () =>
      etlJob.save().then( job => $state.go("activatorEtl.viewer", {jobId: job.id}))
    ;

    // Listen for changes from the child scopes on the tags for the header names
    // @param: tagObj [object] of format {id: [string], name: [string]}
    $scope.$on('headerChange:new', (evt, tagObj) => $scope.knownHeaders.push(tagObj));

    return true;
  }
  ]);
