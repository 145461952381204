// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.services')
  .factory('XmlToJson', function() {

    var getNodes = function(nodes, nodeName) {
      const json = {};
      if (nodes.children.length) {
        for (let node of Array.from(nodes.children)) {
          const name = node.nodeName.toLowerCase();
          json[name] = getNodes(node, name);
        }
      } else {
        return nodes.textContent;
      }
      return json;
    };

    return {
      parseXml(xml) {
        if (typeof xml === 'string') {
          const parser = new DOMParser();
          const doc = parser.parseFromString(xml, "application/xml");
          return getNodes(doc);
        } else {
          throw ('Incorrect input type. Parser requires xml of type string.');
        }
      }
    };
  });
