// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives')
  .directive('tagList', () =>
    ({
      restrict: 'AE',
      templateUrl: 'directives/tag-list.html',
      scope: {
        tags: '='
      },
      link(scope, elem, attrs) {
        return scope.removeTag = function(tagText) {
          const tagIdx = scope.tags.indexOf(tagText);
          if (tagIdx > -1) {
            return scope.tags.splice(tagIdx, 1);
          }
        };
      }
    })
  );
