"use strict";

// Based on: https://github.com/angular-ui/ui-select/issues/2107#issue-296771596
angular.module('kazaam.directives').directive('tagOnBlur', ['$timeout', function ($timeout) {
  return {
    require: 'uiSelect',
    link: function (scope, elm, attrs, ctrl) {
      var addAndPickTag = function (newItem) {
        ctrl.searchInput.triggerHandler('tagged');
        if (ctrl.tagging.fct) {
          newItem = ctrl.tagging.fct(newItem);
        }
        if (newItem) {
          ctrl.select(newItem, true);
        }
      };

      ctrl.searchInput.on('blur', function () {
        // XXX: There's gotta be a better way to figure out if another choice is 
        // about to be selected.
        var hoverTarget = $(ctrl.$element[0]).find('.ui-select-choices-row:hover');
        if (!ctrl.tagging.isActivated || hoverTarget.length > 0) {
          return;
        }
        var newItem = ctrl.search;
        return addAndPickTag(newItem);
      });
    }
  };
}])
