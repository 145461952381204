// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('AudienceQuery', ['RailsResource', 'railsSerializer', function(RailsResource, railsSerializer) {
  let AudienceQuery;
  const ser = railsSerializer( function() {
    this.rename('result', 'audienceQueryResult');
    this.resource('result', 'AudienceQueryResult');
    this.resource('client', 'Client');
    this.exclude('client');
    this.exclude('client');
    this.exclude('creator');
    this.exclude('creatorId');
    this.exclude('createdAt');
    this.exclude('updatedAt');
    return this.exclude('reservedColumns');
  });

  return AudienceQuery = (function() {
    AudienceQuery = class AudienceQuery extends RailsResource {
      static initClass() {
        this.configure({url: '/api/audience_queries', name: 'audience_query', pluralName: "audience_queries", serializer: ser});
      }

      run() {
        const url = this.$url("/run");
        return this.$post(url);
      }
    };
    AudienceQuery.initClass();
    return AudienceQuery;
  })();
}

]);
