// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('DbmLineItem', ['RailsResource', 'railsSerializer', '$http', '$q', function(RailsResource, railsSerializer, $http, $q) {
  let DbmLineItem;
  const ser = railsSerializer( function() {
    this.preserve('settings');
    this.exclude('sdfReady');
    this.exclude('dcmPlacement');
    return this.exclude('dcmSheetId');
  });

  return DbmLineItem = (function() {
    DbmLineItem = class DbmLineItem extends RailsResource {
      static initClass() {
        this.configure({url: '/api/dbm_line_items', name: 'dbm_line_item', serializer: ser});
      }

      queryDbmMapping(term, mappingType, page) {
        if (mappingType == null) { mappingType = undefined; }
        if (page == null) { page = undefined; }
        if (!term || (term.length < 2)) {
          const deferred = $q.defer();
          deferred.resolve({data: []});
          return deferred.promise;
        }

        const url = this.$url('/dbm-mappings');
        return $http.get(url, {
          params: {
            search:       term,
            mapping_type: mappingType,
            page
          },
          headers: {
            Accept: 'application/json'
          }
        });
      }
    };
    DbmLineItem.initClass();
    return DbmLineItem;
  })();
}
]);
