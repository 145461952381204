// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives')
  .directive('tableFilter', ['$timeout', '$interval', 'friendlyStatus', '$stateParams',
  ($timeout, $interval, friendlyStatus, $stateParams)=>
    ({
      restrict: 'AE',
      scope: {
        fields:       '=',
        currentUser:  '=',
        statuses:     '=?'
      },
      templateUrl: 'directives/table-filter.html',
      link(scope, elem, attrs) {
        scope.showSpinner   = false;
        scope.showSelect    = false;
        if (!scope.statuses) { scope.statuses = friendlyStatus; }
        scope.supportsCreatorFilter = false;

        scope.filterFields  = [];
        angular.forEach(scope.fields, function(fld) {
          if (fld.enableFiltering) {
            scope.filterFields.push(fld);

            if (scope.currentUser && (fld.filterParam === "creator_email")) {
              return scope.supportsCreatorFilter = true;
            }
          }
        });

        let tmpFilterValue = '';
        if ($stateParams.hasOwnProperty('filterValue')) {
          tmpFilterValue = $stateParams.filterValue;
          if ($stateParams.hasOwnProperty('filterField') && ($stateParams.filterField === 'status')) {
            tmpFilterValue = friendlyStatus[$stateParams.filterValue];
          }
        }

        scope.filterOpts = {
          field: $stateParams.filterField || (scope.filterFields[0] != null ? scope.filterFields[0].filterParam : undefined),
          value: tmpFilterValue
        };

        scope.$watch('filterOpts.field', function(nV, oV) {
          if (nV !== oV) {
            // Clear out the value if the field is changed
            scope.filterOpts.value = '';
          }

          if (nV === 'status') {
            return scope.showStatusOpts = true;
          } else {
            return scope.showStatusOpts = false;
          }
        });

        if (scope.filterFields.length > 1) {
          scope.showSelect = true;
        }

        scope.startPolling = function() {
          scope.showSpinner = true;
          return scope.$emit('filterPolling:initiated', scope.filterOpts);
        };

        scope.$on('filterPolling:complete', function() {
          scope.showSpinner = !scope.showSpinner;
          return scope.$apply();
        });

        scope.filterByMyItems = function() {
          scope.filterOpts = {
            field: 'creator_email',
            value: scope.currentUser.email
          };
          return scope.startPolling();
        };

        return scope.clearFilter = function() {
          scope.filterOpts = {
            field: (scope.filterFields[0] != null ? scope.filterFields[0].filterParam : undefined),
            value: ''
          };

          return scope.$emit('clearFilter');
        };
      }
    })
  

  ]);
