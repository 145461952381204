'use strict';

angular.module('kazaam.models').factory('MediaProductAdServingFeeSchedule', ['RailsResource', 'railsSerializer', (RailsResource, railsSerializer) => {
  let MediaProductAdServingFeeSchedule;
  const ser = railsSerializer( function() {
    this.exclude('createdAt');
    this.exclude('updatedAt');
  });

  return MediaProductAdServingFeeSchedule = (() => {
    MediaProductAdServingFeeSchedule = class MediaProductAdServingFeeSchedule extends RailsResource {
      static initClass() {
        this.configure({url: '/api/media_product_ad_serving_fee_schedules', name: 'media_product_ad_serving_fee_schedule', serializer: ser});
      }
    };
    MediaProductAdServingFeeSchedule.initClass();
    return MediaProductAdServingFeeSchedule;
  })();
}
]);
