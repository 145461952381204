// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('UploaderFieldManagerCtrl', ['$scope', 'audience', 'vendors', '$state', 'AUDIENCE_FILE_CANONICAL_HEADERS',
  function($scope, audience, vendors, $state, CANON_HEADERS) {
    $scope.title    = $state.current.data != null ? $state.current.data.title : undefined;
    $scope.audience = audience;
    $scope.audience.hydrateVendors(vendors);
    $scope.knownHeaders = [{id: "", name: "None"}];

    // For PK matches, we only want to offer the unique_id column
    if (audience.matchPlan.primaryKeyMatch) {
      $scope.knownHeaders.push({id: "unique_id", name: "unique_id"});
    } else {
      angular.forEach(CANON_HEADERS, h => $scope.knownHeaders.push({id: h, name: h}));
    }

    $scope.save = () =>
      audience.save().then( aud =>
        aud.analyzeFiles().then( () => $state.go("uploader.viewer", {audienceId: aud.id}))
      )
    ;

    // Listen for changes from the child scopes on the tags for the header names
    // @param: tagObj [object] of format {id: [string], name: [string]}
    $scope.$on('headerChange:new', (evt, tagObj) => $scope.knownHeaders.push(tagObj));

    return true;
  }
  ]);
