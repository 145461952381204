// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('MediaPlanLineItemUploaderCtrl',
  ['$scope', 'plan', 'sheet', 'audiences', 'buckets', '$state', '$timeout', 'MEDIA_PLANNER_SECONDARYNAV', 'Upload', 'currentUser', 'MediaProduct',
  ($scope, plan, sheet, audiences, buckets, $state, $timeout, MEDIA_PLANNER_SECONDARYNAV, Upload, currentUser, MediaProduct) => {
    const ctrl        = this;
    this.title        = $state.current.data.title;
    this.plan         = plan;
    this.sheet        = sheet;
    this.audiences    = audiences;
    this.buckets      = buckets;
    this.showSpinner  = false;
    this.showDestroySpinner  = false;

    this.audienceNames  = this.audiences.map( obj => obj.name ).sort();
    this.bucketNames    = this.buckets.map( obj => obj.name ).sort();

    $scope.secondaryNav = MEDIA_PLANNER_SECONDARYNAV;

    this.hideSpinner = () => { $timeout(() => {
      ctrl.showSpinner = false;
      ctrl.showDestroySpinner = false;
    }, 250); };

    this.workflowTransitions = {
      'mediaPlanLineItemUploader.new':      '^.mapping',
      'mediaPlanLineItemUploader.mapping':  '^.review',
      'mediaPlanLineItemUploader.review':   'mediaPlan.show'
    };

    this.nextWorkflowStep = function(id, planId) {
      const targetState = this.workflowTransitions[$state.current.name];
      if (targetState) {
        return $state.go(targetState, {id: id, planId: planId});
      }
    };

    this.startUpload = function(sheet) {
      let method;
      if (sheet.isNew()) {
        method = 'POST';
      } else {
        method = 'PUT';
      }

      const uploads   = [];
      const files     = [sheet.file];
      const sheetData = sheet.serializedJson();
      for (let file of Array.from(files)) {
        sheetData["file"] = file;
        uploads.push(
          Upload.upload({
            url:      sheet.$url(),
            method,
            data:     { 'media_plan_uploaded_line_item_sheet': sheetData }
          })
        );
      }
      // XXX: this should be: Promise.all(uploads)
      return uploads;
    };

    this.removeSheet = () => delete ctrl.sheet.file;

    this.refreshProducts = ($event) => {
      let { search } = $event;
      return MediaProduct.query({filter_field: 'name', filter_value: search, page: 'all'}).then((res) => {
        let productNames = res.map( obj => `${obj.dcmSite.name} - ${obj.name}` ).sort();
        return productNames;
      });
    };

    this.approveSheet = (sheet) => {
      return sheet.approve().then( () => $state.go('mediaPlan.show', {planId: ctrl.plan.id}));
    };

    this.destroySheet = (sheet) => {
      return sheet.delete().then( () => $state.go('mediaPlan.show', {planId: ctrl.plan.id}));
    };

    this.saveSheet = function(sheet) {
      ctrl.showSpinner = true;
      let prom;
      const wasNew = sheet.isNew();
      if (Upload.isFile(sheet.file)) {
        prom = ctrl.startUpload(sheet)[0];
      } else {
        prom = sheet.save();
      }

      return prom.then( function(res) {
        let id, planId;
        if (res.hasOwnProperty("data")) {
          // Request made via Upload.upload()
          id = res.data.media_plan_uploaded_line_item_sheet.id;
          planId = res.data.media_plan_uploaded_line_item_sheet.media_plan_id;
        } else {
          // Request made via DcmSheet.save()
          id = res.id;
          planId = res.mediaPlanId;
        }

        // ctrl.hideSpinner();
        ctrl.nextWorkflowStep(id, planId);
        return res;
      }, function(err) {
        ctrl.hideSpinner();
        if (typeof console !== 'undefined' && console !== null) {
          console.log("Error during upload: ", err);
        }
        return err;
      }, function(evt) {
        // Progress
        if (evt.config.data.file) {
          console.log(`progress: ${parseInt((100.0 * evt.loaded) / evt.total)}% file :${evt.config.data.file.name}`);
        }
        return evt;
      });
    };

    this.applyRules = ($event) => {
      let { rules, ruleKey } = $event;
      this.sheet.renamingRules[ruleKey] = rules;
    };

    return this;
  }
]);
