'use strict';

angular.module('kazaam.models').factory('MediaPlanLineItemBudgetSegment', ['RailsResource', 'railsSerializer', 'DatepickerFriendlyDateInterceptor', '$filter', (RailsResource, railsSerializer, dpfdInterceptor, $filter) => {
  let MediaPlanLineItemBudgetSegment;

  const dateNormalizer = (dt) => {
    // dt is either a string or a date
    if (dt.constructor == Date) {
      return dt.toISOString().replace(/T.+/, '');
    } else if (dt.constructor == String && dt.match(/^\d\d\/\d\d\/\d\d\d\d$/)) {
      // E.g. 09/03/2020 -> 2020-09-03
      return new Date(dt).toISOString().replace(/T.+/, '');
    }

    return dt;
  }

  const ser = railsSerializer( function() {
    this.exclude('mediaPlanLineItem');
    this.exclude('dpFields');
    this.exclude('estimatedClicks');
    this.exclude('createdAt');
    this.exclude('updatedAt');
    this.add('startOn', (obj) => { return dateNormalizer(obj.startOn); });
    this.add('endOn', (obj) => { return dateNormalizer(obj.endOn); });
  });

  // XXX: This must match Rails
  const _standardComm = "Standard Commission";

  return MediaPlanLineItemBudgetSegment = (() => {
    MediaPlanLineItemBudgetSegment = class MediaPlanLineItemBudgetSegment extends RailsResource {
      static initClass() {
        this.configure({url: '/api/media_plan_line_item_budget_segments', name: 'media_plan_line_item_budget_segment',
          serializer: ser,
          interceptors: [{
            response: (result, ctor, context) => {
              const fields = ["startOn", "endOn"];
              return dpfdInterceptor(result, ctor, context, fields);
            }
          }]
        });
      }

      markForDelete() { return this._destroy = 1; }
      undoMarkForDelete() { return delete this._destroy; }

      hydrate(lineItem) {
        this.mediaPlanLineItem = lineItem;
        // We really need to move beyond ui-bootstrap 0.12.2
        this.startOnOriginal = this.startOn;
        this.endOnOriginal = this.endOn;
        this.startOnUTC = Date.convertDateToUTC(new Date(this.startOn));
        this.endOnUTC = Date.convertDateToUTC(new Date(this.endOn));
        this.startOn = $filter('date')(Date.convertDateToUTC(new Date(this.startOn)), 'MM/dd/yyyy', 'UTC');
        this.endOn = $filter('date')(Date.convertDateToUTC(new Date(this.endOn)), 'MM/dd/yyyy', 'UTC');
      }

      // XXX: This is duplicated from the ROR method on MediaPlanLineItem, changes must match what's there.
      calcNetBudget() {
        if (!this.mediaPlanLineItem || !this.mediaPlanLineItem.mediaPlan) {
          // We hit this situation after a save but before the page transition.  Ignore it for now
          return undefined;
        }

        if (!this.budget) {
          return 0;
        }

        if (this.mediaPlanLineItem.mediaPlan.markupType === _standardComm) {
          return (Number(this.budget) * (1 - this.mediaPlanLineItem.effectiveCommission() / 100.0));
        } else {
          return Number(this.budget);
        }
      }
    };
    MediaPlanLineItemBudgetSegment.initClass();
    return MediaPlanLineItemBudgetSegment;
  })();
}
]);
