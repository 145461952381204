// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('ActivatorEtlListController', ['$scope', '$filter', 'uiGridConstants', 'uiGridHelper', '$state', '$log', 'currentPage', 'etlJobs', 'pageMeta', 'friendlyEtlStatus', 'ACTIVATOR_SECONDARYNAV',
  function($scope, $filter, uigc, uigh, $state, $log, currentPage, etlJobs, pageMeta, friendlyEtlStatus, secondaryNav) {
    $scope.secondaryNav = secondaryNav;
    $scope.listItems    = [];
    $scope.title        = $state.current.data.title;
    $scope.chartTitle   = $state.current.data.chartTitle;
    $scope.friendlyEtlStatus = friendlyEtlStatus;

    // Ui-grid stuff
    $scope.tables       = [];
    $scope.tblApis      = [];
    $scope.tblHeights   = [];
    $scope.listItems    = etlJobs;

    // Pagination
    $scope.currentPage  = currentPage;
    $scope.itemsPerPage = pageMeta.per_page;
    $scope.totalItems   = pageMeta.total_records;

    const buildTable = function($scope, dataSet, tblIdx) {
      const colDefs = [
        {displayName: "Client",         field: "client.name", enableFiltering: true, filterParam: "client_name"},
        {displayName: "Job",            field: "name", cellTemplate: "grid-cell-report-link.html", enableFiltering: true, filterParam: "name"},
        {displayName: "Records",        field: "rowCount", cellFilter: "number"},
        {displayName: "Status",         field: "friendlyStatus()", cellFilter: "humanize", enableFiltering: true, filterParam: "status"},
        {displayName: "Date",           field: "createdAt", cellFilter: "date"}
      ];

      const registerCb = function(gridApi) {
        $scope.tblApis[tblIdx]    = gridApi;
        return $scope.tblHeights[tblIdx] = uigh.setHeight($scope.tables[tblIdx], gridApi);
      };

      const tblDef = {
        data:                 dataSet,
        columnDefs:           colDefs,
        onRegisterApi:        registerCb,

        // Until 'WHEN NEEDED' is back: https://github.com/angular-ui/ng-grid/pull/3100
        enableHorizontalScrollbar:  uigc.scrollbars.NEVER,
        enableVerticalScrollbar:    uigc.scrollbars.NEVER
      };
      return $scope.tables[tblIdx] = tblDef;
    };

    $scope.$on('filterPolling:initiated', function(evt, data) {
      if (data.field === 'status') {
        const tmp = Object.keys(friendlyEtlStatus).filter(key => friendlyEtlStatus[key] === data.value.toLowerCase());
        data.value = tmp[0];
      }

      $state.go('.', {filterField: data["field"], filterValue: data["value"], page: 1});
      // This is stupid, but if there are no changes to the filtering fields and the user clicks the filter button again
      // the spinner inside the button just runs continuously. State is never actually changed so it doesn't know to reset
      // the spinner. So we need to send a complete task in that instance to tell the spinner to stop. Without a timeout the
      // event gets sent immediately so it looks like nothing happened.
      return setTimeout( () => $scope.$broadcast('filterPolling:complete')
      , 700);
    });

    $scope.$on('clearFilter', evt => $state.transitionTo($state.current, {}, {reload: true}));

    $scope.loadPageNum = page => $state.go('.', {page: $scope.currentPage});

    return buildTable($scope, $scope.listItems , 0);
  }

  ]);
