// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('SimpleListController', ['$scope', 'listItems', 'overviewCtrlHelper', '$state',
  function($scope, listItems, och, $state) {
    $scope.listItems    = listItems;
    $scope.title        = $state.current.data.title;
    $scope.chartTitle   = $state.current.data.chartTitle;

    const stateTableOpts      = {
      includeResponses:   $state.current.data.includeResponses
    };

    // Ui-grid stuff
    $scope.tables       = [];
    $scope.tblApis      = [];
    $scope.tblHeights   = [];

    $scope.tables[0]    = och.buildTable($scope, $scope.listItems, 0, stateTableOpts);
    return true;
  }
  ]);
