// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

/*
This directive aims to support the DcmPlacementParam and DbmTargetAudience
objects by giving the user DbmResource objects to choose from.

Ultimately these land in SDF files that go to Google's ad platform.
Most objects only support an array of objects.  One supports some
basic logic which we represent as an array of arrays.

SUPPORTED BY SDF:
(A or B) AND (C or D)
(A) AND (B) AND (C)
(A or B or C)

data: [A, B, C]
includes_join_op: AND or OR
if AND, then we output (A) AND (B) AND (C)
if OR, then we output (A or B or C)

data: [[A, B], [C]
includes_join_or: *forced* to OR
output: (A or B) AND (c)

NOT SUPPORTED BY SDF:
(A AND B) or (C AND D)
*/

const traffickerDbmResourceSelect = ['$scope', 'DbmResource', '$q', 'MIN_SELECT_REFRESH', function($scope, DbmResource, $q, MIN_SELECT_REFRESH) {
  $scope.helpers = {
    disabled: undefined,
    searchResults: [],
    placeholderLabel: $scope.placeholderLabel
  };

  if (!$scope.resourceType && $scope.dcmPlacementParam) {
    $scope.resourceType = $scope.dcmPlacementParam.paramType;
  }

  if ((!$scope.hasOwnProperty("supportsLogicTree") || !$scope.supportsLogicTree) && $scope.dcmPlacementParam) {
    $scope.supportsLogicTree = $scope.dcmPlacementParam.supportsLogicTree($scope.mappingType, $scope.settingsKey);
  }

  if (!$scope.generalSettings && $scope.dcmPlacementParam) {
    $scope.generalSettings = $scope.dcmPlacementParam.mappingSettings($scope.mappingType);
  }

  // Pagination stuff
  const loadingItem   = {id: "loading", name: 'Loading...', isLoadingItem: true};
  const hasNextChunk  = true;
  let searchTerm    = '';
  let nextPage      = 2;

  const addLoadingItem    = () => $scope.helpers.searchResults.push(loadingItem);
  const removeLoadingItem = function() {
    const idx = $scope.helpers.searchResults.indexOf(loadingItem);
    if (idx >= 0) {
      $scope.helpers.searchResults.splice(idx, 1);
    }
    return true;
  };

  const getInfiniteScrollChunk = function(pageNum) {
    if (pageNum == null) { pageNum = 1; }
    const queryParams = {};
    if ($scope.sheet) {
      queryParams.dcm_sheet_id = $scope.sheet.id;
    }

    return DbmResource.search(searchTerm, $scope.resourceType, $scope.mappingType, pageNum, queryParams).then( response => response.data.dbm_mappings);
  };

  $scope.helpers.refreshList = function(search) {
    searchTerm  = search.length ? search : '';
    nextPage    = 2;
    if (searchTerm.length >= MIN_SELECT_REFRESH.length) {
      return getInfiniteScrollChunk().then( results => $scope.helpers.searchResults = results || []
      , err => $q.reject(err));
    }
  };
  // End pagination related

  $scope.helpers.isSelectOpen = function(isOpen, $select) {
    if (!isOpen) {
      return $select.search = '';
    }
  };

  $scope.helpers.searchResultLabel = function(item) {
    if (!item) {
      // XXX: I was able to trigger this manually once, but then not again... :(
      if (typeof console !== 'undefined' && console !== null) {
        console.log("Unexpected undefined item in search results, using undefined label");
      }
      return undefined;
    } else if (item.hasOwnProperty("country_code")) {
      return `${item.canonical_name}, Type: ${item.target_type} (${item.id})`;
    } else {
      return `${item.name} (${item.id})`;
    }
  };

  //
  // There appears to be a ui-select bug whereby using $scope.currentSettings
  // directly doesn't propagate changes.  I suspect something is breaking the
  // reference chain inside ui-select, but its not clear what/where.
  //
  $scope.localData = {data: angular.copy($scope.currentSettings)};
  $scope.$watch("localData", function(newV, oldV) {
    if (oldV === newV) { return; }
    return angular.copy(newV.data, $scope.currentSettings);
  }
  , true);

  $scope.$watch('currentSettings', function(newV, oldV) {
    if (oldV === newV) { return; }
    return angular.copy(newV, $scope.localData.data);
  }
  , true);

  $scope.$on('dbmResourceSelector:reset', function() {
    // Don't wait for the next cycle, just clear them both immediately.
    $scope.localData.data.clear();
    return $scope.currentSettings.clear();
  });

  return $scope.$on('dbmResourceSelector:changed', () => angular.copy($scope.currentSettings, $scope.localData.data));
}
];

angular.module('kazaam.directives').directive('traffickerDbmResourceSelect', [ () =>
  ({
    restrict:     'AE',
    transclude:   true,
    scope: {
      currentSettings:      '=settings',
      placeholderLabel:     '=',
      sheet:                '=',
      dcmPlacementParam:    '=',
      mappingType:          '=',
      settingsKey:          '=',
      resourceType:         '=?',
      supportsLogicTree:    '=?',
      generalSettings:      '=?'
    },
    templateUrl: 'directives/trafficker-dbm-resource-select.html',
    controller: traffickerDbmResourceSelect
  })

]);
