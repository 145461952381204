// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

// Originally From http://eric.sau.pe/angularjs-detect-enter-key-ngenter/ and
// https://gist.github.com/EpokK/5884263#gistcomment-1847192
//
// This directive allows us to pass a function in on an enter key to do what we want.
//
angular.module('kazaam.directives').directive('ngEnter', [ () =>
  ({
    restrict: "A",
    scope: {
      action: "&ngEnter"
    },
    link(scope, element, attrs) {
      return element.on("keydown keypress", function(event) {
        if (event.which === 13) {
          scope.$apply(scope.action);
          return event.preventDefault();
        }
      });
    }
  })

]);
