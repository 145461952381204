// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives')
  .directive('helperTooltip', ['$compile', $compile =>
    ({
      restrict: 'AE',
      scope: {
        content:    '=',
        direction:  '='
      },
      transclude:   true,
      templateUrl:  'directives/helper-tooltip.html',
      compile(cElem, cAttrs) {
        let link;
        const elementLinker = $compile();

        return link = function(scope, elem, attrs) {
          const $elem           = $(elem);
          const { direction }       = scope;
          const $container      = $(elem).find('.helper-tooltip-container');
          const containerHeight = $container.height();
          const containerWidth  = $container.width();

          const positionElem = function() {
            if (direction === 'left') {
              $container.css({
                'top': '50%',
                'left': -(containerWidth + 34) + 'px'
              });
              return $(elem).find('.helper-arrow').addClass('arrow-right');
            } else if (direction === 'bottom') {
              $container.css({
                'bottom': -(containerHeight + 34) + 'px',
                'left':   -(containerWidth / 2) + 'px'
              });
              return $(elem).find('.helper-arrow').addClass('arrow-top');
            } else if (direction === 'right') {
              $container.css({
                'top':  '50%',
                'right': -(elemWidth + 34) + 'px'
              });
              return $(elem).find('.helper-arrow').addClass('arrow-left');
            } else {
              $container.css({
                'top':  -(containerHeight + 34) + 'px',
                'left': -(containerWidth / 2) + 'px'
              });
              return $(elem).find('.helper-arrow').addClass('arrow-bottom');
            }
          };

          let to1 = undefined;
          let to2 = undefined;
          return $elem.hover( function() {
            clearTimeout(to1);
            clearTimeout(to2);
            $elem.find('.helper-tooltip-container').addClass('show');
            return positionElem();
          }
          , function() {
            const $htc = $elem.find('.helper-tooltip-container');
            return to1 = setTimeout( function() {
              $htc.removeClass('show');

              return to2 = setTimeout( () => $htc.removeAttr('style')
              , 1000);
            }
            , 700);
          });
        };
      }
    })
  

  ]);
