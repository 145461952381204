// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
'use strict';

angular.module('kazaam.models').factory('oktaAuthClient', ['$window', function($window) {
  if ($window.oktaAuthParams) {
    const client = new OktaAuth($window.oktaAuthParams);
    return {client, params: $window.oktaAuthParams, supported: true};
  } else {
    return {supported: false};
  }
}
]);
