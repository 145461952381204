// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('startEndDatepickerCtrl', ['$scope', '$filter', function($scope, $filter) {
  $scope.format   = 'MM/dd/yyyy';
  $scope.utcDates = [];
  const mKey            = $scope.modelKey;

  if (!mKey) {
    throw new Error("No 'model-key' specified, won't be able to update the settings object.");
  }

  const initSettings = function(settings) {
    // Datepicker settings
    const sDates = settings[mKey];

    if (!settings.hasOwnProperty("dateRange")) {
      settings.dateRange = {};
    }

    // Convert our dates to UTC
    for (let rawDay of Array.from($scope.rawDates)) {
      rawDay = new Date(rawDay);
      const utc = $filter('date')(rawDay, 'MM/dd/yyyy', 'UTC');
      $scope.utcDates.push(utc);
    }

    settings.minDate = $scope.utcDates[0];
    settings.maxDate = $scope.utcDates[$scope.utcDates.length - 1];

    const invertObj = function(obj) {
      const inverted = {};
      for (let k in obj) {
        const v = obj[k];
        inverted[v] = k;
      }
      return inverted;
    };

    if (sDates && (sDates.length > 0)) {
      let dtStart = sDates[0];
      let dtEnd   = sDates[ sDates.length - 1 ];
      if ($scope.dateMap) {
        // invert the dateMap and figure out the dates for the currently selected values
        const valMap  = invertObj($scope.dateMap);
        dtStart = valMap[ dtStart ];
        dtEnd   = valMap[ dtEnd ];
      }

      settings.dateRange.start = $filter('date')(dtStart, 'MM/dd/yyyy', 'UTC');
      settings.dateRange.end   = $filter('date')(dtEnd, 'MM/dd/yyyy', 'UTC');
    } else {
      settings.dateRange.start = $filter('date')($scope.utcDates[0], 'MM/dd/yyyy', 'UTC');
      settings.dateRange.end   = $filter('date')($scope.utcDates[$scope.utcDates.length - 1], 'MM/dd/yyyy', 'UTC');
      updateModel();
    }

    return settings;
  };

  $scope.disabledDates = function(date, mode) {
    const day = $filter('date')(date, 'MM/dd/yyyy', 'UTC');
    return (mode === 'day') && ($scope.utcDates.indexOf(day) === -1);
  };

  var updateModel = function() {
    const settings  = $scope.chartSettings;
    const dStartIdx = $scope.utcDates.indexOf($filter('date')(settings.dateRange.start, 'MM/dd/yyyy', 'UTC'));
    const dEndIdx   = $scope.utcDates.indexOf($filter('date')(settings.dateRange.end, 'MM/dd/yyyy', 'UTC'));
    if ($scope.dateMap) {
      const newVal = [];
      for (let dt of Array.from($scope.rawDates.slice(dStartIdx, dEndIdx + 1))) {
        newVal.push( $scope.dateMap[dt] );
      }
      return settings[mKey] = newVal;
    } else {
      return settings[mKey] = $scope.rawDates.slice(dStartIdx, dEndIdx + 1);
    }
  };

  $scope.doChange = function() {
    updateModel();
    if ($scope.onChange && ($scope.form != null ? $scope.form.$valid : undefined)) {
      return $scope.$eval($scope.onChange);
    } else {
      return undefined;
    }
  };

  // Kick off / setup everything
  return initSettings($scope.chartSettings);
}
]);
