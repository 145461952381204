// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('overviewCtrlHelper', ['uiGridConstants', 'uiGridHelper', (uiGridConstants, uigh) =>
  ({
    buildTable($scope, reportSet, tblIdx, opts) {
      let tblDef;
      if (opts == null) { opts = {}; }
      const colDefs = [
        {displayName: "Name",       field: "name", cellTemplate: "grid-cell-report-link.html"},
        {displayName: "Client",     field: "client.name"},
        {displayName: "Created",    field: "createdAt", cellFilter: "date:'fullDate':'UTC'"}
      ];

      if (!opts.hasOwnProperty("includeResponses") || (opts["includeResponses"] !== false)) {
        colDefs.push( {displayName: "Responses",  field: "formatResponses()", enableCellEdit: false} );
      }

      const registerCb = function(gridApi) {
        $scope.tblApis[tblIdx]    = gridApi;
        return $scope.tblHeights[tblIdx] = uigh.setHeight($scope.tables[tblIdx], gridApi);
      };

      return tblDef = {
        data:                 reportSet,
        columnDefs:           colDefs,
        onRegisterApi:        registerCb,

        // Until 'WHEN NEEDED' is back: https://github.com/angular-ui/ng-grid/pull/3100
        enableHorizontalScrollbar:  uiGridConstants.scrollbars.NEVER,
        enableVerticalScrollbar:    uiGridConstants.scrollbars.NEVER
      };
    }

  })

]);

