// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Filter to convert an array to a string via 'join', from:
// https://stackoverflow.com/a/32406961/845546
'use strict';

angular.module('kazaam.filters').filter('join', [() =>
  function(array, sep, prop) {
    if (!Array.isArray(array)) {
      return array;
    } else {
      if (!!prop) {
        return array.map(item => item[prop]).join(sep);
      } else {
        return array.join(sep);
      }
    }
  }

]);
