// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('DcmSheet', ['RailsResource', 'railsSerializer', 'Upload', function(RailsResource, railsSerializer, Upload) {
  let DcmSheet;
  const ser = railsSerializer( function() {
    this.resource('client', 'Client');
    this.resource('dcmCreatives', 'DcmCreative');
    this.nestedAttribute('dcmCreatives');
    this.resource('dcmPlacementParams', 'DcmPlacementParam');
    this.nestedAttribute('dcmPlacementParams');
    this.resource('dbmLineItems', 'DbmLineItem');
    this.nestedAttribute('dbmLineItems');
    this.exclude('client');
    this.exclude('creator');
    this.exclude('creatorId');
    this.exclude('createdAt');
    this.exclude('updatedAt');
    return this.exclude('excelSheet'); // ng-file-upload will handle this
  });

  return DcmSheet = (function() {
    DcmSheet = class DcmSheet extends RailsResource {
      static initClass() {
        this.configure({url: '/api/dcm_sheets', name: 'dcm_sheet', serializer: ser});
      }

      // Use our serializer to dump out the data we'd pass to a HTTP req.
      // This is necessary to get saving w/ uploads working
      serializedJson() {
        const serializer = new ser();
        return serializer.serialize(this);
      }

      sheetFilename() {
        if (!this.excelSheet) {
          return "";
        } else if (Upload.isFile(this.excelSheet)) {
          // File pending upload
          return this.excelSheet.name;
        } else if (this.excelSheet.hasOwnProperty("name")) {
          // From ROR-land with name + url
          return this.excelSheet.name;
        } else {
          return "";
        }
      }

      sheetUrl() {
        if ((this.excelSheet != null ? this.excelSheet.hasOwnProperty("url") : undefined)) {
          return this.excelSheet.url;
        } else {
          return undefined;
        }
      }

      sdfStatus() {
        if (this.sdfReady) {
          return "Ready";
        } else {
          return "Preview";
        }
      }
    };
    DcmSheet.initClass();
    return DcmSheet;
  })();
}
]);
