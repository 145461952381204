// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('TraffickerAudienceBuilderController',
  ['$scope', '$state', '$stateParams', 'DBM_SUBNAV', 'currentPage', 'audiences', 'pageMeta', 'DBM_SECONDARYNAV', 'DbmTargetedAudience',
    function($scope, $state, $stateParams, DBM_SUBNAV, currentPage, audiences, pageMeta, DBM_SECONDARYNAV, DbmTargetedAudience) {
      $scope.title        = $state.current.data.title;
      $scope.currentPage  = currentPage;
      $scope.itemsPerPage = pageMeta.per_page;
      $scope.totalItems   = pageMeta.total_records;
      $scope.loadPageNum  = () => $state.go('.', {page: $scope.currentPage});
      $scope.secondaryNav = DBM_SECONDARYNAV;
      $scope.newAudience  = $stateParams.new ? JSON.parse($stateParams.new.toLowerCase()) : undefined;

      $scope.savedAudiences   = audiences;
      $scope.emptyAudience    = null;

      // Handle the initial case when we have no audiences.  Just show the form then
      if (audiences.length === 0) {
        $scope.newAudience = true;
      }

      const savedAudienceAction = function(event, audience, wasNew) {
        if (audience && wasNew) {
          $scope.savedAudiences.unshift(audience);
          return resetEmptyAudience();
        }
      };

      $scope.$on('savedAudienceAction:success', savedAudienceAction);

      var resetEmptyAudience = () =>
        $scope.emptyAudience = new DbmTargetedAudience({
          name: '',
          dbmSettings: {
            excludes: [],
            includes: [[]],
            includes_join_op: 'and'
          }
        })
      ;
      return resetEmptyAudience();
    }

  ]
);
