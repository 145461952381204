// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

const EtlPageCtrl = ['$scope', '$state', function($scope, $state) {
  $scope.resetJob = etlJob =>
    etlJob.reset().then( job => $state.go("activatorEtl.viewer", {jobId: job.id}, {reload: true}))
  ;

  return $scope.destroy = job =>
    job.delete().then( () => $state.go("activatorEtl.list"))
  ;
}
];

angular.module('kazaam.directives')
  .directive('etlPageControls', [ '$stateParams', $stateParams =>
    ({
      restrict: 'AE',
      scope: {
        etlJob: '='
      },
      templateUrl: 'activator-etl/page-controls.html',
      controller: EtlPageCtrl
    })
  
  ]);
