// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

// Borrowing heavily from:
// https://github.com/angular-ui/ui-select/pull/1058/commits/b25a50b77bb0a7b84c32c98d0c30e97f12258697
angular.module('kazaam.directives')
  .directive('selectAll', ['$templateCache', '$compile', '$timeout', ($templateCache, $compile, $timeout) =>
    ({
      restrict: 'A',
      //require: ['^uiSelectMultiple']
      controller: ['$scope', function($scope) {
        // The 2 functions below make use of controllers injected into our scope by
        // other ui-select directives.  Those controllers may get injected after
        // our first run, so reference them by their scope path (e.g. $scope.$select)
        // rather than by local references.
        $scope.selectAll = function(e) {
          e.preventDefault();
          e.stopPropagation();
          $scope.$select.resetSearchInput = true;
          const selectItems = angular.extend([], $scope.$select.items, $scope.$select.selected);
          $scope.$select.selected = selectItems;
          $scope.$select.items = [];
          $scope.$select.search = '';
          return $scope.$selectMultiple.updateModel();
        };

        return $scope.clearAll = function(e) {
          e.preventDefault();
          e.stopPropagation();
          $scope.$select.selected.clear();
          $scope.$selectMultiple.updateModel();
          return $scope.$select.resetSearchInput = false;
        };
      }
      ],
      compile(tElem, tAttrs) {
        return function(scope, elem, attrs) {
          const template = $templateCache.get('directives/select-all-ui-select-wrapper.html');
          const compiledTpl = $compile(template)(scope);

          if (angular.isDefined(attrs.multiple)) {
            return $timeout( () => elem.find('.ui-select-choices').prepend(compiledTpl));
          }
        };
      }
    })
  
  ]);
