'use strict';

angular.module('kazaam.models').factory('MediaPlanAudienceFee', ['RailsResource', 'railsSerializer', (RailsResource, railsSerializer) => {
  let MediaPlanAudienceFee;
  const ser = railsSerializer( function() {
    this.exclude('mediaPlanAudienceId');
    this.exclude('createdAt');
    this.exclude('updatedAt');
  });

  const _feeTypes = [
    'Agenda Setter Data',
    'TargetSmart Data'
  ];
  const _rateTypes = [
    'CPM',
    'Fixed',
    'Audience Size (per 1k)',
  ];

  return MediaPlanAudienceFee = (() => {
    MediaPlanAudienceFee = class MediaPlanAudienceFee extends RailsResource {
      static initClass() {
        this.configure({url: '/api/media_plan_audience_fees', name: 'media_plan_audience_fee', serializer: ser});
      }

      static feeTypes() { return _feeTypes; }
      static rateTypes() { return _rateTypes; }

      markForDelete() { return this._destroy = 1; }
      undoMarkForDelete() { return delete this._destroy; }
      toBeDestroyed() { return this._destroy === 1; }
    };
    MediaPlanAudienceFee.initClass();
    return MediaPlanAudienceFee;
  })();
}
]);
