// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

const DEFAULT_OPERATORS = {
  operators: [ 'and', 'or' ]
};
const FORCED_OPERATOR = DEFAULT_OPERATORS.operators[1];

const queryBuilderCtrl = ['$scope', function($scope) {
  if (!$scope.options) {
    $scope.options = angular.extend({}, DEFAULT_OPERATORS, $scope.options);
  } else {
    $scope.options = DEFAULT_OPERATORS;
  }

  $scope.operatorKey = 'includes_join_op';
  if (!$scope.generalSettings[$scope.operatorKey]) { $scope.generalSettings[$scope.operatorKey] = "and"; } // Just pick a sane default

  $scope.addCondition     = () => $scope.queryData.push([]);
  $scope.removeCondition  = index => $scope.queryData.splice(index, 1);

  if ($scope.queryData.length === 0) {
    $scope.addCondition();
  }

  return $scope.$watch('queryData', function(conds) {
    if (conds.length > 1) {
      $scope.generalSettings[$scope.operatorKey] = FORCED_OPERATOR;
      return $scope.forcedOperator = true;
    } else {
      return $scope.forcedOperator = false;
    }
  }
  , true);
}
];

const queryPreviewCtrl = ['$scope', function($scope) {
  $scope.format = function(allElements, oper) {
    let rv;
    if (!Array.isArray(allElements) || (allElements.length === 0) || (allElements[0].length === 0)) {
      return "";
    }

    if (oper === 'and') {
      rv = allElements[0].map( el => el.name).join('<br><strong>AND</strong><br>');
    } else {
      rv = allElements.map( function(elms) {
        const names = elms.map( el => el.name);
        return `(${names.join("&nbsp;<strong>OR</strong>&nbsp;")})`;
      }).join("<br><strong>AND</strong><br>");
    }

    return rv;
  };

  $scope.totalElementsCount = $scope.elements.reduce( ((count, list) => count + list.length), 0 );
  $scope.preview = $scope.format($scope.elements, $scope.operator);
  $scope.previewCollapsed = true;

  $scope.$watch('elements', function(newV, oldV) {
    if (angular.equals(newV, oldV)) { return; }
    if (newV) {
      $scope.preview = $scope.format(newV, $scope.operator);
      return $scope.totalElementsCount = newV.reduce( ((count, list) => count + list.length), 0 );
    } else {
      return $scope.preview = "";
    }
  }
  , true);

  return $scope.$watch('operator', function(newV, oldV) {
    if (newV === oldV) { return; }
    return $scope.preview = $scope.format($scope.elements, newV);
  });
}
];

const queryPreview = [() =>
  ({
    restrict: 'AE',
    scope: {
      elements: '=queryPreview',
      operator: '='
    },
    templateUrl: 'query-builder/query-preview.html',
    controller: queryPreviewCtrl
  })

];

const queryBuilder = [() =>
  ({
    restrict:   'AE',
    transclude: true,
    scope: {
      options:          '=?queryBuilder',
      helpers:          '=',
      queryData:        '=',
      generalSettings:  '='
    },
    templateUrl:   'query-builder/query-builder.html',
    controller: queryBuilderCtrl
  })

];

angular.module('queryBuilder', [])
  .directive('queryBuilder', queryBuilder)
  .directive('queryPreview', queryPreview);
