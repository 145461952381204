'use strict';

// Ok, this isn't purely an interceptor, but its the function that does the work to make
// our API dates work correctly via jquery datepicker / JS date objects.
// See https://stackoverflow.com/a/31338499/845546

angular.module('kazaam.models').factory('DatepickerFriendlyDateInterceptor', () => {
  const dateReplaceHelperFn = (obj, fields) => {
    angular.forEach(fields, (field) => {
      if (obj[field]) {
        obj[field] = obj[field].replace(/-/g, '/');
      }
    });
    return obj;
  };

  const interceptorFn = (result, resourceConstructor, context, fields) => {
    if (Array.isArray(result["data"])) {
      angular.forEach(result["data"], (obj) => {
        dateReplaceHelperFn(obj, fields);
      });
    } else {
      dateReplaceHelperFn(result["data"], fields);
    }
    return result;
  };
  return interceptorFn;
});

