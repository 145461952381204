'use strict';

angular.module('kazaam.models').factory('ActivatorStandardizedJob', ['RailsResource', function(RailsResource) {
  let ActivatorStandardizedJob;
  return ActivatorStandardizedJob = (function() {
    ActivatorStandardizedJob = class ActivatorStandardizedJob extends RailsResource {
      static initClass() {
        this.configure({url: '/api/activator_standardized_jobs', name: 'activator_standardized_job'});
      }
    };
    ActivatorStandardizedJob.initClass();
    return ActivatorStandardizedJob;
  })();
}
]);
