// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('analyticsChartWidgetCtrl',
  ['$scope', 'uiGridConstants', 'uiGridHelper',
  function($scope, uiGridConstants, uigh) {
    // Variables and such

    $scope.resultsTblApi  = undefined;
    $scope.resultsTblOpts = {
      data: $scope.resultsData,
      enableFiltering: true,
      enableSorting: true,
      minRowsToShow: 50,
      columnDefs: [
        { name: 'survey_question',  displayName: 'Survey Question' },
        { name: 'dimension',        displayName: 'Dimension' },
        { name: 'effect_size',      displayName: 'Effect Size' },
        { name: 'sample_size',      displayName: 'Sample Size' },
        { name: 'p_value',          displayName: 'P Value' },
        { name: 'category',         displayName: 'Category' },
        { name: 'category_level',   displayName: 'Category Level' },
        { name: 'analysis_type',    displayName: 'Analysis Type' }
      ],
      enableGridMenu:       true,
      exporterCsvFilename:  `${$scope.report.name}-analytics-table-${$scope.$id}.csv`,
      exporterMenuPdf:      false,
      enableHorizontalScrollbar:  uiGridConstants.scrollbars.NEVER
      //enableVerticalScrollbar:    uiGridConstants.scrollbars.NEVER
    };
    // End variables


    $scope.removeClick = () => $scope.$eval($scope.onRemove);
    return $scope.cloneClick  = () => $scope.$eval($scope.onClone);
  }
]);
