'use strict';

angular.module('kazaam').config(['$stateProvider', '$locationProvider',
  function($stateProvider, $locationProvider) {
    $stateProvider
      .state('mediaPlanner', {
        abstract: true,
        parent: 'abstract-root',
        template: '<ui-view />'
      }
      )
      .state('mediaPlanner.list', {
        url:          "/media-planner?:page&:filterField&:filterValue",
        templateUrl:  "media-planner/list.html",
        controller:   "FilterableListController",
        resolve: {
          gridColumnDefs:   [ () => [
            {displayName: "Client",           field: "client.name", enableFiltering: true, filterParam: "client_name"},
            {displayName: "Plan Name",        field: "name", cellTemplate: "grid-cell-report-link.html", enableFiltering: true, filterParam: "name"},
            {displayName: "NetSuite Job",     field: "netsuiteJobAndId()", enableFiltering: true, filterParam: "netsuite_id"},
            {displayName: "Last Updated",     field: "updatedAt", cellFilter: "date"},
            {displayName: "Creator",          field: "creator.email", enableFiltering: true, filterParam: "creator_email"}
          ]  ],
          currentPage:    ['$stateParams', function($stateParams) { if (typeof $stateParams.page !== 'undefined') { return $stateParams.page; } else { return 1; } } ],
          plansFull:  ['MediaPlan', '$stateParams', 'currentPage', function(MediaPlan, $stateParams, currentPage) {
            const opts = {};
            opts.page = currentPage;

            // Filtering params
            if (typeof $stateParams.filterField !== 'undefined') { opts.filterField = $stateParams.filterField; }
            if (typeof $stateParams.filterValue !== 'undefined') { opts.filterValue = $stateParams.filterValue; }

            // Need to set full response to true here so that we can get the meta data. This is false by default.
            // IMPORTANT: remember to reset it to false after the query call to ensure no blowups further down the tree
            MediaPlan.config.fullResponse = true;
            return MediaPlan.query(opts).then( function(results) {
              MediaPlan.config.fullResponse = false;
              return results;
            });
          }
          ],
          listItems:    ['plansFull', plansFull => plansFull.data ],
          pageMeta:     ['plansFull', plansFull => plansFull.originalData.meta ],
          secondaryNav: ['MEDIA_PLANNER_SECONDARYNAV', nav => nav]
        },
        data: {
          title:      "Media Plans"
        }
      }
      )
      .state('mediaPlanner.archive', {
        url:          "/media-planner/archive?:page&:filterField&:filterValue",
        templateUrl:  "media-planner/list.html",
        controller:   "FilterableListController",
        resolve: {
          gridColumnDefs:   [ () => [
            {displayName: "Client",           field: "client.name", enableFiltering: true, filterParam: "client_name"},
            {displayName: "Plan Name",        field: "name", cellTemplate: "grid-cell-report-link.html", enableFiltering: true, filterParam: "name"},
            {displayName: "NetSuite Job",     field: "netsuiteJobAndId()", enableFiltering: true, filterParam: "netsuite_id"},
            {displayName: "Last Updated",     field: "updatedAt", cellFilter: "date"},
            {displayName: "Creator",          field: "creator.email", enableFiltering: true, filterParam: "creator_email"}
          ]  ],
          currentPage:    ['$stateParams', function($stateParams) { if (typeof $stateParams.page !== 'undefined') { return $stateParams.page; } else { return 1; } } ],
          plansFull:  ['MediaPlan', '$stateParams', 'currentPage', function(MediaPlan, $stateParams, currentPage) {
            const opts = {status: "archived"};
            opts.page = currentPage;

            // Filtering params
            if (typeof $stateParams.filterField !== 'undefined') { opts.filterField = $stateParams.filterField; }
            if (typeof $stateParams.filterValue !== 'undefined') { opts.filterValue = $stateParams.filterValue; }

            // Need to set full response to true here so that we can get the meta data. This is false by default.
            // IMPORTANT: remember to reset it to false after the query call to ensure no blowups further down the tree
            MediaPlan.config.fullResponse = true;
            return MediaPlan.query(opts).then( function(results) {
              MediaPlan.config.fullResponse = false;
              return results;
            });
          }
          ],
          listItems:    ['plansFull', plansFull => plansFull.data ],
          pageMeta:     ['plansFull', plansFull => plansFull.originalData.meta ],
          secondaryNav: ['MEDIA_PLANNER_SECONDARYNAV', nav => nav]
        },
        data: {
          title:      "Archived Media Plans"
        }
      }
      )
      .state('mediaPlanner.favorites', {
        url:          "/media-planner/favorites?:page&:filterField&:filterValue",
        templateUrl:  "media-planner/list.html",
        controller:   "FilterableListController",
        resolve: {
          gridColumnDefs:   [ () => [
            {displayName: "Client",           field: "client.name", enableFiltering: true, filterParam: "client_name"},
            {displayName: "Plan Name",        field: "name", cellTemplate: "grid-cell-report-link.html", enableFiltering: true, filterParam: "name"},
            {displayName: "NetSuite Job",     field: "netsuiteJobAndId()", enableFiltering: true, filterParam: "netsuite_id"},
            {displayName: "Last Updated",     field: "updatedAt", cellFilter: "date"},
            {displayName: "Creator",          field: "creator.email", enableFiltering: true, filterParam: "creator_email"}
          ]  ],
          currentPage:    ['$stateParams', function($stateParams) { if (typeof $stateParams.page !== 'undefined') { return $stateParams.page; } else { return 1; } } ],
          plansFull:  ['MediaPlan', '$stateParams', 'currentPage', function(MediaPlan, $stateParams, currentPage) {
            const opts = {favorites: true};
            opts.page = currentPage;

            // Filtering params
            if (typeof $stateParams.filterField !== 'undefined') { opts.filterField = $stateParams.filterField; }
            if (typeof $stateParams.filterValue !== 'undefined') { opts.filterValue = $stateParams.filterValue; }

            // Need to set full response to true here so that we can get the meta data. This is false by default.
            // IMPORTANT: remember to reset it to false after the query call to ensure no blowups further down the tree
            MediaPlan.config.fullResponse = true;
            return MediaPlan.query(opts).then( function(results) {
              MediaPlan.config.fullResponse = false;
              return results;
            });
          }
          ],
          listItems:    ['plansFull', plansFull => plansFull.data ],
          pageMeta:     ['plansFull', plansFull => plansFull.originalData.meta ],
          secondaryNav: ['MEDIA_PLANNER_SECONDARYNAV', nav => nav]
        },
        data: {
          title:      "Favorite Media Plans"
        }
      }
      )
      .state('mediaPlanner.new', {
        // This state *must* come before <route>.viewer, as above
        url:          "/media-planner/new",
        controller:   "MediaPlanBuilderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-planner/setup.html",
        resolve: {
          clients:        ['Client',    Client => Client.query({page: "all"})],
          netsuiteJobs:   ['NetsuiteJob', NetsuiteJob => NetsuiteJob.query({page: "all"})],
          plan:           ['MediaPlan', MediaPlan => new MediaPlan({mediaPlanFees: []})]
        },
        data: {
          title:      "Create & Upload Media Plan"
        }
      }
      )
      .state('mediaPlan', {
        abstract: true,
        parent: 'abstract-root',
        template: '<ui-view />',
        resolve: {
          plan:         ['MediaPlan', '$stateParams', (MediaPlan, $stateParams) => MediaPlan.get($stateParams.planId)],
          clients:      ['plan',                      plan => [plan.client]],
          netsuiteJobs: ['NetsuiteJob',               NetsuiteJob => []]
        }
      }
      )
      .state('mediaPlan.show', {
        url:          "/media-planner/:planId",
        controller:   "MediaPlanShowCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-planner/show.html",
        resolve: {
          products:     ['MediaProduct', '$stateParams', (MP, $sp) => MP.query({page: 'all', planId: $sp.planId})],
          lineItems:    ['MediaPlanLineItem', '$stateParams', (MPLI, $sp) => MPLI.query({planId: $sp.planId, page: 'all'})]
        }
      }
      )
      .state('mediaPlan.edit', {
        url:          "/media-planner/:planId/edit",
        controller:   "MediaPlanBuilderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-planner/setup.html",
        resolve: {
          clients:        ['Client',    Client => Client.query({page: "all"})],
          netsuiteJobs:   ['NetsuiteJob', NetsuiteJob => NetsuiteJob.query({page: "all"})]
        },
        data: {
          title:      "Edit Media Plan"
        }
      }
      )
      .state('mediaPlan.budgets', {
        url:          "/media-planner/:planId/budgets",
        controller:   "MediaPlanBuilderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-planner/budgets.html",
        data: {
          title:      "Budgets"
        }
      }
      )
      .state('mediaPlan.audiences', {
        url:          "/media-planner/:planId/audiences",
        controller:   "MediaPlanBuilderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-planner/audiences.html",
        data: {
          title:      "Create / Edit Audiences"
        }
      }
      )
      .state('mediaPlan.lineItemStart', {
        url:          "/media-planner/:planId/line-item-start",
        controller:   "MediaPlanBuilderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-planner/line-items/upload-or-create.html",
        data: {
          title:      "Create Line Items"
        }
      }
      )
      .state('mediaPlanLineItemUploader', {
        abstract: true,
        parent: 'abstract-root',
        template: '<ui-view />',
        resolve: {
          plan:       ['MediaPlan', '$stateParams', (MediaPlan, $stateParams) => MediaPlan.get($stateParams.planId)],
          audiences:  ['plan', plan => plan.mediaPlanAudiences],
          buckets:    ['plan', plan => plan.mediaPlanBuckets],
          products:   [ () => [] ]
        }
      }
      )
      .state('mediaPlanLineItemUploader.new', {
        url:          "/media-planner/:planId/line-item-uploader/new",
        controller:   "MediaPlanLineItemUploaderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-planner/line-item-uploader/start.html",
        resolve: {
          sheet:      ['MediaPlanUploadedLineItemSheet', '$stateParams', (MPULS, $sp) => new MPULS({mediaPlanId: $sp.planId})],
        },
        data: {
          title:      "Bulk Load Line Items"
        }
      }
      )
      .state('mediaPlanLineItemUploader.mapping', {
        url:          "/media-planner/:planId/line-item-uploader/:id/fields",
        controller:   "MediaPlanLineItemUploaderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-planner/line-item-uploader/mapping.html",
        resolve: {
          sheet:      ['MediaPlanUploadedLineItemSheet', '$stateParams', (MPULS, $sp) => MPULS.get($sp.id)],
        },
        data: {
          title:      "Map Line Item Fields / Values"
        }
      }
      )
      .state('mediaPlanLineItemUploader.review', {
        url:          "/media-planner/:planId/line-item-uploader/:id/review",
        controller:   "MediaPlanLineItemUploaderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-planner/line-item-uploader/review.html",
        resolve: {
          sheet:      ['MediaPlanUploadedLineItemSheet', '$stateParams', (MPULS, $sp) => MPULS.get($sp.id)]
        },
        data: {
          title:      "Review Bulk Upload"
        }
      }
      )
      .state('mediaPlanLineItem', {
        abstract: true,
        parent: 'abstract-root',
        template: '<ui-view />',
        resolve: {
          plan:         ['MediaPlan', '$stateParams', (MediaPlan, $stateParams) => MediaPlan.get($stateParams.planId)],
          audiences:    ['plan', plan => plan.mediaPlanAudiences],
          buckets:      ['plan', plan => plan.mediaPlanBuckets],
        }
      }
      )
      .state('mediaPlanLineItem.new', {
        url:          "/media-planner/:planId/line-item/new",
        controller:   "MediaPlanLineItemBuilderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-planner/line-items/editor.html",
        resolve: {
          lineItem:   ['MediaPlanLineItem', MediaPlanLineItem => new MediaPlanLineItem({mediaPlanLineItemBudgetSegments: []})],
          products:   [ () => [] ], // Empty array
          versions:   [ () => [] ],
        },
        data: {
          title:      "Create Line Item"
        }
      }
      )
      .state('mediaPlanLineItem.edit', {
        url:          "/media-planner/:planId/line-item/:id/edit",
        controller:   "MediaPlanLineItemBuilderCtrl",
        controllerAs: "$ctrl",
        templateUrl:  "media-planner/line-items/editor.html",
        resolve: {
          lineItem:   ['MediaPlanLineItem', '$stateParams', (MPLI, $sp) => MPLI.get($sp.id)],
          product:    ['MediaProduct', 'lineItem', (MediaProduct, li) => MediaProduct.get(li.mediaProductId)],
          products:   ['product', (product) => [product]],
          versions:   ['MediaPlanLineItemVersion', '$stateParams', (Version, $sp) => Version.query({}, {lineItemId: $sp.id})],
        },
        data: {
          title:      "Edit Line Item"
        }
      }
      )
      .state('mediaPlanTrafficker', {
        abstract:     true,
        parent:       'abstract-root',
        template:     '<ui-view />',
        resolve: {
          plan:       ['MediaPlan', '$stateParams', (MediaPlan, $stateParams) => MediaPlan.get($stateParams.planId)],
        }
      })
      .state('mediaPlanTrafficker.start', {
        url:          '/media-planner/:planId/trafficker',
        controller:   'MediaPlanTraffickerCtrl',
        controllerAs: '$ctrl',
        templateUrl:  'media-planner/trafficker/start.html',
        resolve: {
          traffickPlan: ['PlacementTraffickingPlan', '$stateParams', (PTP, $sp) => PTP.get($sp.planId)],
          activity:     [() => undefined],
        },
        data: {
          title:      'Trafficker'
        }
      })
      .state('mediaPlanTrafficker.activity', {
        url:          '/media-planner/:planId/trafficker/activity',
        controller:   'MediaPlanTraffickerCtrl',
        controllerAs: '$ctrl',
        templateUrl:  'media-planner/trafficker/activity.html',
        resolve: {
          traffickPlan: [() => undefined],
          activity:     ['MediaPlanLineItemPlacementDetail', '$stateParams', (PD, $sp) => PD.query({}, {mediaPlanId: $sp.planId})],
        },
        data: {
          title:      'Trafficker Activity'
        }
      });
  }
]);
