// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('ActivatorEtlBuilderCtrl', ['$scope', 'etlJob', 'stdJobs', 'clients', '$state', 'highChartsHelpers', 'SnowflakeTableLister',
  function($scope, etlJob, stdJobs, clients, $state, hch, SnowflakeTableLister) {
    $scope.title      = $state.current.data != null ? $state.current.data.title : undefined;
    $scope.etlJob     = etlJob;
    $scope.stdJobs    = stdJobs;
    $scope.clients    = clients;

    $scope.newTable   = '';

    // Ensure we have a null option if we have any jobs at all
    if (stdJobs.length > 0) {
      $scope.stdJobs.unshift({id: null, name: "None"});
    }

    // The keys used here must match the 'origin' field returned by the backend serializer
    $scope.queryLoadOptions = [
      { id: "exavault",   name: "FTP" },
      { id: "s3",         name: "Vantage File Transfer" }
    ];

    etlJob.hydratePaths();

    // Default to FTP, but if we have queries selected and no paths, use that
    if (etlJob.selectedPaths.exavault.length > 0) {
      $scope.queryLoadType = $scope.queryLoadOptions[0];
    } else if (etlJob.selectedPaths.s3.length > 0) {
      $scope.queryLoadType = $scope.queryLoadOptions[1];
    } else {
      $scope.queryLoadType = $scope.queryLoadOptions[0];
    }

    $scope.saveJob = etlJob =>
      etlJob.save().then( job => $state.go("activatorEtl.fields", {jobId: job.id}))
    ;

    $scope.headerState = function(path) {
      // Returns the text describing the state of headers for an uploaded file.
      const jf = etlJob.fileByPath(path);
      if (jf && jf.headersReady()) {
        return "Ready";
      } else {
        return "Not Ready";
      }
    };

    $scope.refreshList = function(search) {
      const searchTerm  = search.length ? search : '';
      if (searchTerm.length >= 2) {
        return SnowflakeTableLister.search(searchTerm).then( function(results) {
          $scope.searchResults = results;
          return $scope.searchResults;
        }
        , err => $q.reject(err));
      }
    };

    //$scope.debugMe = ->
    //  console?.log("Scope id: ", $scope.id)
    //  debugger
    return true;
  }
  ]);
