// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives')
  .directive('subnav', [ '$stateParams', $stateParams =>
    ({
      restrict: 'AE',
      scope: {
        navlist: '='
      },
      templateUrl: 'directives/subnav.html',
      link(scope, elem, attrs) {
        return Array.from(scope.navlist).map((tab) =>
          (tab.path = tab.pathName + '(' + JSON.stringify($stateParams) + ')'));
      }
    })
  
  ]);
