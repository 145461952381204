'use strict';

// Use .toPrecision to round numbers to a specified precision
angular.module('kazaam.filters').filter('dollars', ['numberFilter', (numberFilter) =>
  function(num, digits=0) {
    if (num === null || num === undefined) {
      return '';
    } else if (isNaN(num)) {
      return `$${num}`;
    } else {
      return `$${numberFilter(num, digits)}`;
    }
  }
]);
