'use strict';

angular.module('kazaam.models').factory('MediaPlanFee', ['RailsResource', 'railsSerializer', (RailsResource, railsSerializer) => {
  let MediaPlanFee;
  const ser = railsSerializer( function() {
    this.exclude('mediaPlanId');
    this.exclude('createdAt');
    this.exclude('updatedAt');
  });

  const _feeTypes = [
    "Ad Serving",
    "Creative Production",
    "Data Licensing",
    "Vantage Testing",
    "Ad Shipping",
    "Onboarding",
    "Other"
  ];

  return MediaPlanFee = (() => {
    MediaPlanFee = class MediaPlanFee extends RailsResource {
      static initClass() {
        this.configure({url: '/api/media_plan_fees', name: 'media_plan_fee', serializer: ser});
      }

      static feeTypes() { return _feeTypes; }

      markForDelete() { return this._destroy = 1; }
      undoMarkForDelete() { return delete this._destroy; }
      toBeDestroyed() { return this._destroy === 1; }
    };
    MediaPlanFee.initClass();
    return MediaPlanFee;
  })();
}
]);
