// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('TraffickerTargetingController', ['$scope', 'sheet', 'dpps', 'creatives', 'DBM_SUBNAV', '$state', 'DBM_SECONDARYNAV', 'audiences',
  function($scope, sheet, dpps, creatives, DBM_SUBNAV, $state, DBM_SECONDARYNAV, audiences) {
    $scope.title                  = $state.current.data.title;
    $scope.sheet                  = sheet;
    $scope.creatives              = creatives;
    $scope.targetingParamsByType  = {};
    $scope.subnav                 = DBM_SUBNAV;
    $scope.secondaryNav           = DBM_SECONDARYNAV;
    $scope.savedAudiences         = audiences;

    dpps.sort( function(a,b) {
      const aK = a.sortKey();
      const bK = b.sortKey();
      if (aK < bK) {
        return -1;
      } else if (aK === bK) {
        return 0;
      } else {
        return 1;
      }
    });

    // Set defaults and build targetingParamsByType
    angular.forEach(dpps, function(targetingParam) {
      targetingParam.setDefaults();

      // Build targetingParamsByType
      if ($scope.targetingParamsByType.hasOwnProperty(targetingParam.paramType)) {
        return $scope.targetingParamsByType[targetingParam.paramType].push(targetingParam);
      } else {
        return $scope.targetingParamsByType[targetingParam.paramType] = [targetingParam];
      }
    });

    $scope.friendlyNames = {
      aud_targeting:    "Unique Audience",
      campaign_name:    "Campaign Name",
      campaign_type:    "DBM",
      client:           "Client Name",
      device_type:      "Unique Environment Type",
      geo_targeting:    "Unique Geo",
      site_name:        "Unique Site",
      site_section:     "Targeting Type",
      media_type:       "Media",
      size:             "Size",
      frequency_bucket: "Frequency Bucket"
    };

    const friendlySettings = {
      aud_targeting: {
        default:        "UserList Targeting",
        demo_gender:    "Google Demographic Targeting - Gender",
        demo_age:       "Google Demographic Targeting - Age"
      }
    };

    $scope.settingsKeyLabel = function(dpp, mt, sKey) {
      const label = friendlySettings[dpp.paramType] != null ? friendlySettings[dpp.paramType][mt] : undefined;

      if (label) {
        return `${label} - ${sKey.capitalize()}`;
      } else if (mt === 'default') {
        return sKey.capitalize();
      } else {
        return `${sKey.capitalize()} - ${mt.capitalize()}`;
      }
    };

    $scope.saveSheet = sheet =>
      sheet.save().then( function(res) {
        $state.go('^.lineitems', {sheetId: sheet.id});
        return res;
      }
      , function(err) {
        if (typeof console !== 'undefined' && console !== null) {
          console.log("Error during upload: ", err);
        }
        return err;
      })
    ;

    $scope.loadAudience = function(keys, audience, mappingType, paramType) {
      if (!keys.length) { return false; }

      const dbmMappings = $scope.targetingParamsByType[paramType][0].dbmSettings[mappingType];
      const audMappings = audience.dbmSettings;
      return Array.from(keys).map((key) =>
        !Array.isArray(dbmMappings[key][0]) && !Array.isArray(audMappings[key][0]) ?
          (dbmMappings[key] = dbmMappings[key].concat(dedupeMappings(dbmMappings[key], audMappings[key])))
        :
          Array.from(audMappings[key]).map((audMap, idx) =>
            dbmMappings[key][idx] === undefined ?
              dbmMappings[key].push(audMap)
            :
              (dbmMappings[key][idx] = dbmMappings[key][idx].concat(dedupeMappings(dbmMappings[key][idx], audMap)))));
    };

    var dedupeMappings = (dbm, aud) =>
      aud.filter( function(a) {
        const dupe = dbm.find( b => a.id === b.id);
        return (dupe === undefined);
      })
    ;

    // Need some way of wholeslae swapping out the trafficker-dbm-resource-selector widget
    $scope.useResourceSelector = function(dpp, mt, sKey) {
      if ((dpp.paramType === "aud_targeting") && (mt === "demo_age")) {
        return false;
      } else {
        return true;
      }
    };

    $scope.useAgeRange = function(dpp, mt, sKey) {
      if ((dpp.paramType === "aud_targeting") && (mt === "demo_age")) {
        return true;
      } else {
        return false;
      }
    };

    return true;
  }
]);
