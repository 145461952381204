// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('Dtv2Placement', ['RailsResource', '$q', function(RailsResource, $q) {
  let Dtv2Placement;
  return Dtv2Placement = (function() {
    Dtv2Placement = class Dtv2Placement extends RailsResource {
      static initClass() {
        this.configure({url: '/api/dtv2_placements', name: 'dtv2_placement'});
      }

      static search(term, page, extra_opts) {
        if (page == null) { page = undefined; }
        if (extra_opts == null) { extra_opts = {}; }
        if (!term || (term.length < 2) || !extra_opts.hasOwnProperty("dtv2_campaign_id")) {
          const deferred = $q.defer();
          deferred.resolve([]);
          return deferred.promise;
        }

        const params = angular.merge({
          search:         term,
          page
        }, extra_opts);

        return this.query(params);
      }
    };
    Dtv2Placement.initClass();
    return Dtv2Placement;
  })();
}
]);
