// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('currentSite', ['$window', 'RailsResource', function($window, RailsResource) {
  class Site extends RailsResource {
    static initClass() {
      this.configure({url: '/api/sites', name: 'site'});
    }
    hasAllFeatures() { return this.capabilities.features.indexOf("all") !== -1; }
    hasFeature(feature) {
      if (feature === 'adminMenu') {
        return true;
      }

      return this.hasAllFeatures() || (this.capabilities.features.indexOf(feature) !== -1);
    }
  }
  Site.initClass();

  return new Site($window.VantageSiteJson);
}
]);
