// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('ActivatorQueryBuilderController',
  ['$scope', 'query', 'clients', 'AudienceQuery', 'myQueries', '$state', 'uiGridConstants', '$timeout', 'page',
  async function($scope, query, clients, AudienceQuery, myQueries, $state, uigc, $timeout, page) {
    let idx;
    $scope.title                    = $state.current.data.title;
    $scope.query                    = query;
    $scope.clients                  = clients;
    $scope.myQueries                = myQueries;
    $scope.currentPage              = page;
    $scope.showSpinner              = { saveQuery: false };
    $scope.showLibrary              = false
    $scope.tagVoterfileOptions      = [
      { label: "-- Select an option --", value: '', disabled: true },
      { label: "Yes", value: true, disabled: false },
      { label: "No", value: false, disabled: false }
    ];

    const loadLibraryQueries = async (curPage) => {
      AudienceQuery.config.fullResponse = true;
      const opts = {filterField: "bookmarked", filterValue: true, page: curPage};
      const results = await AudienceQuery.query(opts);
      AudienceQuery.config.fullResponse = false;
      return results;
    };
    $scope.loadPageNum = async (newPage=undefined) => {
      newPage = newPage || $scope.currentPage;
      const libraryQueriesFull  = await loadLibraryQueries(newPage);
      $scope.libraryQueries = libraryQueriesFull.data;
      const pageMeta = libraryQueriesFull.originalData.meta;

      // Pagination
      $scope.itemsPerPage = pageMeta.per_page;
      $scope.totalItems   = pageMeta.total_records;

      // This seemed to trigger a reload anyway, so bail on it for now
      // $state.go('.', {page: newPage}, {reload: false});
      $scope.$apply();
      return $scope.libraryQueries;
    };

    await $scope.loadPageNum();

    // Remove the current query from the library / recent list, since its not
    // meaningful in that context.
    if (!$scope.query.isNew()) {
      idx = $scope.libraryQueries.indexOfByKey("id", $scope.query.id);
      if (idx !== -1) {
        $scope.libraryQueries.splice(idx, 1);
      }
      idx = $scope.myQueries.indexOfByKey("id", $scope.query.id);
      if (idx !== -1) {
        $scope.myQueries.splice(idx, 1);
      }
    } else {
      $scope.query.tagVoterfile = $scope.tagVoterfileOptions[0].value;
    }

    const buildResultsTable = function(qry) {
      let hdr;
      const tblData     = [];
      const tblHeaders  = [];

      // Build headers
      for (hdr of Array.from(qry.result.headers)) {
        tblHeaders.push({displayName: hdr, field: hdr});
      }

      // Build sample data, as UI-grid takes an array of objects and we have arrays of arrays
      for (let rawRow of Array.from(qry.result.contentSample)) {
        const rowObj = {};
        tblData.push(rowObj);
        for (idx = 0; idx < qry.result.headers.length; idx++) {
          hdr = qry.result.headers[idx];
          rowObj[hdr] = rawRow[idx];
        }
      }

      return $scope.resultTable = {
        columnDefs:     tblHeaders,
        enableSorting:  true,
        data:           tblData,

        // Until 'WHEN NEEDED' is back: https://github.com/angular-ui/ng-grid/pull/3100
        enableHorizontalScrollbar:  uigc.scrollbars.NEVER,
        enableVerticalScrollbar:    uigc.scrollbars.NEVER
      };
    };

    $scope.destroyQuery = query =>
      query.delete().then( () => $state.go('^.list'))
    ;

    $scope.loadLibraryQuery = libQry => $scope.query.inputSql   = libQry.inputSql;

    $scope.saveQuery = function(query) {
      $scope.showSpinner.saveQuery = true;
      const wasNew = query.isNew();

      return query.save().then( function(res) {
        $scope.showSpinner.saveQuery = false;

        if (wasNew) {
          $state.go('^.viewer', {queryId: res.id});
        } else {
          $scope.query = res;
          buildResultsTable(res);
          $scope.queryBuilder.$setPristine();
        }

        return res;
      }
      , function(err) {
        $scope.showSpinner.saveQuery = false;
        if (typeof console !== 'undefined' && console !== null) {
          console.log("Error saving: ", err);
        }
        return err;
      });
    };

    if (query.result) {
      // Unclear why this became necessary after the 'async' change on the controller function,
      // but I am hoping it helps get the table rendered more reliably
      $scope.$apply(() => { buildResultsTable(query) });
    }

    return true;
  }
]);
