// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('S3UserUpload', ['RailsResource', '$http', '$q', function(RailsResource, $http, $q) {
  let S3UserUpload;
  return S3UserUpload = (function() {
    S3UserUpload = class S3UserUpload extends RailsResource {
      static initClass() {
        this.configure({url: '/api/s3_user_uploads', name: 's3_user_upload'});
      }

      fetchPrefixes() {
        return this.fetchCollectionUtil('/prefixes', 'prefixes');
      }
      fetchUploadToken() {
        return this.fetchCollectionUtil('/upload-token', 'token');
      }

      fetchCollectionUtil(urlPath, dataKey) {
        if (!this.clientId) {
          const deferred = $q.defer();
          return deferred.reject("Client id not yet set");
        }

        const url = this.$url(urlPath);
        return $http.get(url, {
          params: {
            client_id: this.clientId
          },
          headers: {
            Accept: 'application/json'
          }
        }).then( res => res.data[dataKey]
        , err => err);
      }
    };
    S3UserUpload.initClass();
    return S3UserUpload;
  })();
}
]);
