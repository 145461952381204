// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Filter to turn a path string 'foo/bar/baz' into 'baz'

'use strict';

angular.module('kazaam.filters').filter('basename', [() =>
  function(text) {
    if (text) {
      return text.split(/[\\/]/).pop();
    } else {
      return null;
    }
  }

]);
