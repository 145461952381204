// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('MediaCampaignBuilderCtrl',
  ['$scope', 'campaign', 'clients', '$state', '$timeout', '$modal', 'CreativeConcept', 'MEDIA_CAMPAIGNS_SECONDARYNAV', 'currentUser',
  function($scope, campaign, clients, $state, $timeout, $modal, CreativeConcept, MEDIA_CAMPAIGNS_SECONDARYNAV, currentUser) {
    this.title        = $state.current.data.title;
    this.campaign     = campaign;
    this.clients      = clients;
    this.currentUser  = currentUser;
    this.showSpinner  = false;
    this.programTypes = ["Persuasion", "Mobilization", "GOTV"];
    this.raceTypes    = ["House", "Senate", "Governor", "Statewide", "State Legislature"];
    const ctrl          = this;
    const today         = new Date();
    const tomorrow      = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const EARLIEST_POSSIBLE_DATE = new Date('2018-05-01T00:00:00');

    $scope.secondaryNav = MEDIA_CAMPAIGNS_SECONDARYNAV;
    $scope.isDragging = false;

    if (this.campaign.isNew()) {
      // Ensure we have the prefix set to _something_ ahead of the uploader being available
      this.campaign.setS3Prefix();
    }

    this.workflowTransitions = {
      'mediaCampaigns.new':       'mediaCampaign.terms',
      'mediaCampaign.setup':      '^.terms',
      'mediaCampaign.terms':      '^.concepts',
      'mediaCampaign.concepts':   '^.show',
      'mediaCampaign.edit':       '^.show'
    };

    this.stepperTransitions = [
      {
        state: this.campaign.isNew() ? 'mediaCampaigns.new' : 'mediaCampaign.setup',
        label: 'Top-Level Media Plan Information'
      },
      {
        state: 'mediaCampaign.terms',
        label: 'Terms'
      },
      {
        state: 'mediaCampaign.concepts',
        label: 'Concepts'
      },
      {
        state: 'mediaCampaign.show',
        label: 'Review'
      }
    ];
    this.currentStep = this.stepperTransitions.findIndex(step => $state.current.name === step.state);

    if (this.clients.length === 1) {
      this.campaign.clientId = this.clients[0].id;
    }

    this.conceptTemplate = new CreativeConcept({name: "New Concept", startAt: this.campaign.startAt, endAt: this.campaign.endAt, mediaCampaignId: campaign.id, assets: []});

    this.nextWorkflowStep = function(cpn) {
      const targetState = this.workflowTransitions[$state.current.name];
      if (targetState) {
        return $state.go(targetState, {campaignId: cpn.id});
      }
    };

    this.resetCSVAssets = function() {
      return this.csvAssets = this.campaign.assetObjs.filter(assetObj => assetObj.key.match(/\.csv$/i));
    };

    this.refreshAssets = function() {
      return this.campaign.refreshAssets().then(newAssets => ctrl.resetCSVAssets());
    };

    this.conceptAssetsChanged = function(concept) {
      if (concept.thumbnail && (concept.assets.indexOf(concept.thumbnail) === -1)) {
        concept.thumbnail = undefined;
      }
      return true;
    };

    this.hideSpinner = () =>
      $timeout( () => ctrl.showSpinner = false
      , 250)
    ;
    this.destroyCampaign = function(campaignId) {
      if (this.campaign.id === campaignId) {
        return this.campaign.delete().then( () => $state.go('mediaCampaigns.list'));
      }
    };

    this.saveCampaign = function(campaign) {
      ctrl.showSpinner = true;

      return campaign.save().then( function(res) {
        ctrl.hideSpinner();
        ctrl.nextWorkflowStep(res);
        return res;
      }
      , function(err) {
        ctrl.hideSpinner();
        if (typeof console !== 'undefined' && console !== null) {
          console.log("Error saving: ", err);
        }
        return err;
      });
    };

    this.markConceptForDelete = function($event, concept, idx) {
      $event.preventDefault();
      $event.stopPropagation();
      if (concept.isNew()) {
        this.campaign.creativeConcepts.splice(idx, 1);
      } else {
        concept.markForDelete();
      }
      return concept;
    };

    this.undoMarkConceptForDelete = function($event, concept) {
      $event.preventDefault();
      $event.stopPropagation();
      concept.undoMarkForDelete();
      return concept;
    };


    this.addConcept = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      const newConcept = angular.copy(this.conceptTemplate);
      newConcept.dpFields = angular.copy(this.dpFields);
      newConcept.name += ` ${this.campaign.creativeConcepts.length + 1}`;
      newConcept.setS3Prefix();
      this.campaign.creativeConcepts.push(newConcept);
      return newConcept;
    };

    // Datepicker stuff
    this.dpFields = {
      format: 'MM/dd/yyyy',
      start: false,
      end: false,
      minDate: EARLIEST_POSSIBLE_DATE
    };
    $scope.openDatePopup = function($event, param, obj) {
      $event.preventDefault();
      $event.stopPropagation();
      // Reset any opened popups
      ctrl.campaign.dpFields.start = false;
      ctrl.campaign.dpFields.end = false;
      angular.forEach(ctrl.campaign.creativeConcepts, function(conc) {
        conc.dpFields.start = false;
        return conc.dpFields.end = false;
      });
      //Set the triggered popup
      return obj.dpFields[param] = true;
    };

    this.setupDpFields = function() {
      this.campaign.dpFields = angular.copy(ctrl.dpFields);
      return angular.forEach(this.campaign.creativeConcepts, conc => conc.dpFields = angular.copy(ctrl.dpFields));
    };

    this.setupDpFields();
    this.resetCSVAssets();

    $scope.sortableOpts = {
      'ui-floating': false,
      connectWith: '.sortable-container',
      start(e, ui) {
        if (ui.item[0].className.indexOf('unassigned-asset') === -1) {
          return $scope.$apply( () => $scope.isDragging = true);
        }
      },
      stop() {
        return $scope.$apply( () => $scope.isDragging = false);
      }
    };

    // Tagging stuff
    this.stripQuotes = newTag => newTag.replace(/"|'/g, '').replace(/^ +/, '').replace(/ +$/, '');

    this.planUploadFinished = function($event) {
      // When the upload is complete, set the new file as the plan
      console.log("In uploadFinished, event: ", $event);
      this.campaign.planFile = $event.results[0].file.name;

      if (this.campaign.isNew()) {
        return this.csvAssets.push({key: this.campaign.planFile});
      } else {
        return this.refreshAssets();
      }
    };

    this.searchTermsParsed = function($event) {
      //console?.log("in searchTermsParsed", $event)
      return this.campaign.searchTerms = $event.results.deduped;
    };

    this.approveCampaign = function() {
      this.showSpinner = true;
      return this.campaign.approve().finally( () => ctrl.hideSpinner());
    };

    this.getClientNameById = function(id) {
      const client = this.clients.find( item => item.id === id);
      return (client != null ? client.name : undefined);
    };

    $scope.openAssignmentModal = function(concept) {
      let modalInstance;
      return modalInstance = $modal.open({
        templateUrl:  'asset-assign-modal.html',
        controller:   'AssetAssignModalCtrl as $ctrl',
        size:         'lg',
        resolve: {
          campaign() { return campaign; },
          concept() { return concept; },
          currentUser() { return currentUser; }
        }
      });
    };

    return this;
  }
]);
