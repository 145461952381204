// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers').controller('MediaPlanTraffickerCtrl',
  ['$scope', 'plan', 'traffickPlan', 'activity', '$state', '$timeout', 'MEDIA_PLANNER_SECONDARYNAV', 'currentUser',
  ($scope, plan, traffickPlan, activity, $state, $timeout, MEDIA_PLANNER_SECONDARYNAV, currentUser) => {
    const ctrl        = this;
    this.title        = $state.current.data.title;
    this.plan         = plan;
    this.traffickPlan = traffickPlan;
    this.activity     = activity;
    this.currentUser  = currentUser;

    this.showSpinner        = false;
    this.showDeleteSpinner  = false;

    $scope.secondaryNav = MEDIA_PLANNER_SECONDARYNAV;

    this.hideSpinner = () => { $timeout(() => { ctrl.showSpinner = false }, 100); };

    // Setup doTraffickingByName so we can toggle the go/no-go logic
    if (this.traffickPlan) {
      this.doTraffickingByName = {};
      angular.forEach(this.traffickPlan.placementDetails, (pDetail) => {
        ctrl.doTraffickingByName[pDetail.placementName] = pDetail.doTrafficking;
      });
    }

    this.allowAll = () => {
      angular.forEach(Object.keys(ctrl.doTraffickingByName), (pName) => { ctrl.doTraffickingByName[pName] = true; });
    };
    this.ignoreAll = () => {
      angular.forEach(Object.keys(ctrl.doTraffickingByName), (pName) => { ctrl.doTraffickingByName[pName] = false; });
    };

    this.approve = () => {
      ctrl.showSpinner = true;

      return ctrl.traffickPlan.approve({traffickingStates: ctrl.doTraffickingByName}).then( (result) => {
        ctrl.hideSpinner();
        if (result.background) {
          ctrl.backgroundTrafficking = true;
        } else {
          $state.go('mediaPlanTrafficker.activity', {planId: ctrl.plan.id});
        }
        return result;
      }, (err) => {
        ctrl.hideSpinner();
        if (typeof console !== 'undefined' && console !== null) {
          console.log("Error saving: ", err);
        }
        return err;
      });
    };

    return this;
  }
]);
