// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.directives')
  .directive('tag', () =>
    ({
      restrict: 'AE',
      templateUrl: 'directives/tag.html',
      scope: {
        tagText: '=',
        onClose: '='
      },
      link(scope, elem, attrs) {}
    })
  );
