'use strict';

angular.module('kazaam.constants')
  .constant('MEDIA_PLANNER_SECONDARYNAV', [
    { label: 'Media Plans',           pathName: 'mediaPlanner.list',            params: '', active: false, icon: 'icon-screen-desktop' },
    { label: 'Favorites',             pathName: 'mediaPlanner.favorites',       params: '', active: false, icon: 'icon-star' },
    { label: 'Archive',               pathName: 'mediaPlanner.archive',         params: '', active: false, icon: 'icon-notebook' },
    { label: 'New Plan',              pathName: 'mediaPlanner.new',             params: '', active: false, icon: 'icon-plus' },
  ]);
  
