'use strict';

angular.module('kazaam', [
  'ngRaven',
  'ngSanitize',
  'ui.router',
  'rails',
  'kazaam.components',
  'kazaam.constants',
  'kazaam.controllers',
  'kazaam.directives',
  'kazaam.filters',
  'kazaam.models',
  'kazaam.services',
  'ui.grid',
  'ui.grid.exporter',
  'ui.grid.selection',
  'ui.grid.resizeColumns',
  'ui.grid.edit',
  'ui.bootstrap',
  'ui.sortable',
  // 'ui.tinymce',
  'ui.select',
  'ngSelect',
  'ngAnimate',
  'http-auth-interceptor',
  'ngTagsInput',
  'ngFileUpload',
  'checklist-model',
  'queryBuilder',
  'stepper',
  'angular-uuid',
  'papa-promise',
  'btford.markdown'
]);

angular.module('kazaam.components', []);
angular.module('kazaam.controllers',[]);
angular.module('kazaam.constants', []);
angular.module('kazaam.directives', []);
angular.module('kazaam.filters', []);
angular.module('kazaam.models', ['rails']);
angular.module('kazaam.services', []);
