// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('MediaCampaign', ['RailsResource', 'railsSerializer', '$http', 'uuid', function(RailsResource, railsSerializer, $http, uuid) {
  let MediaCampaign;
  const ser = railsSerializer( function() {
    this.resource('client', 'Client');
    this.resource('creativeConcepts', 'CreativeConcept');
    this.nestedAttribute('creativeConcepts');
    this.exclude('assetObjs');
    this.exclude('client');
    this.exclude('creator');
    this.exclude('creatorId');
    this.exclude('createdAt');
    this.exclude('updatedAt');
    this.exclude('dpFields');
    return this.exclude('workflowDetails');
  });

  return MediaCampaign = (function() {
    MediaCampaign = class MediaCampaign extends RailsResource {
      static initClass() {
        this.configure({url: '/api/media_campaigns', name: 'media_campaign', serializer: ser});
      }

      setS3Prefix() { return this.s3Prefix = `unsaved-campaign-assets-${uuid.v4()}`; }

      refreshAssets() {
        const url = this.$url("assets");
        return $http.get(url).then(resp => {
          //console?.log("Assets:", resp.data)
          this.assetObjs = resp.data.assets;
          return resp;
        });
      }

      isAssetAssigned(asset) {
        for (let concept of Array.from(this.creativeConcepts)) {
          const idx = concept.assets.indexOf(asset);
          if (idx !== -1) {
            return {concept, index: idx};
          }
        }
        return undefined;
      }

      approve() {
        const url = this.$url("/approve");
        return MediaCampaign.$post(url).then( resp => {
          this.approved = resp.approved;
          return resp;
        });
      }

      approvable() { return __guard__(this.workflowDetails != null ? this.workflowDetails.planFile : undefined, x => x.valid); }

      deleteAsset(asset) {
        const url = this.$url("/assets");
        return $http.delete(url, {params: {assets: [asset]}}).then(resp => {
          let idx = this.assetObjs.indexOfByKey("key", asset);
          if (idx !== -1) {
            this.assetObjs.splice(idx, 1);
          }

          angular.forEach(this.creativeConcepts, function(concept) {
            idx = concept.assets.indexOf(asset);
            if (idx !== -1) {
              return concept.assets.splice(idx, 1);
            }
          });
          return resp;
        });
      }

      save() {
        if (this.isNew() && !this.s3Prefix) {
          this.setS3Prefix();
        }
        return super.save(...arguments);
      }

      termsMissing() { return (this.searchTerms != null ? this.searchTerms.length : undefined) === 0; }
      statusText() {
        // Human-readable interpretation of the extended validation in workflowDetails
        if (this.workflowDetails['dashboardReady']) {
          if (this.termsMissing() || !(this.workflowDetails['creativeConcepts'] != null ? this.workflowDetails['creativeConcepts'].valid : undefined)) {
            return "Ready in dashboard; some data missing.";
          } else {
            return "Ready in dashboard.";
          }
        } else if (this.approved) {
          if (this.termsMissing() || !(this.workflowDetails['creativeConcepts'] != null ? this.workflowDetails['creativeConcepts'].valid : undefined)) {
            return "Approved & processing; some data missing.";
          } else {
            return "Approved & processing.";
          }
        } else if ((this.workflowDetails['planFile'] != null ? this.workflowDetails['planFile'].missing : undefined)) {
          return "Media plan not selected.";
        } else if (!(this.workflowDetails['planFile'] != null ? this.workflowDetails['planFile'].valid : undefined)) {
          return "Media plan has errors.";
        } else if ((this.workflowDetails['creativeConcepts'] != null ? this.workflowDetails['creativeConcepts'].missing : undefined)) {
          return "Creative concepts not set.";
        } else if (!(this.workflowDetails['creativeConcepts'] != null ? this.workflowDetails['creativeConcepts'].valid : undefined)) {
          return "Creative concepts have errors.";
        } else {
          return "Awaiting approval.";
        }
      }
    };
    MediaCampaign.initClass();
    return MediaCampaign;
  })();
}
]);

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}