'use strict';

angular.module('kazaam.models').factory('PlacementTraffickingPlan', ['RailsResource', 'railsSerializer', (RailsResource, railsSerializer) => {
  let PlacementTraffickingPlan;
  const ser = railsSerializer( function() {
    this.exclude('createdAt');
    this.exclude('updatedAt');
  });

  return PlacementTraffickingPlan = (() => {
    PlacementTraffickingPlan = class PlacementTraffickingPlan extends RailsResource {
      static initClass() {
        this.configure({url: '/api/media_plan_trafficker', name: 'placement_trafficking_plan', serializer: ser});
      }

      approve(approveOpts) {
        const url = this.$url(`/${this.mediaPlanId}/approve`);
        return this.constructor.$post(url, {states: approveOpts['traffickingStates']}, {skipRequestProcessing: true}, {});
      }

      hasPlacements() {
        return (this.counts.total - this.counts.ignore) > 0;
      }
    };
    PlacementTraffickingPlan.initClass();
    return PlacementTraffickingPlan;
  })();
}
]);
