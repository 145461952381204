// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('S3UploadManager', ['$raven', 'S3UserUpload', 'Upload', 'XmlToJson', '$q', function($raven, S3UserUpload, Upload, xmlToJson, $q) {
  let S3UploadManager;
  return (S3UploadManager = class S3UploadManager {
    constructor() {
      this.recentFiles  = [];
      this.reset();
    }

    reset() {
      this.error              = false;
      this.errorMsg           = '';
      this.isUploadingFile    = false;
      return this.s3uuTemplate       = undefined;
    }

    startUpload(files, prefix, userId, clientId) {
      const self = this;
      this.s3uuTemplate = new S3UserUpload({userId: Number(userId), clientId: Number(clientId)});
      return this.s3uuTemplate.fetchUploadToken().then( function(token) {
        //console?.log("Token: ", token, "Prefix: ", prefix)
        self.isUploadingFile = true;

        const promises = [];
        angular.forEach(files, file => promises.push(self.doUpload(file, prefix, token)));
        return $q.all(promises);
      }
      , function(err) {
        if (typeof console !== 'undefined' && console !== null) {
          console.log("Error fetching access token: ", err);
        }
        $raven.captureMessage(err.statusText, {tags: {status: err.status}});
        self.errorMsg = err.statusText;
        return self.error = err.status;
      }).then( function(results) {
        if (typeof console !== 'undefined' && console !== null) {
          console.log("All uploads complete");
        }
        angular.forEach(results, res => self.recentFiles.push(res.file));
        self.reset();
        return results;
      }
      , function(errs) {
        if (typeof console !== 'undefined' && console !== null) {
          console.log("All uploads complete, errors:", errs);
        }
        return self.isUploadingFile = false;
      });
    }

    doUpload(file, prefix, uploadToken) {
      // Order seems to matter here, tread lightly
      const uplData           = angular.copy(uploadToken.policy);
      uplData.key       = `${uploadToken.policy_meta.prefix}/${prefix}/${file.name}`.replace(/\/+/g, '/').replace(/^\//, '');
      uplData.Policy    = uploadToken.policy64;
      uplData["X-Amz-Signature"]  = uploadToken.signature;
      uplData["Content-Type"]     = file.type || "application/octet-stream";
      uplData.filename  = file.name;
      uplData.file      = file;

      const upload_opts = {
        url:        `https://${uploadToken.policy_meta.bucket}.s3.amazonaws.com/`,
        method:     "POST",
        data:       uplData
      };

      const self = this;
      const s3UserUpload = angular.copy(this.s3uuTemplate);

      return Upload.upload(upload_opts).then( function() {
        // AWS returns HTTP 204 by default, so no meaningful response body 
        if (typeof console !== 'undefined' && console !== null) {
          console.log(`Successfully POST'ed ${file.name} to S3`);
        }
        s3UserUpload.s3url = `s3://${uploadToken.policy_meta.bucket}/${uplData.key}`;
        return s3UserUpload.save().then( function(s3uu) {
          if (typeof console !== 'undefined' && console !== null) {
            console.log(`Saved S3UserUpload ${s3uu.id}`);
          }
          return {s3UserUpload: s3uu, file};
        }
        , function(err) {
          $raven.captureMessage(err.statusText, {tags: {status: err.status}, extra: {filename: file.name}});

          self.error    = err;
          self.errorMsg = err.statusText;
          if (typeof console !== 'undefined' && console !== null) {
            console.log("Error saving S3UserUpload: ", self.errorMsg, err);
          }
          return err;
        });
      }
      , function(err) {
        // These are triggered when the POST to S3 fails, so the error objects are from AWS (e.g. xml)
        if (typeof console !== 'undefined' && console !== null) {
          console.log(`Failed to upload ${file.name}: `, err);
        }
        const parsedErrorData = xmlToJson.parseXml(err.data);
        self.error = parsedErrorData.error;
        self.errorMsg = self.error.message;
        $raven.captureMessage(JSON.stringify(self.error), {tags: {status: err.status}, extra: {filename: file.name}});
        return err;
      }
      , function(evt) {
        file.progress = parseInt((100.0 * evt.loaded) / evt.total);
        if ((file.progress % 25) === 0) {
          // Don't spam the console w/ progress, only every few
          if (typeof console !== 'undefined' && console !== null) {
            console.log(`Progress: ${file.progress}% ${evt.config.data.file.name}`);
          }
        }
        return evt;
      });
    }
  });
}
]);
