// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

const S3UploaderCtrl = ['$scope', 'S3UploadManager', function($scope, S3UploadManager) {
  const ctrl = this;
  this.reset = function() {
    $scope.error              = false;
    $scope.errorMsg           = '';
    $scope.fileContainer      = {files: []};
    return this.uploadMgr                = new S3UploadManager();
  };

  this.$onInit = function() {
    this.recentFiles = [];
    this.reset();
    return this.setupImmediateTrigger();
  };

  this.$onChanges = function(changeObj) {};
    //console?.log("onChanges(): ", changeObj)

  this.removeFile = idx => $scope.fileContainer.files.splice(idx, 1);

  this.setupImmediateTrigger = function() {
    if (this.immediate) {
      return $scope.$watch('fileContainer.files', function(newV, oldV) {
        if (newV && (Array.ensureArray(newV).length > 0)) {
          return ctrl.startUpload(newV);
        }
      });
    }
  };

  this.startUpload = function(files) {
    files = Array.ensureArray(files);
    return this.uploadMgr.startUpload(files, this.prefix, this.userId, this.clientId).then( function(results) {
      angular.forEach(files, file => ctrl.recentFiles.push(file));
      ctrl.reset();
      if (ctrl.onUploadFinish) {
        ctrl.onUploadFinish({$event: {results}});
      }
      return results;
    });
  };

  return this;
}
];

angular.module('kazaam.components')
  .component("s3UploaderSmall", {
    controller: S3UploaderCtrl,
    templateUrl: 'components/s3uploader-small.html',
    bindings: {
      clientId: '<',
      userId: '<',
      prefix: '<',
      multiple: '<',
      immediate: '<',
      showRecent: '<',
      showPending: '<',
      onUploadFinish: '&',
      buttonLabel: '@',
      extensions: '@',
      allowBlankPrefix: '<'
    }
  });
