// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.models').factory('highChartsHelpers', [ 'jQuery', function($){
  return {
    ucfirst(str) { return str.charAt(0).toUpperCase() + str.slice(1); },
    dcfirst(str) { return str.charAt(0).toLowerCase() + str.slice(1); },

    // http://stackoverflow.com/a/15762794/845546
    // '+' prefix to preserve the type as a number
    roundTo(num, places) { return +num.toFixed(places); },

    // Like indexOf, but uses an object key
    indexOfByKey(ary, searchObj, key) {
      if (ary.length === 0) {
        return -1;
      } else if (!searchObj || !searchObj.hasOwnProperty(key)) {
        return -1;
      } else {
        for (let idx = 0; idx < ary.length; idx++) {
          const obj = ary[idx];
          if (obj[key] === searchObj[key]) {
            return idx;
          }
        }

        // If we got here, we didn't find it
        return -1;
      }
    },

    // http://stackoverflow.com/a/7726509/845546
    arrayContentsEq(arr1, arr2) {
      return ($(arr1).not(arr2).length === 0) && ($(arr2).not(arr1).length === 0);
    },

    // Returns an object with 'added' and 'removed' fields indicating the
    // objects that were... added or removed.
    arrayChangeSet(origA, newA, includeFn) {
      let obj;
      if (includeFn == null) { includeFn = undefined; }
      const rv = {added: [], removed: []};

      // includeFn tests whether the array includes the element
      if (includeFn === undefined) {
        includeFn = (ary, elm) => ary.indexOf(elm) !== -1;
      }

      if (!origA) { origA = []; }
      if (!newA) { newA = []; }

      for (obj of Array.from(origA)) {
        if (!includeFn(newA, obj)) {
          rv.removed.push(obj);
        }
      }
      for (obj of Array.from(newA)) {
        if (!includeFn(origA, obj)) {
          rv.added.push(obj);
        }
      }
      return rv;
    },

    // http://stackoverflow.com/a/16988918/845546
    deepSet(obj, str, val) {
      // make sure str is a string with length
      if (!str || !str.length || (Object.prototype.toString.call(str) !== "[object String]")) {
        return false;
      }
      if (obj !== Object(obj)) {
        // if it's not an object, make it one
        obj = {};
      }
      const keys = str.split(".");
      while (keys.length > 1) {
        const key = keys.shift();
        if (obj !== Object(obj)) {
          // if it's not an object, make it one
          obj = {};
        }
        if (!obj.hasOwnProperty(key)) {
          // if obj doesn't contain the key, add it and set it to an empty object
          obj[key] = {};
        }
        obj = obj[key];
      }
      return obj[keys[0]] = val;
    },

    downloadCsv(tblData, fname) {
      if (fname == null) { fname = undefined; }
      if (fname === undefined) {
        fname = "data.csv";
      }

      const header = Object.keys(tblData[0]).filter( elm => !elm.match(/^\$\$/));

      const rows = [header.join(',')];
      // XXX: This is an _extremely_ naive CSV conversion, we really want one
      // that's more robust.
      angular.forEach(tblData, function(row) {
        const tmp = [];
        for (let k of Array.from(header)) {
          tmp.push(`\"${row[k]}\"`);
        }
        return rows.push(tmp.join(','));
      });
      const csv = rows.join("\n");
      const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
      return saveAs(blob, fname);
    },

    toPercentPts(rows, totalCount) {
      if (totalCount == null) { totalCount = undefined; }
      const hch = this;
      const pctRows = angular.copy(rows);
      angular.forEach(pctRows, function(seriesRow) {
        let divisor = 0;
        // We might get a plain array of values, or an array of arrays like: [ [label, val], ... ]
        if (Array.isArray(seriesRow.data[0])) {
          divisor = seriesRow.data.reduce(
            (t, s) => t + s[1]
          , 0);
        } else {
          divisor = seriesRow.data.reduce(
            (t, s) => t + s
          , 0);
        }

        //console?.debug("divisor: #{divisor}")
        // Allow the caller to override the divisor
        if (Number(totalCount)) {
          divisor = totalCount;
        }

        return angular.forEach(seriesRow.data, function(pointVal, idx) {
          if (divisor === 0) {
            if (Array.isArray(seriesRow.data[idx])) {
              return seriesRow.data[idx][1] = 0;
            } else {
              return seriesRow.data[idx] = 0;
            }
          } else {
            if (Array.isArray(seriesRow.data[idx])) {
              return seriesRow.data[idx][1] = hch.roundTo((100 * pointVal[1]) / divisor, 1);
            } else {
              return seriesRow.data[idx] = hch.roundTo((100 * pointVal) / divisor, 1);
            }
          }

        });
      });
      return pctRows;
    },

    labelForAnswer(answer, rawChartData) {
      if (rawChartData.labels.hasOwnProperty(answer)) {
        return rawChartData.labels[answer];
      } else {
        return answer;
      }
    },

    controlGroupLookupLabel(settings) {
      let rv = settings.controlGroup;
      if (settings.aggGroups) {
        // The group name gets changed if things are aggregated.
        rv = settings.controlGroupLabel;
      }
      return rv;
    }

  };
}
]);

