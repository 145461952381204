'use strict';

angular.module('kazaam.components')
  .component('mediaPlannerPageHeader', {
    templateUrl: 'media-planner/components/page-header.html',
    controller: ['$scope', '$state', function($scope, $state) {
      $scope.$state = $state;
      return this;
    }],
    bindings: {
      title: '<',
      planId: '=',
      plan: '<',
      onDelete: '&'
    }
  });
