// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('kazaam.controllers')
  .controller('AssetAssignModalCtrl', ['$scope', '$modalInstance', 'campaign', 'concept', 'currentUser', function($scope, $modalInstance, campaign, concept, currentUser) {
    const CSV_REGEXP        = /\.csv$/i;
    this.campaign         = campaign;
    this.concept          = concept;
    this.currentUser      = currentUser;
    this.assignedAssets   = angular.copy(this.concept.assets) || [];
    this.thumbnail        = angular.copy(this.concept.thumbnail) || null;
    const ctrl = this;

    this.stripCampaignPrefix = function(fullKey) { return fullKey.split(this.campaign.s3Prefix + '/')[1]; };

    this.assignAsset = function(asset) {
      this.unassignedAssets = this.unassignedAssets.filter( unassigned => unassigned !== asset);
      return this.assignedAssets.push(asset);
    };

    this.unassignAsset = function(asset) {
      this.assignedAssets = this.concept.assets.filter( assigned => assigned !== asset);
      this.unassignedAssets.unshift(asset);
      if (this.concept.thumbnail === asset) { return this.thumbnail = null; }
    };

    this.deselectThumbnail = function() {
      return this.thumbnail = null;
    };

    this.selectThumbnail = function(asset) {
      if (this.thumbnail === asset) { return this.thumbnail = null; } else { return this.thumbnail = asset; }
    };

    this.setAssigned = function() {
      this.concept.thumbnail = this.thumbnail;
      this.concept.assets = this.assignedAssets;
      return $modalInstance.close(this.concept);
    };

    // Uploader integration
    this.assetUploadFinished = function($event) {
      //console.log("In uploadFinished, event: ", $event)
      // If the file is not a csv, assign it appropriately
      angular.forEach($event.results, uplResult => {
        const { file } = uplResult;
        const s3uu = uplResult.s3UserUpload;
        const fname = this.stripCampaignPrefix(s3uu.s3url);

        if (this.campaign.assetObjs.indexOfByKey("key", fname) === -1) {
          this.campaign.assetObjs.push({key: fname, size: file.size, lastModified: file.lastModifiedDate});
        }

        // Assign to the current concept if its not already assigned somewhere.
        if (!file.name.match(CSV_REGEXP)) {
          const assignedTo = this.campaign.isAssetAssigned(fname);
          if (!assignedTo) {
            return this.assignAsset(fname);
          }
        }
      });
      return $event;
    };

    this.resetAssets = function() {
      this.unassignedAssets = this.campaign.assetObjs.filter(assetObj => !assetObj.key.match(CSV_REGEXP) && (assetObj.key.indexOf(this.concept.s3Prefix) !== -1) ).map(assetObj => assetObj.key);

      return angular.forEach(this.campaign.creativeConcepts, concept =>
        angular.forEach(concept.assets, function(asset) {
          const idx = ctrl.unassignedAssets.indexOf(asset);
          if (idx !== -1) {
            return ctrl.unassignedAssets.splice(idx, 1);
          }
        })
      );
    };

    this.cancel = function() {
      return $modalInstance.close(this.concept);
    };

    this.deleteAsset = function(asset) {
      return this.campaign.deleteAsset(asset).then( function() {
        ctrl.unassignAsset(asset);
        const idx = ctrl.unassignedAssets.indexOf(asset);
        if (idx !== -1) {
          ctrl.unassignedAssets.splice(idx, 1);
        }
        return asset;
      });
    };

    this.resetAssets();
    return this;
  }
  ]);
