/* Adapted from https://stackoverflow.com/a/28305488/845546 */
'use strict';

const ConfirmSvc = ['$modal',  function($modal) {
  const service = {};
  service.open = function (text, onOk, okText='Ok', danger=false) {
    const modalInstance = $modal.open({
      templateUrl: 'directives/confirm.html',
      controller: 'ModalConfirmCtrl',
      resolve: {
        text: () => { return text; },
        okText: () => { return okText; },
        danger: () => { return danger; },
      }
    });

    modalInstance.result.then(function (selectedItem) {
      onOk();
    }, function () {
    });
  };

  return service;
}];

const ConfirmCtrl = ['$scope', '$modalInstance', 'text', 'okText', 'danger', ($scope, $modalInstance, text, okText, danger) => {
  $scope.text = text;
  $scope.okText = okText;
  $scope.danger = danger;
  $scope.ok = () => { $modalInstance.close(true); };
  $scope.cancel = () => { $modalInstance.dismiss('cancel'); };
}];

angular.module('kazaam.services').service('ConfirmService', ConfirmSvc);
angular.module('kazaam.controllers').controller('ModalConfirmCtrl', ConfirmCtrl);
angular.module('kazaam.directives').directive('confirm', ['ConfirmService', (ConfirmService) => {
  return {
    restrict: 'A',
    scope: {
      eventHandler: '&ngClick'
    },
    link: function(scope, element, attrs){
      element.unbind("click");
      element.bind("click", function(e) {
        ConfirmService.open(attrs.confirm, scope.eventHandler, attrs.confirmOk, (attrs.confirmDanger === 'true'));
      });
    }
  }
}]);
